/* eslint-disable react/no-unescaped-entities */
import React from "react";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">Gizlilik İlkesi</span>
        <div className="printAndDownload">
          <i className="print-icons  bc-i-Print" />
          <i className="print-icons  bc-i-download" />
        </div>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p>
          K Softworks NV bireysel gizliliğe saygı duyar ve müşterilerinin,
          çalışanlarının, tedarikçilerinin, iş ortaklarının ve kamuoyunun
          güvenine değer verir.
        </p>
        <p>
          Bu nedenle K Softworks NV, Kişisel Verileri iş yaptığı ülkelerin
          yasalarına uygun bir şekilde toplamaya, kullanmaya ve paylaşmaya
          çalışmaktadır.
        </p>
        <p>
          Maltbahis markasının sahibi K Softworks NV, AB Genel Veri Koruma
          Yönetmeliğine uymayı taahhüt eder.
        </p>
        <p>
          K Softworks NV yönetimi, müşteriler, ücretlendirme, strateji, yönetim
          veya bu konularla ilgili başka bilgilerin korunmasının; adı geçen
          bilgilerin kaybolmasını, uygunsuz bir şekilde paylaşılmasını ve
          değiştirilmesini ya da yetkisiz kullanılmasını engellemenin önemini
          kabul eder.
        </p>
        <p>
          Bu nedenle K Softworks NV, genel olarak kişisel bilgilerin
          gizliliğini, kullanılabilirliğini ve bütünlüğünü sağlamak için bilgi
          güvenliği yönetim sistemini uygulamayı, sürdürmeyi ve sürekli olarak
          geliştirmeyi taahhüt eder.
        </p>
        <p>Güvenli bir oyun ortamı</p>
        <p>
          Şans Oyunları Komisyonu'nun tanıdığı oyun lisansına sahip K Softworks
          NV topluluğu tarafından yönetilen Maltbahis, şans oyunları ve bahis
          ile ilgili yeni mevzuata bağlıdır. K Softworks NV. Curacao'da kayıtlı
          bir şirkettir ve 8048/JAZ 2016-081 numarası altında Şans Oyunları
          Komisyonu tarafından verilmiş resmi lisansa sahiptir.
        </p>
        <p>
          Son derece güvenli bir internet sitesi olarak Maltbahis, oyuncularının
          çevrimiçi güvenliğini garanti eder. Her şeyden önce, yazılımımız
          virüssüzdür; bu yüzden bilgisayarınıza güvenli bir şekilde indirebilir
          ve yükleyebilirsiniz. Programlarımızı koruduğumuz ve oyun şeffaflığını
          sağladığımız teknoloji ile sizlere optimum güvenlik sunuyoruz.
        </p>
      </div>
    </div>
  );
};

export { PrivacyPolicy };
