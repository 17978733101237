import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";

import "./MegaCandyFestival.scss";

interface IMegaCandyFestival {
  navigate: NavigateFunction;
  language: string;
}

const MegaCandyFestival = ({ navigate, language }: IMegaCandyFestival) => {
  const handleCloseInfo = () => {
    navigate(`/${language}/promotions/all`);
  };
  return (
    <>
      <i
        className="e-p-close-icon-bc bc-i-close-remove"
        onClick={handleCloseInfo}
      />
      <div className="overlay-header">We Transfer Özel</div>
      <div className="promotion-details-container">
        <div className="promotion-details-header">
          <img
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_f3cd8a0b8752172e325e6956d47ffb89.webp"
            className="promotion-details-img"
            alt=""
            title="We Transfer Özel"
          />
        </div>
        <div className="promotion-details-info" data-scroll-lock-scrollable="">
          <p>
            &nbsp;<strong>WeTransfer Özel</strong>
          </p>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              Kurallar ve Şartlar
            </div>
            <div className="spoiler-content">
              <p>WeTransfer</p>
              <p>
                -WeTransfer sistemi ; siz kıymetli üyelerimiz için
                tasarladığımız hızlı transfer sistemidir. Bu sistem bir site
                hesabından diğer site hesabına bakiye transfer ederek “yeni bir
                yatırım” yapmanıza yardımcı olacaktır. Örneğin;
                sitemize&nbsp;1.000 TRY yatırım gerçekleştirip bonus aldınız ve
                sonrasında kazanç elde ederek bakiyenizi 2.000 TRY yaptınız.
                Bakiyenizin tamamını ya da belli bir kısmını Jupiterbahis ve
                Olipsbet sitelerine&nbsp;transfer ederek yeniden çevrimsiz bir
                bonus almaya hak kazanırsınız. We Transfer ile günlük işlem
                limitiniz bulunmamaktadır.&nbsp;Tek transferde minimum 100 TRY
                aktarım gerçekleştirebilirsiniz..
                <br />
                - WeTransfer sisteminin sizlere sunduğu bir çok avantaj
                bulunmaktadır. Bunlardan bazıları, Limit Aşımları, Finans
                Komisyonları, İşlem Süreleri gibi. WeTransfer için masraf
                bulunmamaktadır. Papara komisyonları, Bitcoin işlem ücreti,
                Havale ücreti vb. yöntemlerdeki ek masrafları ortadan kaldırmış
                olmaktasınız.
                <br />
                - WeTransfer sisteminin kullanımı çok kolay ve anlaşılırdır. Bu
                işlemi gerçekleştirebilmeniz için üye hesabınıza giriş yaptıktan
                sonra Para Çekme sayfasına giriş yapınız. WeTransfer seçeneğine
                tıklayınız. Bilgilerinizi girdikten sonra bakiyeniz ortalama 1 -
                3 dakika içerisinde transfer gerçekleştirmek istediğiniz şirkete
                aktarılacaktır. WeTransfer , sadece aynı kişi hesapları arasında
                kullanılabilmektedir.
                <br />
                - Transfer yapabilmeniz için ana paranızı en az 1 kat&nbsp;
                çevirmeniz gerekmektedir.&nbsp; &nbsp;
                <br />
                - Transfer edilen miktarı çekebilmek ve bonus alabilmek için
                ,transfer edilen miktarı 1 kat çevirmeniz yeterli
                olacaktır.&nbsp;
                <br />- WeTransfer yatırımlarınız için %20 Spor ve Casino
                Yatırım Bonusu alabilirsiniz.
              </p>
            </div>
          </div>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              Casino Yatırım Bonusu
            </div>
            <div className="spoiler-content">
              <p>
                - Alınan Casino Yatırım Bonusu 35 kat Slot ve Canlı Casino
                alanlarında çevirmeniz yeterli olacaktır.
                <br />
                -Transferinizin ardından maksimum 500 TL Casino Yatırım Bonusu
                alabilirsiniz.
                <br />- Alınan Casino Yatırım Bonusu ile maksimum 5000 TL
                çekilebilmektedir.
              </p>
            </div>
          </div>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              Spor Yatırım Bonusu
            </div>
            <div className="spoiler-content">
              <p>
                - Alınan Spor Yatırım Bonusu ile futbol,basketbol,tenis spor
                dallarında, tekli ve kombine olmak üzere Canlı , Maç öncesi
                etkinliklerinde minimum 1.90 oran bahis alarak
                kullanabilirsiniz.
                <br />
                - Transferiniz ardından maksimum 500 TL Spor Yatırım Bonusu
                alabilirsiniz.
                <br />
                -Alınan Spor Yatırım Bonusu ile maksimum 5000 TL
                çekilebilmektedir.
              </p>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export { MegaCandyFestival };
