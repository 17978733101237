import React, { useEffect, useRef, useState } from "react";
import "./CategoryInput.scss";

interface ICategoryInput {
  categorySelected: string;
  setCategorySelected: React.Dispatch<React.SetStateAction<string>>;
}

const CategoryInput = ({
  categorySelected,
  setCategorySelected,
}: ICategoryInput) => {
  const wrapperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectCategory = (item: string) => {
    setCategorySelected(item);
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc ">
        <div className="form-control-bc valid filled">
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenModal}
          >
            <div className="form-control-select-bc ellipsis">
              {categorySelected}
            </div>
            <i className="form-control-icon-bc bc-i-small-arrow-down" />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">Category</span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  categorySelected === "Sport" ? "active" : ""
                } `}
                data-option-value="Sport"
                onClick={() => handleSelectCategory("Sport")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Sport
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  categorySelected === "Casino" ? "active" : ""
                } `}
                data-option-value="Casino"
                onClick={() => handleSelectCategory("Casino")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Casino
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { CategoryInput };
