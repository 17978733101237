import axios, { AxiosRequestConfig } from "axios";
const url = process.env.REACT_APP_API_ORIGIN_URL; 

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
});


export { api };
