import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoIconGameType } from "../../../../../common/types/Casino/CasinoIconGameType";
import { FavoritCasinoResponceType } from "../../../../../common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "../../../../../common/types/Casino/FavoritCasinoStateType";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";
import { CasinoInfo } from "../../../../../services";
import { setFavoritsLocalDelete } from "../../../../../store/action/favorits/setFavoritsLocalDelete";
import { setFavoritsLocalSelect } from "../../../../../store/action/favorits/setFavoritsLocalSelect";
import { setFavoritsUser } from "../../../../../store/action/favorits/setFavoritsUser";
import { setModalLogin } from "../../../../../store/action/modals/setmodallogin";

import "./GameItem.scss";
interface IGameCasinoItem {
  gameItem:
    | CasinoIconGameType
    | FavoritCasinoResponceType
    | FavoritCasinoStateType;
  liveCasino?: boolean;
}

const GameItem = ({ gameItem, liveCasino }: IGameCasinoItem) => {
  const navigate = useNavigate();

  const [focus, setFocus] = useState(false);

  const handleMouseEnter = () => {
    setFocus(true);
  };

  const handleMouseLeave = () => {
    setFocus(false);
  };
  const dispatch = useDispatch();
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const favoritsGamesUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  const favoritsGamesLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const handleOpenPlay = () => {
    const setParamsPlay = () => {
      const params = new URLSearchParams();
      params.set("openGames", gameItem.game_provider.slug);
      params.set("gameNames", gameItem.slug);
      const search = "?" + params.toString();
      navigate({ search });
    };
    if (role === "unauth") {
      dispatch(setModalLogin(true));
    } else {
      setParamsPlay();
    }
  };

  const handleOpenDemo = () => {
    const setParamsDemo = () => {
      const params = new URLSearchParams();
      params.set("openGames", gameItem.game_provider.slug);
      params.set("gameNames", gameItem.slug);
      const search = "?" + params.toString();
      navigate({ search });
    };
    setParamsDemo();
  };
  const handleFavoriteUserSelect = () => {
    async function gandleSelectFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gameItem.id,
        type: 1,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    async function gandleRemoveFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gameItem.id,
        type: 0,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    favoritsGamesUser.some((obj) => obj.id === gameItem.id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };
  const handleFavoriteLocalSelect = () => {
    async function gandleSelectFavorit() {
      dispatch(
        setFavoritsLocalSelect({
          id: gameItem.id,
          slug: gameItem.slug,
          original_name: gameItem.original_name,
          is_demo_enabled: gameItem.is_demo_enabled,
          game_provider: {
            slug: gameItem.game_provider.slug,
          },
          banner: gameItem.banner,
        })
      );
    }
    async function gandleRemoveFavorit() {
      dispatch(
        setFavoritsLocalDelete({
          id: gameItem.id,
          slug: gameItem.slug,
          original_name: gameItem.original_name,
          is_demo_enabled: gameItem.is_demo_enabled,
          game_provider: {
            slug: gameItem.game_provider.slug,
          },
          banner: gameItem.banner,
        })
      );
    }
    favoritsGamesLocal.some((obj) => obj.id === gameItem.id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };

  return (
    <div
      className="casino-game-item-content"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="casino-game-item  " data-badge="">
        <img
          src={gameItem.banner}
          className="casino-game-item-image-bc"
          alt={gameItem.original_name}
          title={gameItem.original_name}
          loading="eager"
        />
        {focus ? (
          <div className="casino-game-item-block">
            <div className="casino-game-icons">
              <i className="casino-game-info-icon bc-i-info" />
            </div>
            <h3 className="casino-game-item-label-bc">
              {gameItem.original_name}
            </h3>
            <div className="casino-game-buttons">
              <button
                className="btn a-color"
                type="button"
                title={gameItem.original_name}
                onClick={handleOpenPlay}
              >
                <span>{t("casino.Play")}</span>
              </button>
              {gameItem.is_demo_enabled && !liveCasino ? (
                <button
                  className="btn a-outline "
                  type="button"
                  title={gameItem.original_name}
                  onClick={handleOpenDemo}
                >
                  <span>{t("casino.Demo")}</span>
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
        {role === "player" ? (
          <i
            className={`casino-game-item-fav-bc bc-i-favorite ${
              favoritsGamesUser.some((obj) => obj?.id === gameItem?.id)
                ? "active"
                : "null"
            }`}
            onClick={handleFavoriteUserSelect}
          />
        ) : (
          <i
            className={`casino-game-item-fav-bc bc-i-favorite ${
              favoritsGamesLocal.some((obj) => obj?.id === gameItem?.id)
                ? "active"
                : "null"
            }`}
            onClick={handleFavoriteLocalSelect}
          />
        )}
      </div>
    </div>
  );
};

export { GameItem };
