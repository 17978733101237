import { NavigateFunction } from "react-router-dom";
import { AviatorFreebet } from "./promotionsInfo/AviatorFreebet/AviatorFreebet";
import { DropWins } from "./promotionsInfo/DropWins/DropWins";
import { MegaCandyFestival } from "./promotionsInfo/MegaCandyFestival/MegaCandyFestival";
import { WazdanMysteryDrop } from "./promotionsInfo/WazdanMysteryDrop/WazdanMysteryDrop";

type cartType = {
  navigate: NavigateFunction;
  language: string;
};

type Promotion = {
  name: string;
  img: string;
  id: string;
  path_name: string;
  cart: (arg0: cartType) => JSX.Element;
};

type PromotionsMockSlots = Record<string, Promotion>;

const promotionsMockSlots: PromotionsMockSlots = {
  163943: {
    name: "Mega Şeker Festivali",
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_23363f01d50dd9b91c3d1982be8d4a45.webp",
    id: "163943",
    path_name: "mega-şeker-festivali",
    cart: MegaCandyFestival,
  },
  28476: {
    name: "Drop & Wins",
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_2216ffd50f677b09c4634e5cfdfe6d85.webp",
    id: "28476",
    path_name: "drop---wins",
    cart: DropWins,
  },
  134426: {
    name: "Wazdan - Mystery Drop",
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_78e431a5f09a8f84eb44a6a553b3105b.webp",
    id: "134426",
    path_name: "wazdan---mystery-drop",
    cart: WazdanMysteryDrop,
  },
  82602: {
    name: "Her Yatırıma Aviator Freebet",
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_02b1e9a724621904f6e99dd0bc8bfd83.webp",
    id: "82602",
    path_name: "her-yatırıma-aviator-freebet",
    cart: AviatorFreebet,
  },
};

export { promotionsMockSlots };

export type { Promotion };
