type IsetModalRegister = {
  type: string;
  payload: boolean;
};

function setModalRegister(payload: boolean): IsetModalRegister {
  return {
    type: 'SET_MODAL_REGISTER',
    payload,
  };
}

export { setModalRegister };
