import React from "react";
import { Link } from "react-router-dom";
import "./Logo.scss";

import logo from "../../../../../../accets/images/png/logo.png";
import { useTypeSelector } from "../../../../../../hooks/useTypeSelector";

const Logo = () => {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div className="logo-container">
      <Link className="logo" to={`/${language}/`}>
        <img className="hdr-logo-bc" src={logo} alt="" />
      </Link>
      <Link
        target="_blank"
        className=" header-icon"
        to={`/${language}/wonderwheel`}
      >
        <img
          src="https://cmsbetconstruct.com/storage/medias/jupiterbahis/media_1874369_e4a76d8655d05e9fa2c60284e837741d.png"
          alt=""
        />
      </Link>
    </div>
  );
};

export { Logo };
