import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./BetBuilderManagement.scss";

interface IBetBuilderManagement {
  page: string;
}

const BetBuilderManagement = ({ page }: IBetBuilderManagement) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const account = searchParams.get("account") || "";
  //   const page = searchParams.get("page") || "";
  const [modalActive, setModalActive] = useState(false);
  const handleOpenModal = () => {
    setModalActive((prev) => !prev);
  };
  useEffect(() => {
    if (account === "bet-builder-history") {
      setModalActive(true);
    }
  }, []);
  return (
    <div className={`user-profile-nav ${modalActive ? "active" : ""}`}>
      <div className="user-profile-nav-header" onClick={handleOpenModal}>
        <i className="user-profile-nav-icon bc-i-bet-builder-history" />
        <span className="user-profile-nav-title">{t("admin.BetBuilderhistory")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i
          className={`user-profile-nav-arrow bc-i-small-arrow-${
            modalActive ? "up" : "down"
          }`}
        />
      </div>
      <div className="user-profile-nav-list">
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "bets" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=bets",
          }}
        >
          {t("admin.All")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "open-bets" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=open-bets",
          }}
        >
          {t("admin.Openbets")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "won" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=won",
          }}
        >
          {t("admin.Won")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "lost" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=lost ",
          }}
        >
          {t("admin.Lost")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <div className="user-profile-nav-item-cursor " />
      </div>
    </div>
  );
};

export { BetBuilderManagement };
