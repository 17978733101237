import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import "./RemindMeInput.scss";
import { FormLogin } from "../../ModalAuthForm";
import { t } from "i18next";

interface IRemindMeInput {
  register: UseFormRegister<FormLogin>;
}

const RemindMeInput = ({ register }: IRemindMeInput) => {
  return (
    <div className="entrance-f-item-bc entrance-f-item-checkbox-bc">
      <div className="checkbox-control-bc">
        <label className="checkbox-control-content-bc">
          <input
            type="checkbox"
            className="checkbox-control-input-bc"
            step={0}
            {...register("remindMe", { value: false })}
          />
          <i className="checkbox-control-icon-bc bc-i-checked checkbox-custom" />
          <p className="checkbox-control-text-bc">{t("admin.Rememberme")}</p>
        </label>
      </div>
    </div>
  );
};

export { RemindMeInput };
