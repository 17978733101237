import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const [footerOpen, setFooterOpen] = useState(false);
  const handleOpenFooter = () => {
    setFooterOpen((prev) => !prev);
  };
  return (
    <div className={`layout-footer-holder-bc ${footerOpen ? "active" : ""}`}>
      <i
        className="open-footer-icon bc-i-arrow-drop-down-up"
        onClick={handleOpenFooter}
      />
      <div className="footer-bc">
        <div className="ftr-link">
          <div className="ftr-n-item-bc">
            <div className="ftr-n-l-cells-bc">
              <h4 className="footer-title custom-footer-title">Hakkımızda</h4>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=30749",
                }}
              >
                <span>Firma Bilgileri</span>
              </Link>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=27091",
                }}
              >
                <span>İletişim</span>
              </Link>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=27089",
                }}
              >
                <span>Sorumlu Oyunculuk</span>
              </Link>
            </div>
            <div className="ftr-n-l-cells-bc">
              <h4 className="footer-title">Kurallar ve Şartlar</h4>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=17125",
                }}
              >
                <span>Genel Kurallar ve Şartlar</span>
              </Link>
            </div>
            <div className="ftr-n-l-cells-bc">
              <h4 className="footer-title">Hüküm ve Koşullar</h4>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=17125",
                }}
              >
                <span>Hüküm ve Koşullar</span>
              </Link>
            </div>
            <div className="ftr-n-l-cells-bc">
              <h4 className="footer-title">Gizlilik</h4>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=30750",
                }}
              >
                <span>Gizlilik İlkesi</span>
              </Link>
              <Link
                target="_self"
                className="ftr-n-link-bc undefined"
                to={{
                  search: "?helpPageContent=30752",
                }}
              >
                <span>Çerez Bildirimi</span>
              </Link>
            </div>
          </div>
          <div className="footer-age-social-block">
            <div className="ftr-social-link-row-column-bc">
              <div rel="noreferrer" className="ftr-social-link-c-item-bc">
                <i className="social-icon bc-i-twitter"></i>
              </div>
              <div rel="noreferrer" className="ftr-social-link-c-item-bc">
                <i className="social-icon bc-i-telegram"></i>
              </div>
            </div>
            <span className="ftr-age-bc">18+</span>
          </div>
        </div>
        <div className="footer-title">Regulations &amp; Partners</div>
        <div className="regulation-partner">
          <div className="ftr-partners-row-bc">
            <div className="ftr-partners-row-inner-bc ">
              <div rel="noreferrer" className="">
                <img
                  className="ftr-partners-r-img"
                  loading="lazy"
                  src="https://cmsbetconstruct.com/content/images/29254551737a317c3c510123910bdfe0_1873892_media.png"
                  alt=""
                />
              </div>
              <div rel="noreferrer" className="">
                <img
                  className="ftr-partners-r-img"
                  loading="lazy"
                  src="https://cmsbetconstruct.com/content/images/b35b511a9714e701df9a1f69ca39275c_1873892_media.png"
                  alt=""
                />
              </div>
            </div>
            <div className="ftr-partners-row-inner-bc ">
              <div
                id="apg-seal-container"
                data-apg-seal-id="965dc6d2-26ec-4e34-b4dc-c9831d7a20e9"
                data-apg-image-size="128"
                data-apg-image-type="basic-small"
              >
                <div
                  style={{
                    display: "block",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <div rel="nonoopener">
                    <img
                      alt=""
                      style={{ width: "100%", height: "auto" }}
                      src="https://965dc6d2-26ec-4e34-b4dc-c9831d7a20e9.snippet.antillephone.com/sealassets/a1026c218807ac26306b0d5a464bea91-www.maltbahis290.com-2bee807cd72da8a8805c62d49a5c434a9f1d9baf416dcef0271604651c2c4fb6910512fb39a3047754c26525a37b4764-c2VhbC5wbmc%3D?status=valid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ftr-partners-row-inner-bc ">
              <p className="ftr-copy-rights-bc">
                © 2022 K Softworks NV - all rights reserved. K Softworks NV is a
                registered company in Perseusweg 27/A Curacao and licenced for
                online gaming by the gaming commission under numbers 8048/JAZ
                2016-081. Maltbahis, in adherence with Curacao regulations, does
                not allow players aged under 18 to use its online gaming site.
              </p>
            </div>
          </div>
        </div>
        <div className="horizontal-sl-list casino-horizontal-sl-list-bc horizontal-items-expanded scroll-end ">
          <i className="horizontal-sl-arrow bc-i-small-arrow-left"></i>
          <div className="horizontal-sl-wheel" />
          <img
            id="7283_Jupiterbahise Para Cek"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7283.png"
            alt="WeTransfer"
          />
          <img
            id="4672_Hizlipapara"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/4672.png"
            alt="Hizlipapara"
          />
          <img
            id="12715_Crypto Withdrawal"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/12715.png"
            alt="Crypto Withdrawal"
          />
          <img
            id="12208_FulgurPayFTN"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/default/12208.png"
            alt="FTN"
          />
          <img
            id="10423_FulgurPay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10423.png"
            alt="Cryptocurrency"
          />
          <img
            id="8006_SafePay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/8006.png"
            alt="SafePay"
          />
          <img
            id="10795_HedefHavale"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10795.png"
            alt="HedefHavale"
          />
          <img
            id="10078_InstapaysCrypto"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10078.png"
            alt="Instapay Crypto"
          />
          <img
            id="7674_InstapaysBank"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7674.png"
            alt="Simple Havale"
          />
          <img
            id="4338_Fixturka"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/4338.png"
            alt="Fixturka"
          />
          <img
            id="1056_CoinPayments"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/1056.png"
            alt="CoinPayments"
          />
          <img
            id="7049_AnindaMFT"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7049.png"
            alt="AnindaMFT"
          />
          <img
            id="7090_CepBank"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7090.png"
            alt="CepBank"
          />
          <img
            id="5344_InstaPayPaparaV2"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5344.png"
            alt="InstaPay Papara"
          />
          <img
            id="3257_AnindaKrediKarti"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/3257.png"
            alt="AnindaKrediKarti"
          />
          <img
            id="6439_MultiIpay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/6439.png"
            alt="MultiIpay"
          />
          <img
            id="5452_MaksiparaCreditCard"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5452.png"
            alt="MaksiCC"
          />
          <img
            id="5688_MPay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5688.png"
            alt="MPay Papara"
          />
          <i className="horizontal-sl-arrow bc-i-small-arrow-right"></i>
        </div>
        <div className="ftr-copy-rights-bc">© 2021 - 2023 Maltbahis</div>
      </div>
    </div>
  );
};

export { Footer };
