/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { NavigateFunction } from "react-router-dom";

import "./WazdanMysteryDrop.scss";

interface IWazdanMysteryDrop {
  navigate: NavigateFunction;
  language: string;
}

const WazdanMysteryDrop = ({ navigate, language }: IWazdanMysteryDrop) => {
  const handleCloseInfo = () => {
    navigate(`/${language}/promotions/all`);
  };

  return (
    <>
      <i
        className="e-p-close-icon-bc bc-i-close-remove"
        onClick={handleCloseInfo}
      />
      <div className="overlay-header">Wazdan - Mystery Drop</div>
      <div className="promotion-details-container">
        <div className="promotion-details-header">
          <img
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_78e431a5f09a8f84eb44a6a553b3105b.webp"
            className="promotion-details-img"
            alt=""
            title="Wazdan - Mystery Drop"
          />
        </div>
        <div className="promotion-details-info" data-scroll-lock-scrollable="">
          <p>
            <strong>MYSTERY DROP &nbsp;</strong>
          </p>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              Kurallar ve Şartlar
            </div>
            <div className="spoiler-content">
              <p>
                - Mystery Drop kampanyası&nbsp;yalnızca gerçek oynama modunda
                kullanılabilir.&nbsp;
              </p>
              <p>
                - Kampanya 15 Mayıs saat 9:00'da başlar ve 15 Ağustos saat
                23:59'a kadar devam eder.&nbsp;
              </p>
              <p>-Tüm Wazdan Slot oyunları kampanyaya dahildir.&nbsp;</p>
              <p>
                - Katılan oyuncular, kampanya süresi boyunca kampanyaya dahil
                olan oyunlara uygun bir bahis oynayarak kampanyada yer
                alacaklardır. Bunun dışında ek bir ücret bulunmamaktadır.&nbsp;
              </p>
              <p>
                - Oyuncular, kampanya sırasında rastgele olarak birden fazla
                ödül kazanabilir.&nbsp;
              </p>
              <p>
                - Kampanya döneminde kampanya dahilindeki oyunlarda geçerli olan
                herhangi bir bahis, ödül havuzundan bir ödülü aktive
                edebilir.&nbsp;
              </p>
              <p>
                - Geçerli bir bahis yalnızca bir ödül kazanabilir. Ödüller
                kampanya süresince rastgele dağıtılacaktır.&nbsp;
              </p>
              <p>
                - Minimum bahis miktarı gereksinimi yoktur. Tüm bahisler
                kampanyaya katılmaya hak kazanır.&nbsp;
              </p>
              <p>- Mevcut ödüllerin sayısı anında güncellenir.&nbsp;</p>
              <p>
                - Kazanılan tüm ödüller, kazan. bildirimi aldıktan sonra anında
                oyuncuların&nbsp;hesaplarına yatırılacaktır.&nbsp;
              </p>
              <p>- Ödüller, ödül havuzu tablosuna göre ödenecektir.&nbsp;</p>
              <p>
                - Nakit ödüllerin maksimum toplam değeri, 12500 Mystery Box'a
                bölünmüş 1.250.000 EUR'dur.&nbsp;
              </p>
              <p>- Nakit ödüllerin herhangi bir bahis şartı yoktur.&nbsp;</p>
              <p>
                - Bonus kullanılırken ödenen ödüller, bonus fonlarda
                ödenecektir.&nbsp;
              </p>
              <p>
                - Nakit ödüller, kazanan bahsin oynandığı andaki Euro'dan para
                birimine çevrilmesine göre, kazanan bahsin oynandığı para
                biriminde ödenecektir.&nbsp;
              </p>
              <p>
                - Yalnızca doğrulanmış Oyuncular ödülleri kazanmaya hak
                kazanır.&nbsp;
              </p>
              <p>
                - Maltbahis , katılımcı oyunlardan herhangi biriyle ilgili
                olarak bir makine veya insan hatasından kaynaklanan herhangi bir
                bariz hata, yanlışlık veya teknik hatadan (yanlış oyun ödemeleri
                dahil) dolayı nakit ödülü ödememe hakkını saklı tutar.&nbsp;
              </p>
              <p>
                - Maltbahis ayrıca, kazancın hile veya diğer oyuncularla gizli
                anlaşmadan kaynaklandığına kanaat getirdiği durumlarda nakit
                ödül ödememe hakkını saklı tutar.&nbsp;
              </p>
              <p>
                - Maltbahis, bu promosyonu herhangi bir zamanda herhangi bir
                nedenle değiştirme, durdurma veya sonlandırma hakkını saklı
                tutar ve ayrıca bu kampanyanın hüküm ve koşullarını herhangi bir
                zamanda değiştirme hakkını saklı tutar.&nbsp;
              </p>
              <p>- Maltbahis Promosyon Hüküm ve Koşulları geçerlidir.&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              ÖDÜL HAVUZ TABLOSU&nbsp;
            </div>
            <div className="spoiler-content">
              <p>
                Nakit ödüllerin maksimum toplam değeri 1.250.000 EUR'dur. Toplam
                12500 Mystery Box vardır. Her bir Mystery Box aşağıdaki listeden
                bir ödül verebilir:&nbsp;
              </p>
              <p>100 EURO&nbsp;</p>
              <p>60 EURO&nbsp;</p>
              <p>40 EURO&nbsp;</p>
              <p>20 EURO&nbsp;</p>
              <p>10 EURO&nbsp;</p>
              <p>5 EURO&nbsp;</p>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export { WazdanMysteryDrop };
