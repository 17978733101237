import React, { useEffect, useState } from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import "./PasswordInput.scss";
import { FormLogin } from "../../ModalAuthForm";
import { t } from "i18next";

interface IAuthFormInputs {
  register: UseFormRegister<FormLogin>;
  watch: UseFormWatch<FormLogin>;
  setValue: UseFormSetValue<FormLogin>;
}

const PasswordInput = ({ register, watch, setValue }: IAuthFormInputs) => {
  const [inputValue, setInputValue] = useState("");
  const [defaulError, setDefaultError] = useState(false);
  const [inputFocused, setInputFocuse] = useState(false);

  const handleInputFocus = () => {
    setInputFocuse(true);
  };

  const handleInputBlur = () => {
    setInputFocuse(false);
    if (inputValue?.length > 6) {
      setDefaultError(false);
    } else {
      setDefaultError(true);
    }
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (inputValue?.length > 6) {
      setDefaultError(false);
    } else {
      setDefaultError(true);
    }
    setValue("password", e.target.value);
  };
  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default ${
          inputFocused || inputValue?.length > 0 ? "focused" : ""
        } ${defaulError ? "invalid" : "valid"}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="password"
            className="form-control-input-bc"
            step={0}
            {...register("password")}
            defaultValue=""
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChangeText}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">{t("admin.Password")}</span>
        </label>
        {defaulError ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
            {t("admin.Thisfieldisrequired")}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { PasswordInput };
