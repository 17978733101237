import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "./CompanyInfoModal.scss";
import { Communication } from "./Modals/Communication/Communication";
import { CompanyInfo } from "./Modals/CompanyInfo/CompanyInfo";
import { CookieNotice } from "./Modals/CookieNotice/CookieNotice";
import { GeneralTerms } from "./Modals/GeneralTerms/GeneralTerms";
import { PrivacyPolicy } from "./Modals/PrivacyPolicy/PrivacyPolicy";
import { ResponsibleActing } from "./Modals/ResponsibleActing/ResponsibleActing";

interface ICompanyInfoModal {
  helpPageContent: string;
}

const CompanyInfoModal = ({ helpPageContent }: ICompanyInfoModal) => {
  const wrapperRef = useRef(null);

  const navigate = useNavigate();

  const handleCloseWindow = () => {
    navigate("", {
      replace: true,
    });
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="popup-holder-bc windowed  content-manager ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc" ref={wrapperRef}>
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={() => handleCloseWindow()}
          />
          {helpPageContent === "30749" ? <CompanyInfo /> : null}
          {helpPageContent === "27091" ? <Communication /> : null}
          {helpPageContent === "27089" ? <ResponsibleActing /> : null}
          {helpPageContent === "17125" ? <GeneralTerms /> : null}
          {helpPageContent === "30750" ? <PrivacyPolicy /> : null}
          {helpPageContent === "30752" ? <CookieNotice /> : null}
        </div>
      </div>
    </div>
  );
};

export { CompanyInfoModal };
