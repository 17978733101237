import { t } from "i18next";
import React from "react";
import { ImockSelectUserList } from "../../BuddyTransfer";
import "./TransferSelect.scss";

interface ITransferSelect {
  selectedRadioUser: ImockSelectUserList;
}

const TransferSelect = ({ selectedRadioUser }: ITransferSelect) => {
  return (
    <div
      className={`buddy-transfer-item ${
        selectedRadioUser.name === "username" ? "buddy-transfer-select" : ""
      } ${selectedRadioUser.name === "userId" ? "transfer-amount" : ""}`}
    >
      {selectedRadioUser.name === "username" ? (
        <p className="empty-b-text-v-bc">{t("admin.You have not friends")} </p>
      ) : null}
      {selectedRadioUser.name === "friendName" ? (
        <div className="form-control-bc default ">
          <label className="form-control-label-bc inputs">
            <input
              type="text"
              className="form-control-input-bc"
              name="toFriend"
              step={0}
              defaultValue=""
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">{t("admin.FriendName")}</span>
          </label>
        </div>
      ) : null}
      {selectedRadioUser.name === "userId" ? (
        <div className="form-control-bc default ">
          <label className="form-control-label-bc inputs">
            <input
              type="text"
              inputMode="decimal"
              className="form-control-input-bc"
              name="toUserId"
              step={0}
              defaultValue=""
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">{t("admin.user id")}</span>
          </label>
        </div>
      ) : null}
    </div>
  );
};

export { TransferSelect };
