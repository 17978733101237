type IsetOddsFormat = {
  type: string;
  payload: string;
};

function setOddsFormat(payload: string): IsetOddsFormat {
  return {
    type: "SET_ODDS_FORMAT",
    payload,
  };
}

export { setOddsFormat };
