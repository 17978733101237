import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { CasinoInfo } from "../../services";

import "./Aviator.scss";

const Aviator = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [gameLink, setGameLink] = useState("");
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  useEffect(() => {
    async function searchPlaySecond() {
      try {
        const res = await CasinoInfo.casinoPlay(
          `/spribe/aviator?currency=TRY&lang=${language}&platform=desktop&game_link=true`
        );
        setGameLink(res.data.game_link);
      } catch {
        console.error("");
      }
    }
    searchPlaySecond();
  }, []);
  return (
    <Layout
      addClasses={`${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } page-full-size hide-tab-navigation-footer-mobile compact-footer ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="aviator"
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <iframe
        className="iframe-full-page"
        title={gameLink}
        src={gameLink}
        allowFullScreen={true}
        allow="autoplay; microphone *"
      />
    </Layout>
  );
};

export { Aviator };
