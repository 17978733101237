import { t } from "i18next";
import React from "react";
import "./RangePeriod.scss";

const RangePeriod = () => {
  return (
    <>
      <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
        <div className="react-datepicker-wrapper">
          <div className="react-datepicker__input-container">
            <div className="form-control-bc default filled ">
              <label className="form-control-label-bc inputs">
                <input
                  className="form-control-input-bc "
                  type="text"
                  placeholder="From"
                  // readOnly={true}
                  defaultValue=""
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Range")}
                </span>
                <i className="dropdownIcon-bc bc-i-datepicker" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
        <div className="react-datepicker-wrapper">
          <div className="react-datepicker__input-container">
            <div className="form-control-bc default filled ">
              <label className="form-control-label-bc inputs">
                <input
                  className="form-control-input-bc "
                  type="text"
                  placeholder="To"
                  readOnly={false}
                  defaultValue=""
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis" />
                <i className="dropdownIcon-bc bc-i-datepicker" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { RangePeriod };
