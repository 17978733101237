import item1 from "./accets/Mega Şeker Festivali.webp";
import item2 from "./accets/Son Çıkan Oyunlar.webp";
import item3 from "./accets/Power Of Merlins - Megaways.webp";
import item4 from "./accets/Dropandwinsslots.webp";
import item5 from "./accets/Zeus vs Hades.webp";

type mockCouruselCasinoType = {
  id: number;
  img: string;
  title: string;
};

const mockCouruselCasino: mockCouruselCasinoType[] = [
  {
    id: 1,
    img: item1,
    title: "Mega Şeker Festivali",
  },
  {
    id: 2,
    img: item2,
    title: "Son Çıkan Oyunlar",
  },
  {
    id: 3,
    img: item3,
    title: "Power Of Merlins - Megaways",
  },
  {
    id: 4,
    img: item4,
    title: "Dropandwinsslots",
  },
  {
    id: 5,
    img: item5,
    title: "Zeus vs Hades",
  },
];

export { mockCouruselCasino };
export type { mockCouruselCasinoType };
