import { UserInformation } from "../../../common/types/AdminInfo/UserInformation";

type IsetAuth = {
  type: string;
  payload: string;
};

function setAuth(payload: string): IsetAuth {
  return {
    type: 'SET_AUTH',
    payload,
  };
}

export { setAuth };
