import React from "react";
import { userDeposit } from "../../../../../../../../../common/types/AdminInfo/userDeposit";
import "./PayemntItem.scss";

interface IPayemntItem {
  paymentInfo: userDeposit | undefined;
  selectedPaymentMethod: userDeposit | undefined;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<userDeposit>>;
  setButtonSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const PayemntItem = ({
  paymentInfo,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setButtonSubmitDisabled,
  setInputValue,
}: IPayemntItem) => {
  const handleChangeSelectedPayment = (item: userDeposit | undefined) => {
    if (item) {
      setSelectedPaymentMethod(item);
    }
    setInputValue('');
    setButtonSubmitDisabled(false);
  };
  return (
    <div
      className={`m-nav-items-list-item-bc ${
        selectedPaymentMethod?.id === paymentInfo?.id ? "active" : ""
      }`}
      onClick={() => handleChangeSelectedPayment(paymentInfo)}
    >
      <div className="nav-ico-w-row-bc">
        {/* <img className="payment-logo" src={paymentInfo.img} alt="" /> */}
        <p>{paymentInfo?.name}</p>
      </div>
    </div>
  );
};

export { PayemntItem };
