import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Zeplin } from "../pages/Zeplin/Zeplin";
import { Aviator } from "../pages/Aviator/Aviator";
import { Casino } from "../pages/Casino/Casino";
import { Live } from "../pages/Live/Live";
import { LiveCasino } from "../pages/LiveCasino/LiveCasino";
import { Main } from "../pages/Main/Main";
import { SpaceMan } from "../pages/SpaceMan/SpaceMan";
import { Sport } from "../pages/Sport/Sport";
import { Tournaments } from "../pages/Tournaments/Tournaments";
import { MaltbahisCarc } from "../pages/MaltbahisCarc/MaltbahisCarc";
import { Jackpot } from "../pages/Jackpot/Jackpot";
import { Promotions } from "../pages/Promotions/Promotions";
import { Games } from "../pages/Games/Games";
import { CaniTakvim } from "../pages/CaniTakvim/CaniTakvim";
import { BahisTurnuvalari } from "../pages/BahisTurnuvalari/BahisTurnuvalari";
import { SportTournaments } from "../pages/SportTournaments/SportTournaments";
import { setAuth } from "../store/action/auth/setauth";
import { useDispatch } from "react-redux";
import { RouteWrapper } from "./RoutWrapper";
import { AdminPanel, CasinoInfo } from "../services";
import { setUserInfo } from "../store/action/auth/setuserinfo";
import { setUserBalance } from "../store/action/auth/setuserbalance";
import { useTypeSelector } from "../hooks/useTypeSelector";
import { setFavoritsUser } from "../store/action/favorits/setFavoritsUser";
// import loadGit from "./accets/images/gif/loader/loader.gif";
import loadGit from "../accets/images/gif/loader/loader.gif";
import { setUserInit } from "../store/action/auth/setuserinit";

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { ErrorPage } from "../pages/ErrorPage/ErrorPage";

window.Pusher = Pusher;

declare global {
  interface Window {
    Pusher: typeof Pusher;
  }
}

declare global {
  interface Window {
    Echo: any;
  }
}

function RedirectToMain() {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return <Navigate to={`/${language}/`} />;
}

const Navigation = () => {
  const bearerToken = localStorage.getItem("token") || "1234";
  const user = useTypeSelector((data) => data.accountInfoReducer.user);
  const [loading, setLoading] = useState(true);
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const dispatch = useDispatch();
  async function userBalance() {
    try {
      const resbalance = await AdminPanel.userBalance();
      dispatch(setUserBalance(resbalance.data));
      dispatch(setAuth("player"));
      getFavoritsGames();
    } catch (error) {
      console.error(error);
      localStorage.removeItem("token");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }
  async function userInfo() {
    try {
      const res = await AdminPanel.userInfo();
      dispatch(setUserInfo(res.data));
    } catch (error) {
      localStorage.removeItem("token");
    }
  }
  async function getFavoritsGames() {
    const res = await CasinoInfo.favoritGames();
    dispatch(setFavoritsUser(res.data));
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isAuthenticated = !!token;
    if (isAuthenticated) {
      userBalance();
      userInfo();
    } else {
      dispatch(setUserInit());
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);
  const echo = new Echo({
    broadcaster: "pusher",
    key: "b96900e87fe4fad222df",
    cluster: "eu",
    encrypted: true,
    forceTLS: true,
    authEndpoint: "https://casino-api.services/broadcasting/auth",
    auth: { headers: { Authorization: "Bearer " + bearerToken } },
  });
  echo
    .private("user-" + user.api_user_id)
    .listen("BalanceUpdated", (e: { amount: number; amount_bonus: number }) => {
      const amount = e.amount / 100;
      dispatch(
        setUserBalance({
          balance: {
            amount: amount,
            amount_bonus: 0,
            currency: "",
          },
          cryptoBalance: null,
          fiatBalance: null,
          active_bonus: null,
          init: false,
          extra_data: {
            game_token: "",
            softswiss_freespin: false,
            crypto_payment: null,
          },
        })
      );
    });
  return (
    <>
      {loading ? (
        <div className="layout-bc">
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loadGit} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <Router>
          <Routes>
            <Route
              path={"/:en/"}
              element={<RouteWrapper element={<Main />} />}
            />
            <Route path="/" element={<RedirectToMain />} />
            <Route
              path={"/:en/sports/live/event-view/"}
              element={<RouteWrapper element={<Live />} />}
            />
            <Route
              path={"/:en/sports/pre-match/event-view"}
              element={<RouteWrapper element={<Sport />} />}
            />
            <Route
              path={"/:en/sports/pre-match/tournaments"}
              element={<RouteWrapper element={<SportTournaments />} />}
            />
            <Route
              path={"/:en/casino/slots/-1"}
              element={<RouteWrapper element={<Casino />} />}
            />
            <Route
              path={"/:en/casino/game-view/806666"}
              element={<RouteWrapper element={<Aviator />} />}
            />
            <Route
              path={"/:en/casino/game-view/400043028"}
              element={<RouteWrapper element={<SpaceMan />} />}
            />
            <Route
              path={"/:en/casino/game-view/9010"}
              element={<RouteWrapper element={<Zeplin />} />}
            />
            <Route
              path={"/:en/live-casino/home/-1/All"}
              element={<RouteWrapper element={<LiveCasino />} />}
            />
            <Route
              path={"/:en/wonderwheel"}
              element={<RouteWrapper element={<MaltbahisCarc />} />}
            />
            <Route
              path={"/:en/casino/jackpot"}
              element={<RouteWrapper element={<Jackpot />} />}
            />
            <Route
              path={"/:en/promotions/all"}
              element={<RouteWrapper element={<Promotions />} />}
            />
            <Route
              path={"/:en/promotions/all/:id/:name"}
              element={<RouteWrapper element={<Promotions />} />}
            />
            <Route
              path={"/:en/games"}
              element={<RouteWrapper element={<Games />} />}
            />
            <Route
              path={"/:en/casino/tournaments"}
              element={<RouteWrapper element={<Tournaments />} />}
            />
            <Route
              path={"/:en/sports/live-calendar"}
              element={<RouteWrapper element={<CaniTakvim />} />}
            />
            <Route
              path={"/:en/sports/tournaments"}
              element={<RouteWrapper element={<BahisTurnuvalari />} />}
            />
            <Route
              path={"*"}
              element={<RouteWrapper element={<ErrorPage />} />}
            />
          </Routes>
        </Router>
      )}
    </>
  );
};

export { Navigation };
