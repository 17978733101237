import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import "./SidePanelNotification.scss";

interface ISidePanelNotification {
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notificationOpen: boolean;
}

const SidePanelNotification = ({
  setNotificationOpen,
  notificationOpen,
}: ISidePanelNotification) => {
  const wrapperRef = useRef(null);

  const handleClosePanel = () => {
    setNotificationOpen(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className={`sidePanel-holder-bc ${notificationOpen ? "" : "hidden"}`}>
      <div className="sidePanel-holder" ref={wrapperRef}>
        <div className="overlay-header">
          <i className="bc-i-close-remove" onClick={handleClosePanel} />
          <span>{t("admin.Whatsnew")}</span>
        </div>
        <p className="empty-b-text-v-bc">
          {t("admin.Youhavenonewnotifications")}
        </p>
      </div>
    </div>
  );
};

export { SidePanelNotification };
