import React from "react";

import "./Communication.scss";

const Communication = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">İletişim</span>
        <div className="printAndDownload">
          <i className="print-icons  bc-i-Print" />
          <i className="print-icons  bc-i-download" />
        </div>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p>
          <strong>CANLI SOHBET</strong>
          <br />
          <br />
          7/24 Canlı Destek hizmetimiz size yardımcı olmaktan mutluluk
          duyacaktır.
          <br />
          <br />
          <strong>E-POSTA</strong>
        </p>
        <p>
          <br />
          Tüm soru ve sorunlarınız için lütfen bize&nbsp;
          <a href="mailto:destek@maltbahis.com">destek@maltbahis.com</a>
          &nbsp;adresinden e-posta gönderin, adınızı ve hesap numaranızı
          belirtmeyi unutmayın.
          <br />
          <br />
          Diğer tüm sorularınız veya teklifleriniz için, lütfen bize&nbsp;
          <a href="mailto:info@maltbahis.com">info@maltbahis.com</a>
          &nbsp;adresinden e-posta gönderin.
          <br />
          <br />
          Lütfen doğrulama belgelerinizi gönderirken
        </p>
        <p>
          <a href="mailto:admin@maltbahis.com">admin@maltbahis.com</a>
          &nbsp;adresini kullanın.
        </p>
      </div>
    </div>
  );
};

export { Communication };
