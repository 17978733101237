/* eslint-disable react/no-unescaped-entities */
import React from "react";

import "./GeneralTerms.scss";

const GeneralTerms = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">
          Genel Kurallar ve Şartlar
        </span>
        <div className="printAndDownload">
          <i className="print-icons  bc-i-Print" />
          <i className="print-icons  bc-i-download" />
        </div>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>HESAP AÇMAK</span>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              1.Web sitesine üye olmak için destek hattından yardım
              alabilirsiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              2.Üyelik oluşturduğunuzda sizden isim, doğum tarihi ve yanı sıra
              adres telefon ve e-posta bilgilerinden oluşan iletişim
              bilgileriniz istenilecektir ("İletişim Bilgileri").
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              3.Web sitesi hizmetlerini kullanarak para kazanabileceğinizi ve
              kaybedebileceğinizi kabul etmiş sayılırsınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.Hesabınız kendi adınıza ve doğru bilgilerle açılmış olmalıdır.
              Her üye yalnız 1 hesap sahibi olabilir. Sitemizde açacağınız
              herhangi başka bir hesap birden fazla kayıt olarak
              değerlendirilecektir. Birden fazla açtığınız hesaplarınız
              tarafımızdan anında kapatılabilir ve:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.5.1.birden fazla hesaptan yapılan tüm işlemler geçersiz sayılır;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.5.2.yapılan tüm para yatırma ve bahisler size geri gönderilir;
              ve
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.5.3.Birden fazla hesap aktif iken tahakkuk eden tüm kazanımlar,
              bonuslar kaybedilebilir ve tarafımızdan geri alınabilir, yapılan
              para çekimlerinin iade edilmesi istenebilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.6.Başka bir hesap açmak istiyorsanız bunu destek birimi ile
              görüşerek yapmalısınız info@olipsbet.com Yeni bir hesap
              açıldığında eski hesap kapatılır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              4.7.Hesap bilgilerinizi korumalı ve güncel tutmalısınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              KİMLİK DOĞRULAMA, KARA PARA ÖNLEME POLİTİKASI
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.1.Aşağıdaki maddeler tarafınızdan garanti edilir:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>5.1.1.Yaş sınırı:</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.1.1.1.18 yaşından büyük; veya
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.1.1.2.Bulunduğunuz ülkede yer alan yasal bahis ve oyun oynama
              yaşından büyük; ve
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.1.2.Hesabınızı açarken kullandığınız bilgilerin doğru olduğu; ve
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.1.3.Hesabınızda bulunan paranın size ait olduğu.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.2.Kuralları kabul ederek ihtiyaç duyulması halinde kişisel
              bilgilerinizin ve iletişim bilgilerinizi tarafımızdan veya gerek
              duyulması halinde üçüncü şahıslar tarafından kontrol edilmesine
              izin vermiş olursunuz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.3.Hesap bilgileriniz kontrol edilirken hesabınızdan para
              çekmeniz kısıtlanabilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.4.Bazı durumlarda kimlik doğrulama için sizinle iletişime
              geçilebilir ve bilgi vermeniz istenebilir. İstenilen bilgilerin
              sağlanmaması durumunda hesabınız istenilen bilgiler sağlanana
              kadar askıya alınabilir veya kalıcı olarak kapatılabilir.{" "}
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.5.Yasal yaşın üstünde olduğunuzun doğrulanamazsa hesabınız
              askıya alınır. Eğer bahis veya oyun oynadığınız sırada yasal yaş
              sınırının altında olduğunuz ispatlanırsa:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.5.1.Hesabınız kapatılır;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.5.2.yasal yaş sınırının altında iken yaptığınız tüm işlemler
              iptal edilir, tüm para ödemeleriniz iade edilir;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.5.3.Oynadığınız tüm bahisler iptal edilir; ve
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              5.5.4.Kazanılan tüm bahisler iptal edilir ve para çekimi
              yapılmışsa çekilen paraların iadesi istenir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              KULLANICI ADI, ŞİFRE VE MÜŞTERİ BİLGİLERİ
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              6.1.Hesap açıldıktan sonra kullanıcı ismi ve şifre kasıtlı ya da
              kasıtsız ifşa edilmemelidir. Eğer şifrenizi kaybeder veya
              unutursanız giriş bölümünde “Şifremi Unuttum” linkine tıklayarak
              şifre bilgilerinizi alabilirsiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              HESABINIZDAN PARA YATIRMA VE PARA ÇEKME
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.1.Web sitesi üzerinden bahis ve oyun oynamak için hesabınıza
              para yatırmanız gerekmektedir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.2.Para yatırma ve çekme işlemleri çeşitli ödeme sistemleri ile
              yapılabilir. Prosedürler, kurallar ve koşullar ödeme sistemine
              bağlı olarak değişmektedir. Nakit ödemeler tarafımızdan kabul
              edilmemektedir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.3.Hesabınıza para yatırarak, para yatırma işlemine iptal ve
              itiraz etmeyeceğinizi, ödenmeyen para yatırma işlemlerinin iade ve
              tazmin edeceğinizi kabul etmiş sayılırsınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.4.Hesabınız bir banka hesabı değildir ve garanti, sigorta ve
              sponsoru yoktur, herhangi bir bankacılık sigorta sistemi dahilinde
              değildir ve hesabınıza yatırılan paralardan faiz geliri sağlamanız
              mümkün değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.5.Hesap bakiyeniz ve yapılan bahislerin kazanılan miktara
              bakılmaksızın birden fazla hesap, gizli anlaşma, hile,
              dolandırıcılık, illegal aktiviteler veya oluşan hatalar
              gerekçeleri ile tekrar hesaplanma hakkı tarafımızdan saklı
              tutulur.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.6.Kazanç ve kayıplarınızı yerel vergi idaresi ve yetkili
              makamlara bildirmek sizin sorumluluğunuzdadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.7.Hesabınızdan istediğiniz zaman şu şartları sağlayarak para
              çekebilirsiniz:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.7.1.hesabınıza yapılan tüm para yatırma işlemleri temiz olmalı
              ve herhangi bir geri ödeme talebi veya itiraz bulunmamalıdır, aksi
              halde ödeme talebi iptal edilir;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.7.2.7.5&nbsp;maddesinde belirtilen tüm kontroller tamamlanmış
              olmalıdır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.8.Para çekme talebiniz tarafımızdan onaylandığında para
              transferi ile ilgili gerekli tüm bilgileri sağlamalısınız.
              Seçtiğiniz ödeme sistemi ve para birimi ile para transferi
              talebiniz gerçekleştirilmeye çalışılır, ancak garanti edilemez.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.9.Oyuna sürülmemiş parayı çekmek için kendi maliyetleri
              (mevduatın maliyeti dahil) tutarında bir ücret talep etme hakkını
              tarafımızca saklı tutulur.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              7.10.&nbsp;Çekim işlemlerinde yatırmış olduğunuz ana paranız kadar
              oyun yaparak çekim işlemi yapabilirsiniz. Yatırmış olduğunuz
              miktarın 1 katını spor veya casino oyunlarında kullanarak çekim
              işlemlerinizi gerçekleştire bilirsiniz. (Hesabında bonus bakiyesi
              olan kullanıcılarımız için aktif bonus kuralları geçerlidir.)
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>BAHİS/OYUN OYNAMA</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              8.1.Tüm işlemler işleme başlanılan dil ile devam eder.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              8.2.İşlem detaylarının doğruluğundan emin olmak sizin
              sorumluluğunuzdadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              8.3.İşlem geçmişinize web sitesi üzerinden erişebilirsiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              8.4.Yapılan işlemlerin tamamen veya kısmen herhangi bir zamanda
              iptal edilmesi hakları tarafımızda saklı tutulur. İşlemler
              tarafımızdan onaylanmadığı sürece geçerli sayılmazlar. İşlemin
              onaylandığına dair onay e-postası almadıysanız destek birimine
              başvurun.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              8.5.Bir bahis onaylandıktan sonra yazılı iznimiz olmadan işlem
              iptal edilemez.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              GİZLİ ANLAŞMA, HİLE, DOLANDIRICILIK VE YASA DIŞI FAALİYETLER
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              9.1.Aşağıdaki faaliyetlere izin verilmez ve yapılması halinde
              kurallar ihlal edilmiş olur:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              •Üçüncü şahıslarla gizli anlaşmalar yapmak;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              •Adaletsizlik yaratacak bir üstünlük veya etki kullanmak (genel
              tabiriyle hile yapmak), olası bir hatanın istismarı, yazılım açığı
              veya hatalarını kullanmak, otomatik oyuncular kullanmak (‘bots’
              olarak da adlandırılır);
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              •Dolandırıcılık yöntemi ile ele geçirilmiş, çalınmış, kopyalanmış
              kredi veya debit (atm kartı) kartlarını kullanmak;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              •Kara para aklama veya benzeri suçların içerisinde yer almak veya
              bir parçası olmak;
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              9.2.Bu faaliyetlerin önlenmesi, tespiti ve ilişkili oyuncuların
              tespiti için gerekli adımlar tarafımızdan atılmaktadır. Sizin veya
              başka bir oyuncunun gizli anlaşma, hile, dolandırıcılık ve yasa
              dışı faaliyetlerden uğrayacağı zarar ve kayıplardan şirketimiz
              sorumlu değildir, uygulanacak işlemler şirketimizin takdirindedir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              9.3.Eğer bir kişinin gizli anlaşma, hile, dolandırıcılık ve yasa
              dışı faaliyetler yaptığını düşünüyorsanız e-posta aracılığı ile
              veya Canlı destek hattı üzerinden bize bildirmelisiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              9.4.Herhangi bir şüpheli yasadışı veya uygunsuz faaliyet sonucunda
              kimlik bilgilerinizi yetkili makamlara, diğer bahis siteleri ve
              operatörlerine, online servis sağlayıcısı ve bankalara, kredi
              kartı firmaları, elektronik ödeme sistem sağlayıcıları ve diğer
              finansal kuruluşlarla paylaşma hakkı şirketimizce saklı tutulur ve
              bu tür faaliyetlerin araştırılmasında işbirliği içinde olacağınızı
              kabul etmiş sayılırsınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              DİĞER YASAKLI FAALİYETLER
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.1.Web sitesi hizmetlerini ve sohbet fonksiyonunu kullanırken
              hakaret edici, müstehcen, ırkçı, ayrımcı ya da saldırgan olarak
              kabul edilen herhangi bir amaç için kullanmamalısınız. Rahatsız
              edici ve saldırgan bir dil veya resmi kullanmamalı, diğer
              kullanıcıları ve site çalışanlarını rahatsız edici davranışlarda
              bulunmamalısınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.2.Websitesinin çalışmasını engelleyecek girişimlerde
              bulunmamalı, web sitesinin hizmetlerini etkileyecek virüs, solucan
              ve benzeri yöntemler kullanmamalısınız. Herhangi bir toplu
              gönderim veya spam göndermek kesinlikle yasaktır. Web sitesinde
              yer alan herhangi bir formda herhangi bir bilgiyi değiştirmeye
              veya müdahale etmeye çalışmamalısınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.3.Web sitesi sadece eğlence amaçlı kullanılmalıdır ve sitenin
              tamamını ya da herhangi bir kısmını izin almadan kopyalanması ve
              kullanılması yasaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.4.Web sitesine, Web sitesinin bulunduğu sunuculara, bilgisayar
              ve veri tabanlarına yetkiniz dışında erişmeye çalışmamalısınız.
              DDOS (denial of service) veya benzeri sistemlerle Web sitesine
              saldırılarda bulunmamalısınız. Bu tür girişimlerde bulunmanız
              halinde ilgili yetkili makamlarla iş birliği yapılacak ve kimlik
              bilgileriniz paylaşılacaktır. Böyle bir girişim sonunda web sitesi
              kullanma hakkınız derhal sona erer.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.5.Web sitesini kullanırken, web sitesinden dosya indirirken
              virüs, DDOS saldırısı veya çeşitli zararlı teknolojililer ile
              bilgisayarınızda, bilgisayar programlarınızda, belgelerinizde
              meydana gelebilecek kayıp ve hasarlardan şirketimiz sorumlu
              değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              10.6.Hesap satmak veya başka bir oyuncuya devretmek, kasten
              kaybederek fişleri diğer oyuncuya aktarmak yasaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              HESAP KAPATMA VE FESHETME
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              11.1.Eğer hesabınızda doksan (90) gün işlem yapılmamışsa (inaktif
              hesap) hesabınızdaki para geri ödenir ve hesabınız kapatılır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              11.2.İnaktif olan hesabınız kapatılırken iletişim bilgilerindeki
              bilgileriniz kullanılarak size bilgi verilir. Hesap kapatma işlemi
              9.madde (gizli anlaşma, hile, dolandırıcılık ve yasa dışı
              faaliyetler) veya 16.madde (Kullanım koşulları ihlali) dahilinde
              değilse hesap bakiyeniz geri ödenir. Size ulaşılamaması halinde
              yetkili bahis kurumlarına devredilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>WEB SİTESİ GÜNCELLEMLERİ</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              12.1.Web sitesinde yer alan herhangi bir hizmetin herhangi bir
              zamanda değiştirilmesi veya güncellenmesi şirketimizin mutlak
              takdir yetkisi altındadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              BİLGİ TEKNOLOJİLERİ HATALARI
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              13.1.Web sitesini sağlayan sistemde herhangi bir yazılım veya
              donanım hatası veya arızası olması durumunda sorunun giderilmesi
              için tarafımızdan kısa sürede müdahale edilir. Kullandığınız
              donanımda veya yazılımda oluşan hatalar veya Internet servis
              sağlayıcınızdan dolayı oluşan erişim hatalarından şirketimiz
              sorumlu değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>HATALAR VE İHMALLER</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              14.1.&nbsp;Çeşitli sebeplerden dolayı tarafımızdan kaynaklı ve
              hatalı şekilde bir bahis işlemi kabul edilmiş ya da ödeme yapılmış
              olabilir. Hata durumunda bahsin oynandığı zamanda olması gereken
              değerde ve kurallara uygun olarak düzeltilmesi ve bahsin uygun
              kurallar ve değerlere göre değiştirilmesi hakkı tarafımızca saklı
              tutulur. Biz (çalışanlarımız ve temsilcilerimiz dahil olmak
              üzere), iş ortakları ve servis sağlayıcılarından hiç biri bizim
              veya sizin tarafınızdan yapılan hatadan kaynaklanan kayıplar ve
              kazanç kayıplarından sorumlu değildir. Bu tür hata sonucu ortaya
              çıkan kazanç/kayıpları kaybetmiş olursunuz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>SORUMLULUKLAR</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              15.1.Web sitesine ulaşmanız ve hizmetleri kullanmanız tamamen
              sizin seçiminiz ve takdirinizdir ve tüm riskler size aittir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              15.2.Kullanımı kolay ve kurallarda tanımlandığı üzere çalışan bir
              web sitesi sunulmaktadır. Web sitemiz ve web sitemizde sunulan
              ürünlerin dışında herhangi bir hizmet/ürün için garanti verilemez
              ya da vaat edilemez. Bu vesile ile benzeri ürünlerle ilgili olarak
              uygulanan garantiler yasaların izin verdiği ölçüde bizim
              garantimiz dışındadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              15.3.İş kaybı, veri kaybı, kar, gelir, fırsat, prestij, tanınırlık
              ve iş kesintisi ve öngörülemeyen kayıplardan şirketimiz sorumlu
              değildir ve sözleşme kapsamı dışında tutulmuştur.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              KULLANIM KOŞULLARI İHLALİ
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.1.Kuralların ihlal edilmesi sonucu ortaya çıkacak herhangi
              sorumluluk, maliyet, harcama (yasal masraflar dahil) ve olası
              masrafları karşılamak sizin sorumluluğunuzdadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.2.Kulanım koşullarını ihlal ettiğinizde, haklar saklı tutulur
              ancak:
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.2.1.Yaptığınız faaliyetin kurallara aykırı olduğu ve
              durdurmanız gerektiği size iletişim bilgileriniz kullanılarak
              bildirilir,
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.2.2.Hesabınız dondurulur ve ilgili birim tarafından bahis ve
              oyun oynamanız engellenir,
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.2.3.Size ön bilgi verilerek ilgili birim tarafından hesabınız
              kapatılır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.2.4.Kural ihlalinden etkilenen para çekme, kazanç ve bonuslar
              hesaptan geri alınır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              16.3.Kurallara uyulmaması halinde kullanıcı kodu ve şifresi devre
              dışı bırakılması şirket insifiyatifindedir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>FİKRİ MÜLKİYET HAKLARI</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              17.1.Tüm web sitesi tasarımı, yazılar, grafikler, müzik, ses,
              fotoğraf, video, bunların seçimi ve düzenlenmesi, yazılım
              derlemeleri, kaynak kodu, yazılım ve tüm materyaller telif hakları
              bize ait veya üçüncü taraf hak sahiplerinin lisansı altında
              kullanılan diğer mülkiyet haklarına tabidir. Web sitesinde bulunan
              materyaller, yalnızca tek bir kişisel bilgisayara kişisel kullanım
              için indirilebilir ve ticari amaçla kullanılamaz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              17.2.Hiçbir suretle bize veya üçüncü taraf hak sahiplerine ait
              hakların herhangi bir kullanıcıya devredilmesi söz konusu
              değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              17.3.Kurallara bağlı olarak izin verilmediği sürece Web sitesinde
              görülen ticari marka, ticari isim ve logoların kullanımı ve
              yeniden üretilmesi yasaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>KİŞİSEL BİLGİLER</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              18.1.Websitemiz tarafından alınan kişisel bilgiler, data güvenlik
              yasaları gereğince korunmaktadır. Sitemiz kişisel bilgilerin
              kullanımı konusundaki yükümlülüklerini titizlikle yerine
              getirmektedir ve asla 3. kişiler ile bu bilgiler
              paylaşılmamaktadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              18.2.Sitemize verdiğiniz kişisel bilgilerin, site koşulları
              gereğince web sitemiz tarafından kullanımını ve yasal ve
              düzenleyici zorunluluklar gereği kullanımı kabul etmiş
              sayılırsınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              18.3.Şirket politikası gereği hiçbir kişisel bilgi, hizmet
              vermekle yükümlü olarak bilgiye erişim hakkı olan firma
              çalışanlarının dışında, üçüncü şahıslarla paylaşılmamaktadır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              18.4.Sizden aldığımız bilgilerin doğru bir şekilde kaydını
              sağlamak için bize göndermiş olduğunuz her türlü iletişim
              bilgisinin kopyası (e-maillerin kopyası da dahil olmak üzere)
              tarafımızdan saklı tutulacaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>ÇEREZLERİN KULLANIMI</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              19.1.Web sitesinin etkin kullanımı için ‘çerezler’ den
              faydalanılır. Çerezler bilgisayarınıza indirilen küçük text
              dosyalarıdır ve siteye tekrar geldiğinizde hatırlanmanızı sağlar.
              Çerezlerin nasıl kontrol edileceği ve silineceği bilgileri{" "}
              <a
                href="http://www.aboutcookies.org/"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                www.aboutcookies.org
              </a>{" "}
              adresinden öğrenilebilir. Çerezlerin silinmesi ve devre dışı
              bırakılmasıyla web sitesinin bazı özelliklerine ulaşamayabilir
              veya kullanamayabilirsiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              ŞİKAYETLER VE BİLDİRİMLER
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              20.1.Web sitesi hakkında herhangi bir şikayette bulunmak için
              öncelikle müşteri ilişkileri birimiyle temasa geçmelisiniz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              20.2.Herhangi bir anlaşmazlık durumunda, sunucu kayıtlarının
              herhangi bir iddianın sonucu belirlemede nihai otorite olarak
              tanınacağını kabul etmiş sayılırsınız.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              20.3.Kullanıcı ekranında görünen sonuç ve oyun sunucusundaki sonuç
              arasında herhangi bir farklılık olması durumunda oyun
              sunucusundaki veriler geçerli sayılır, online oyunlara katılımınız
              sırasında kuralları ve koşulları belirleyen nihai otoritenin
              tarafımız olduğunu kabul etmiş sayılırsınız. “Geçen Ellerin Özeti”
              ve “Oyun Sohbet” özelliği herhangi bir oyun ya da hizmet için
              kesin kayıt olarak değerlendirilmez.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              20.4.Herhangi bir itilaf durumunda iletişim bilgileriniz
              kullanılarak sizinle iletişime geçilecektir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>TERCÜME</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              21.1.Kuralların yazıldığı dil Türkçe'dir&nbsp; ve yapılan tüm
              tercümeler Türkçe orijinal metin üzerinden çevrilmiştir.
              Kuralların veya herhangi bir belge ya da bildirimlerin başka bir
              dile çevrilmesi durumunda, orijinal Türkçe versiyonu geçerli
              olacaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              HAKLARIN VE YÜKÜMLÜLÜKLERİN DEVRİ
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              22.1.Kuralların aynen ya da size daha az avantaj sağlamayacak
              şekilde başka bir kişiye devredilmesi, atanması, alt lisans ya da
              rehin verilmesi hakları tarafımızca saklı tutulur.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              KONTROLÜMÜZ DIŞINDAKİ OLAYLAR
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              23.1.&nbsp;Savaş, toplumsal ayaklanma, network hizmetlerinde
              kesinti, sanayi kaynaklı sıkıntılar ve DDOS saldırıları ve benzeri
              zorlayıcı nedenler gereği web sitemizin kontrolü dışında
              gerçekleşen olaylardan dolayı yaşanan hizmet verememe ya da hizmet
              gecikmesi gibi kullanım kuralları zorunluluklarının yerine
              getirilmemesi durumlarından şirketimiz sorumlu tutulamaz. Bu
              zorlayıcı nedenlerin devamı süresince hizmetlerimiz askıya alınmış
              sayılır ve hizmet verme süremiz, zorlayıcı nedenlerin devamı
              süresince uzatılmış sayılır. Sitemiz, bu zorlayıcı nedenleri
              sonlandırmak ya da zorlayıcı nedenlere rağmen mümkün olduğunca
              zorunluluklarını yerine getirmek için çözüm bulmak için mümkün
              olan gayreti gösterecektir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>FERAGAT</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              24.1.Sitemizin, kullanıcı olarak sizin yerine getirmekle yükümlü
              olduğunuz zorunlulukları ihmal etmeniz durumunda uyarıda
              bulunmaması ya da sitemizin yetkili olduğu hakları ve
              düzenlemeleri uygulamada oluşacak aksaklıklar, sizin bu hak ve
              zorunluklardan feragatinizi sağlamaz ve bu zorunluluklara olan
              yükümlülüğünüzü ortadan kaldırmaz.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              24.2.Tarafımızdan kaynaklı yükümlülüklerin yerine
              getirilememesinden doğacak fegarat, buna bağlı oluşacak başka bir
              feragatı teşkil etmez. Açıkça belirtilmediği ve size yazılı olarak
              bildirilmediği sürece, kullanım koşullarından kaynaklı hiçbir
              feragat söz konusu değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>BÖLÜNEBİLİRLİK</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              25.1.Kurallardan herhangi birinin geçersiz, yasadışı veya
              uygulanamaz olduğuna karar verilirse bu kurallar diğer kurallardan
              ayrılır, diğer kurallar kanunların izin verdiği ölçüde geçerli
              kalır. Bu durumlarda, geçersiz veya uygulanamaz olan kısımlar
              yasalara uygun ve orijinal metinde verilen anlama en yakın olacak
              şekilde tadil edilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>LİNKLER</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              27.1.Sitemizde link verilen diğer sitelerin tamamı bilgi
              amaçlıdır. Bu linklerin kullanımı sizin sorumluluğunuzdadır ve bu
              sitelerin kullanımından veya içeriğinden ortaya çıkacak sorunlar
              tarafımızın sorumluluğunda değildir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>28. CANLI BAHİS</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              28.1.&nbsp;Olipsbet, canlı bahisler için sağlanan canlı skorların,
              istatistiklerin veya diğer bilgi/verilerin kesinlikle doğru
              olduğuna dair sorumluluk kabul etmez.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              28.2.&nbsp;Bahisler kabul süresi aralığında "bekleme" modunda
              tutulacak ve sonrasında kabul edilecek veya geçersiz olacaktır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              28.3.&nbsp;“Bekleme” modunda olan bahisler o bahis için pazarın
              kabul süresine kadar önemli ölçüde değiştiğine karar verilirse
              iptal edilebilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              28.4.&nbsp;Olipsbet herhangi bir pazarda bahisleri haber vermeden
              durdurma hakkını saklı tutar.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              28.5.&nbsp;Olipsbet canlı bir olayın sonucu bilindikten sonra bir
              canlı bahis girildiğini inanmak için nedenleri varsa veya herhangi
              bir teknik arıza canlı bahis seçilen katılımcı bir avantaj elde
              ettikten sonra yanlış oranda girildiyse şirketin herhangi bir
              neden göstermeden bahsi geçersiz kılma hakkı vardır.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>29.&nbsp;BONUSLAR</span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.1.&nbsp;Bonuslar kullanıcılarımız için oluşturulmuş
              promosyonlardır. Bonuslarımızdan sadece tekil IP'ye sahip
              kullanıcılar yararlanabilir. Bu nedenle internet cafe, lojman,
              cafe, işyeri, yurt gibi paylaşılan ağlardan sitemize giriş
              yapıyorsanız bonus almayınız. Aksi halde hiçbir sorumluluk kabul
              edilmeyecektir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.2.&nbsp;Aynı IP adresinden birden fazla üyelik olduğunda tüm
              bonus ve kazançlarınız İPTAL edilir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.3.&nbsp;Bir para yatırımı için sadece bir bonusdan faydalanıla
              bilinir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.4. Alınan promosyon suistimal edilmesi dahilinde ilgili birim
              hesabınızı kapatmaya bakiyenize müdahale etme hakkını saklı tutar.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.5.Aksi belirtilmediği sürece her yatırım için sunulan
              promosyonların birinden yararlanılabilir. Promosyonlardan
              yararlanan üyelerimizin de para çekim işlemi gerçekleştirebilmesi
              için, yararlanmakta olduğu bonus kurallarında da belirtilen ana
              para çevrimini tamamlaması gerekmektedir.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontSize: "12pt" }}>
            <span style={{ fontSize: "11.0pt" }}>
              29.6.E-posta, IP adresi, telefon numarası, adres bilgisi gibi
              kişisel bilgilere ait birden fazla üyelik bulunması halinde,
              üyeliklerden sadece bir tanesi promosyonlardan faydalanma hakkına
              sahiptir. Ayrıca aynı ailenin fertlerinden yalnızca bir tanesi
              promosyon kullanım hakkı elde edebilir. Olipsbet, gerek gördüğü
              takdirde belge isteme hakkına sahip olmakla birlikte bu belgelerin
              üye tarafından ibraz edilmemesi durumunda bonus ve finansal işlem
              taleplerini iptal etme hakkını saklı tutar. Olipsbet,
              promosyonları suistimal ederek haksız kazanç elde etmeye çalışan
              veya Genel Kural ve Şartları ihlal eden kullanıcıların hesaplarını
              askıya alma ve kapatma hakkını saklı tutar.
            </span>
          </span>
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export { GeneralTerms };
