import { t } from "i18next";
import React from "react";

import "./UpcomingMatches.scss";

const UpcomingMatches = () => {
  return (
    <div className="hm-row-bc ">
      <div className="popular-matches-bc upcoming-games">
        <div className="page-view-more">
          <h3 className="view-title-more ellipsis">
            {t("admin.UpcomingMatches")}
          </h3>
          <a className="view-see-more">
            {t("navHeader.More")}
            <i className="view-arrow-more bc-i-small-arrow-right"></i>
          </a>
        </div>
        <div className="upcoming-games-container">
          <div className="time-filters-bc">
            <ul className="time-filters-content">
              <li
                className="time-filter-button-bc btn s-small a-outline"
                style={{ flexGrow: 1 }}
              >
                0-15m
              </li>
              <li
                className="time-filter-button-bc btn s-small a-outline"
                style={{ flexGrow: 1 }}
              >
                15-30m
              </li>
              <li
                className="time-filter-button-bc btn s-small a-outline a-white"
                style={{ flexGrow: 1 }}
              >
                30-60m
              </li>
            </ul>
          </div>
          <div className="horizontal-sl-list    ">
            <div
              className="horizontal-sl-wheel"
              style={{ transform: "translateX(0px)" }}
            ></div>
          </div>
        </div>
        <p className="empty-b-text-v-bc">
          {t("admin.Noupcomingmatchesatthemoment")}
        </p>
      </div>
    </div>
  );
};

export { UpcomingMatches };
