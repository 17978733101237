import React from "react";
import "./Accordion.scss";
import { AccordionItem } from "./AccrodionUi/AccordionItem";

interface IAccordion {
  providersList: any;
  selectedProviders: number[];
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
}

const Accordion = ({
  providersList,
  selectedProviders,
  setSelectedProviders,
}: IAccordion) => {
  return (
    <div className="accordion-items-container collapsed">
      <div className="accordion-items">
        <div className="accordion-items-resize">
          {Object.keys(providersList).map((item) => {
            return providersList[item].map((provider: any) => {
              return (
                <AccordionItem
                  accordionContent={provider}
                  key={provider.id}
                  selectedProviders={selectedProviders}
                  setSelectedProviders={setSelectedProviders}
                />
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export { Accordion };
