import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CasinoGameType } from "../common/types/Casino/CasinoGameType";
import { CasinoInfo, AdminPanel } from "../services";
import { setUserBalance } from "../store/action/auth/setuserbalance";

interface IusePlayersWindows {
  role: string;
  openGamesUrl: string;
  setOpenGames: React.Dispatch<React.SetStateAction<string[]>>;
  gameNamesUrl: string;
  setGameNames: React.Dispatch<React.SetStateAction<string[]>>;
  openGames: string[];
  gameNames: string[];
  language: string;
  casinoLink: string[];
  gamesSelected: CasinoGameType[];
  setGamesSelected: React.Dispatch<React.SetStateAction<CasinoGameType[]>>;
  setCasinoBanner: React.Dispatch<React.SetStateAction<string>>;
  setCasinoLink: React.Dispatch<React.SetStateAction<string[]>>;
}

const usePlayersWindows = ({
  role,
  openGamesUrl,
  setOpenGames,
  gameNamesUrl,
  setGameNames,
  openGames,
  gameNames,
  language,
  casinoLink,
  gamesSelected,
  setGamesSelected,
  setCasinoBanner,
  setCasinoLink,
}: IusePlayersWindows) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (openGamesUrl) {
      const openGamesArray = openGamesUrl.split(",");
      setOpenGames(openGamesArray);
    }

    if (gameNamesUrl) {
      const gameNamesArray = gameNamesUrl.split(",");
      setGameNames(gameNamesArray);
    }
  }, [openGamesUrl, gameNamesUrl]);

  useEffect(() => {
    async function searchPlaySecond() {
      try {
        const res = await CasinoInfo.casinoPlay(
          `/${openGames[1]}/${gameNames[1]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`
        );
        setGamesSelected([gamesSelected[0], res.data]);
        setCasinoLink([casinoLink[0], res.data.game_link]);
      } catch {
        console.error("");
      }
    }
    async function searchPlay() {
      try {
        const res = await CasinoInfo.casinoPlay(
          `/${openGames[0]}/${gameNames[0]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`
        );
        setGamesSelected([res.data, gamesSelected[1]]);
        setCasinoBanner(res.data.background_banner);
        setCasinoLink([res.data.game_link, casinoLink[1]]);
      } catch {
        console.error("");
      }
    }

    searchPlay();
    if (openGames[1]) {
      searchPlaySecond();
    }
  }, [openGames, gameNames]);

  useEffect(() => {
    async function userInfo() {
      const res = await AdminPanel.userBalance();
      dispatch(setUserBalance(res.data));
    }
    const interval = setInterval(() => {
      if (role === "player") {
        userInfo();
      }
    }, 15000);

    return () => clearInterval(interval);
  }, []);
};

export { usePlayersWindows };
