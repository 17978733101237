import React, { useState } from "react";
import "./AccordionProviders.scss";

interface IAccordionProviders {
  providersList: any;
  selectedProviders: any;
  setSelectedProviders: any;
  filteredData: any;
  searchInput: string;
}

const AccordionProviders = ({
  providersList,
  selectedProviders,
  setSelectedProviders,
  filteredData,
  searchInput,
}: IAccordionProviders) => {
  const [accordingOpen, setAccordingOpen] = useState(false);

  const handleOpenAccardion = () => {
    setAccordingOpen((prev) => !prev);
  };

  const handleSelectItem = (item: string) => {
    setSelectedProviders((prevState: any) => {
      if (prevState.includes(item)) {
        return prevState.filter((existingId: any) => existingId !== item);
      } else {
        return [...prevState, item];
      }
    });
  };
  return (
    <div
      className={`accordion-items-container ${
        accordingOpen ? "" : "collapsed"
      } `}
    >
      <div className="accordion-items">
        <div className="accordion-items-resize">
          {searchInput === ""
            ? Object.keys(providersList).map((item: any) => {
                return providersList[item].map((game: any) => {
                  return (
                    <span
                      title={game.name}
                      key={game.id}
                      onClick={() => handleSelectItem(game.id)}
                    >
                      <button
                        className={`btn s-big accordion-button ${
                          selectedProviders.includes(game.id) ? "a-outline" : ""
                        } `}
                        type="button"
                      >
                        {game.name}
                      </button>
                    </span>
                  );
                });
              })
            : Object.keys(filteredData).map((item: any) => {
                return filteredData[item].map((game: any) => {
                  return (
                    <span
                      title={game.name}
                      key={game.id}
                      onClick={() => handleSelectItem(game.id)}
                    >
                      <button
                        className={`btn s-big accordion-button ${
                          selectedProviders.includes(game.id) ? "a-outline" : ""
                        } `}
                        type="button"
                      >
                        {game.name}
                      </button>
                    </span>
                  );
                });
              })}
        </div>
      </div>
      <i
        className={`accordion-items-container-arrow bc-i-small-arrow-${
          accordingOpen ? "up" : "down"
        }`}
        onClick={handleOpenAccardion}
      />
    </div>
  );
};

export { AccordionProviders };
