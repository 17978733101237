import React from "react";
import "./ReceivedRange.scss";

const ReceivedRange = () => {
  return (
    <div className="u-i-p-control-item-holder-bc ">
      <div className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <div className="form-control-bc default filled ">
            <label className="form-control-label-bc inputs">
              <input
                className="form-control-input-bc "
                type="text"
                placeholder="From"
                readOnly={false}
                defaultValue=""
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc ellipsis">Range</span>
              <i className="dropdownIcon-bc bc-i-datepicker" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReceivedRange };
