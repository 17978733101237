import { t } from "i18next";
import React, { useState } from "react";
import { ReceivedPeriod } from "./components/ReceivedPeriod/ReceivedPeriod";
import { ReceivedRange } from "./components/ReceivedRange/ReceivedRange";
import "./ReceivedGifts.scss";

const ReceivedGifts = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("custom");
  return (
    <>
      <div className="overlay-header">{t("admin.Receivedgifts")}</div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content"
        data-scroll-lock-scrollable=""
      >
        <div className="componentFilterWrapper-bc">
          <div className="componentFilterBody-bc">
            <div className="componentFilterElsWrapper-bc">
              <form className="filter-form-w-bc">
                <div className="componentFilterBody-content">
                  <ReceivedPeriod
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                  {selectedPeriod === "custom" ? <ReceivedRange /> : null}
                </div>
                <div className="u-i-p-c-filter-footer-bc">
                  <button
                    className="btn a-color "
                    type="submit"
                    title="Show"
                    disabled={true}
                  >
                    <span>{t("admin.Show")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <p className="empty-b-text-v-bc">
          {t("admin.Nobetstoshow")}
        </p>
      </div>
    </>
  );
};

export { ReceivedGifts };
