import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { userFormType } from "../../../ModalRegister";
import "./InputPromocode.scss";

interface IInputPromocode {
  register: UseFormRegister<userFormType>;
  watch: UseFormWatch<userFormType>;
}

const InputPromocode = ({ register, watch }: IInputPromocode) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const name = watch("promocode");

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    if (inputValue.length === 0) {
      setInputFocus(false);
    }
  };

  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default  ${inputFocus ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            className="form-control-input-bc"
            {...register("promocode")}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Promocode")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { InputPromocode };
