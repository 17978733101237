import { t } from "i18next";
import React, { useState } from "react";
import "./BuddyTransfer.scss";
import { AmountSelect } from "./BuddyTransferUi/amountSelect/AmountSelect";
import { SelectUser } from "./BuddyTransferUi/selectUser/SelectUser";
import { TransferButton } from "./BuddyTransferUi/transferButton/TransferButton";
import { TransferSelect } from "./BuddyTransferUi/transferSelect/TransferSelect";
import { TransferTrack } from "./BuddyTransferUi/transferTrack/TransferTrack";
import { ValidationInfo } from "./BuddyTransferUi/validationInfo/ValidationInfo";

export interface ImockSelectUserList {
  name: string;
  fullName: string;
  inputFriend: string | null;
  info: string | null;
  inputPlaceholder: string;
}

const mockSelectUserList: ImockSelectUserList[] = [
  {
    name: "username",
    fullName: "Username",
    inputFriend: null,
    info: "You haven`t friends",
    inputPlaceholder: "Amount",
  },
  {
    name: "friendName",
    fullName: "Friend Name",
    inputFriend: "Friend Name",
    info: null,
    inputPlaceholder: "Amount",
  },
  {
    name: "userId",
    fullName: "user id",
    inputFriend: "user id",
    info: null,
    inputPlaceholder: "Amount",
  },
];

const BuddyTransfer = () => {
  const [selectedRadioUser, setSelectedRadioUser] = useState(
    mockSelectUserList[0]
  );
  return (
    <>
      <div className="overlay-header">{t("admin.BuddyTransfer")}</div>
      <div className="u-i-e-p-p-content-bc u-i-common-content">
        <div className="buddy-transfer">
          <form>
            <ValidationInfo>
              {t("admin.Pleaseselecttheoptionyouwanttomaketransferwith")}
            </ValidationInfo>
            <SelectUser
              mockSelectUserList={mockSelectUserList}
              selectedRadioUser={selectedRadioUser}
              setSelectedRadioUser={setSelectedRadioUser}
            />
            <TransferSelect selectedRadioUser={selectedRadioUser} />
            <TransferTrack />
            <AmountSelect selectedRadioUser={selectedRadioUser} />
            <TransferButton />
          </form>
        </div>
      </div>
    </>
  );
};

export { BuddyTransfer };
