import React from "react";
import "./PromotionSelect.scss";

interface IPromotionSelect {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const PromotionSelect = ({ selected, setSelected }: IPromotionSelect) => {
  const handleChangeSelect = (selectItem: string) => {
    setSelected(selectItem);
  };
  return (
    <div className="horizontal-sl-list promotion-horizontal-sl-list-bc horizontal-items-expanded  ">
      <div
        className="horizontal-sl-wheel"
        style={{ transform: "translateX(0px)" }}
      >
        <div
          className={`horizontal-sl-item-bc all ${
            selected === "all" ? "active" : ""
          }`}
          data-id="all"
          title="Hepsi"
          onClick={() => handleChangeSelect("all")}
        >
          <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-all" />
          <p className="horizontal-sl-title-bc">Hepsi</p>
        </div>
        <div
          className={`horizontal-sl-item-bc sport ${
            selected === "sport" ? "active" : ""
          }`}
          data-id="sport"
          title="Spor Bonusları"
          onClick={() => handleChangeSelect("sport")}
        >
          <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-sport" />
          <p className="horizontal-sl-title-bc">Spor Bonusları</p>
        </div>
        <div
          className={`horizontal-sl-item-bc slots ${
            selected === "slots" ? "active" : ""
          }`}
          data-id="slots"
          title="Casino Bonusları"
          onClick={() => handleChangeSelect("slots")}
        >
          <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-slots" />
          <p className="horizontal-sl-title-bc">Casino Bonusları</p>
        </div>
        <div
          className={`horizontal-sl-item-bc belote ${
            selected === "belote" ? "active" : ""
          }`}
          data-id="belote"
          title="Özel Bonuslar"
          onClick={() => handleChangeSelect("belote")}
        >
          <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-belote" />
          <p className="horizontal-sl-title-bc">Özel Bonuslar</p>
        </div>
        <div
          className={`horizontal-sl-item-bc games ${
            selected === "games" ? "active" : ""
          }`}
          data-id="games"
          title="Turnuvalar"
          onClick={() => handleChangeSelect("games")}
        >
          <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-games" />
          <p className="horizontal-sl-title-bc">Turnuvalar</p>
        </div>
      </div>
    </div>
  );
};

export { PromotionSelect };
