import React, { useEffect, useState } from "react";
import "./CasinoFreeSpins.scss";
import loader from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const CasinoFreeSpins = () => {
  const [load, setLoad] = useState(false);
  const [freeSpinType, setFreeSpinType] = useState("new");
  const handleFreeSpintChange = (item: string) => {
    setFreeSpinType(item);
  };
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  }, [freeSpinType]);
  return (
    <>
      <div className="overlay-header">Casino Free Spins</div>
      <div className="sportBonusWrapper-bc" data-scroll-lock-scrollable="">
        <div className="second-tabs-bc">
          <div
            className={`tab-bc selected-underline ${
              freeSpinType === "new" ? "active" : ""
            } `}
            title=""
            onClick={() => handleFreeSpintChange("new")}
          >
            {t("admin.New")}
          </div>
          <div
            className={`tab-bc selected-underline ${
              freeSpinType === "activated" ? "active" : ""
            } `}
            title=""
            onClick={() => handleFreeSpintChange("activated")}
          >
            {t("admin.Activated")}
          </div>
        </div>
        {load ? (
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loader} alt="" />
            </div>
          </div>
        ) : (
          <p className="empty-b-text-v-bc">
            {t("admin.Therearenobonusesforselectedtype")}
          </p>
        )}
      </div>
    </>
  );
};

export { CasinoFreeSpins };
