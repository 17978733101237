import React from "react";
import { LastWinnerType } from "../../../../../../../common/types/Casino/LastWinnerType";
import { winners } from "../../WinnerBlock";
import "./LastWiinerCart.scss";

interface ILastWiinerCart {
  winner: LastWinnerType;
}

const LastWiinerCart = ({ winner }: ILastWiinerCart) => {
  return (
    <li>
      {/* <img className="casino-info-img" src={winner.img} alt={winner.alt} /> */}
      <div className="casino-winners-info">
        <b>{winner.username}</b>
        <span className="ellipsis">{winner.game_name}</span>
      </div>
      <b className="casino-winners-amount">{winner.win} ₺</b>
    </li>
  );
};

export { LastWiinerCart };
