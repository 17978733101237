import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setTimeFormat } from "../../../../../../../../../../../store/action/settings/settimeformat";
import "./TimeFormatSelect.scss";

interface ITimeFormatSelect {
  timeFormat: string;
  setSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

const TimeFormatSelect = ({ timeFormat, setSettings }: ITimeFormatSelect) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const handleItemSelect = (item: string) => {
    dispatch(setTimeFormat(item));
    setSettings(false);
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };
  const handleClosePanel = () => {
    setModalOpen(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div
      className="form-control-bc select  filled"
      tabIndex={0}
      ref={wrapperRef}
    >
      <div
        className="form-control-label-bc form-control-select-bc inputs "
        onClick={handleOpenModal}
      >
        <span className="ellipsis">{timeFormat}</span>
        <i
          className={`form-control-icon-bc bc-i-small-arrow-${
            modalOpen ? "up" : "down"
          }`}
        />
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc ellipsis">
          {t("admin.Timeformat")}
        </span>
      </div>
      {modalOpen ? (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          <label
            className={`checkbox-control-content-bc ${
              timeFormat === "12h" ? "active" : ""
            } `}
            data-option-value="12h"
            onClick={() => handleItemSelect("12h")}
          >
            <p
              className="checkbox-control-text-bc"
              style={{ pointerEvents: "none" }}
            >
              12h
            </p>
          </label>
          <label
            className={`checkbox-control-content-bc ${
              timeFormat === "24h" ? "active" : ""
            } `}
            data-option-value="24h"
            onClick={() => handleItemSelect("24h")}
          >
            <p
              className="checkbox-control-text-bc"
              style={{ pointerEvents: "none" }}
            >
              24h
            </p>
          </label>
        </div>
      ) : null}
    </div>
  );
};

export { TimeFormatSelect };
