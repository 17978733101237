import React, { useRef, useState } from "react";
import { CasinoSelectFilter } from "./CasinoSelectFilter/CasinoSelectFilter";
import { CasinoSelectList } from "./CasinoSelectList/CasinoSelectList";

import "./CasinoSelectPopap.scss";

interface ICasinoSelectPopap {
  setGameSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CasinoSelectPopap = ({ setGameSelectOpen }: ICasinoSelectPopap) => {
  const handleCloseSelect = () => {
    setGameSelectOpen(false);
  };
  const [inputValue, setInputValue] = useState("");
  return (
    <div className="popup-holder-bc windowed   ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc castom-inner-bc">
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleCloseSelect}
          />
          <CasinoSelectFilter
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <CasinoSelectList
            setGameSelectOpen={setGameSelectOpen}
            inputValue={inputValue}
          />
        </div>
      </div>
    </div>
  );
};

export { CasinoSelectPopap };
