import React from "react";
import { Accordion } from "./components/Accordion/Accordion";
import { Games } from "./components/Games/Games";
import { Providaers } from "./components/Providaers/Providaers";
import "./LiveCasinoProviders.scss";

interface ILiveCasinoProviders {
  providersList: any;
  selectedProviders: number[];
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
  seacrhGames: string;
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>;
}

const LiveCasinoProviders = ({
  providersList,
  selectedProviders,
  setSelectedProviders,
  seacrhGames,
  setSeacrhGames,
}: ILiveCasinoProviders) => {
  return (
    <>
      <Providaers />
      <Accordion
        providersList={providersList}
        selectedProviders={selectedProviders}
        setSelectedProviders={setSelectedProviders}
      />
      <Games seacrhGames={seacrhGames} setSeacrhGames={setSeacrhGames} />
    </>
  );
};

export { LiveCasinoProviders };
