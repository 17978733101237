import React, { useState } from "react";
import { useTypeSelector } from "../../../../../../hooks/useTypeSelector";
import { PanelItemsPlayer } from "./components/panelItemsPlayer/PanelItemsPlayer";
import { PanelItemsUnauth } from "./components/panelItemsUnauth/PanelItemsUnauth";
import { SidePanelFavorits } from "./components/sidePaneles/SidePanelFavorits/SidePanelFavorits";
import { SidePanelNotification } from "./components/sidePaneles/SidePanelNotification/SidePanelNotification";
import { SidePanelSettings } from "./components/sidePaneles/SidePanelSettings/SidePanelSettings";
import "./SmartPanel.scss";

interface ISmartPanel {
  panelOnep: boolean;
  setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  betsLipOpen?: boolean;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SmartPanel = ({
  panelOnep,
  setPanelOpen,
  betsLipOpen,
  setBetsLipOpen,
}: ISmartPanel) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [favorits, setFavorits] = useState(false);
  const [settings, setSettings] = useState(false);
  const { role } = useTypeSelector((data) => data.accountInfoReducer);
  return (
    <div className="smartPanel-bc">
      <div className="hdr-smart-panel-holder-arrow-bc">
        {role === "unauth" ? (
          <PanelItemsUnauth
            setNotificationOpen={setNotificationOpen}
            setFavorits={setFavorits}
            setSettings={setSettings}
            betsLipOpen={betsLipOpen}
            setBetsLipOpen={setBetsLipOpen}
          />
        ) : (
          <PanelItemsPlayer
            setNotificationOpen={setNotificationOpen}
            setFavorits={setFavorits}
            setSettings={setSettings}
            betsLipOpen={betsLipOpen}
            setBetsLipOpen={setBetsLipOpen}
          />
        )}
      </div>
      <SidePanelNotification
        setNotificationOpen={setNotificationOpen}
        notificationOpen={notificationOpen}
      />
      <SidePanelFavorits favorits={favorits} setFavorits={setFavorits} />
      <SidePanelSettings settings={settings} setSettings={setSettings} />
      <i
        onClick={() => {
          setPanelOpen((prev) => !prev);
        }}
        className={`${
          panelOnep
            ? "  count-blink-even hdr-toggle-button-bc bc-i-vertical-toggle"
            : "  count-blink-odd hdr-toggle-button-bc bc-i-vertical-toggle"
        }`}
      ></i>
    </div>
  );
};

export { SmartPanel };
