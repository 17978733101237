import { t } from "i18next";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BetList } from "./components/betList/BetList";
import { SelectPeriod } from "./components/selectPeriod/SelectPeriod";
import "./index.scss";

const BetNuildManegmentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const [modalOpen, setModalOpen] = useState(false);
  const [periodSelected, setPeriodSelected] = useState('24/1')
  return (
    <>
      <div className="overlay-header">
        {page === "bets" ? t("admin.All") : ""}
        {page === "open-bets" ? t("admin.OpenBets") : ""}
        {page === "won" ? t("admin.Won") : ""}
        {page === "lost" ? t("admin.Lost") : ""}
      </div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content BetBuilder-history"
        data-scroll-lock-scrollable=""
      >
        <SelectPeriod
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          periodSelected={periodSelected}
          setPeriodSelected={setPeriodSelected}
        />
        <BetList />
      </div>
    </>
  );
};

export { BetNuildManegmentPage };
