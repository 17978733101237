import { t } from "i18next";
import React from "react";
import { useTypeSelector } from "../../../../../../../../../../hooks/useTypeSelector";
import { GameItem } from "../../../../../../../../../liveCasino/gamesList/components/GameItem/GameItem";

import "./FavoritGamesLocal.scss";

const FavoritGamesLocal = () => {
  const favoritCasinoLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  return (
    <>
      {favoritCasinoLocal.length > 0 ? (
        <div>
          <div className="bs-remove-all-bc bs-favorite-remove-bc">
            <div className="bs-favorite-remove">
              <span className="betslip-remove-all ellipsis">
                {t("admin.RemoveAll")}
              </span>
            </div>
          </div>
          <div className="casino-category-games">
            {favoritCasinoLocal.map((data) => {
              return <GameItem key={data.id} gameItem={data} />;
            })}
          </div>
        </div>
      ) : (
        <p className="empty-b-text-v-bc">{t("admin.donthavefavorit")}</p>
      )}
    </>
  );
};

export { FavoritGamesLocal };
