import React from "react";
import "./AccordionItem.scss";

interface IAccordionItem {
  accordionContent: any;
  selectedProviders: number[];
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
}

const AccordionItem = ({
  accordionContent,
  selectedProviders,
  setSelectedProviders,
}: IAccordionItem) => {
  const handleClick = () => {
    if (accordionContent.id === selectedProviders[0]) {
      setSelectedProviders([1]);
    } else {
      setSelectedProviders([accordionContent.id]);
    }
  };
  return (
    <span title={accordionContent.name} onClick={handleClick}>
      <button
        className={`btn s-big accordion-button ${
          selectedProviders[0] == accordionContent.id ? "a-outline" : ""
        } `}
        type="button"
        data-badge=""
      >
        {accordionContent.name}
      </button>
    </span>
  );
};

export { AccordionItem };
