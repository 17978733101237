import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";
import "./TournamentBlock.scss";
import { TournamentCart } from "./TournamentBlockUi/TournamentCart/TournamentCart";

export interface ImockCarts {
  img: string;
  alr: string;
  name: string;
  price: number;
}

const mockCarts: ImockCarts[] = [
  {
    img: "https://ca-ft.kgrrtqnnksnjhfuc.com/RGS/Tournament/7762/1/en/E98683C9.jpg",
    alr: "Pragmatic April Hunt",
    name: "Pragmatic April Hunt",
    price: 214628.39,
  },
  {
    img: "https://ca-ft.kgrrtqnnksnjhfuc.com/RGS/Tournament/7753/1/en/A90062E3.jpg",
    alr: "Pragmatic April Hunt",
    name: "Pragmatic April Hunt",
    price: 214628.39,
  },
  {
    img: "https://ca-ft.kgrrtqnnksnjhfuc.com/RGS/Tournament/7696/1/en/3A2B93EE.jpg",
    alr: "Feel The Adrenaline With Wazdan!",
    name: "Feel The Adrenaline With Wazdan!",
    price: 214628.39,
  },
];

const TournamentBlock = () => {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div className="casino-info-block">
      <div className="casino-tournament-header">
        <i className="casino-tournament-header-icon bc-i-tournament" />
        <span className="casino-tournament-header-title ellipsis">
          {t("casino.Tournaments")}
        </span>
      </div>
      <Link
        to={`/${language}/casino/tournaments`}
        className="casino-tournament-all"
      >
        <span className="casino-tournament-all">{t("admin.Viewall")}</span>
      </Link>
      <ul className="casino-info-content casino-info-content-tournament">
        {mockCarts.map((item) => (
          <TournamentCart key={item.img} mockCart={item} />
        ))}
      </ul>
    </div>
  );
};

export { TournamentBlock };
