import { t } from "i18next";
import React, { useState } from "react";
import { Layout } from "../../components/layout";
import { EntryTypes } from "../../components/sportTournaments/EntryTypes/EntryTypes";
import { RegistrationStatuses } from "../../components/sportTournaments/RegistrationStatuses/RegistrationStatuses";
import { StatusSelect } from "../../components/sportTournaments/StatusSelect/StatusSelect";
import { Filters } from "../../components/tournaments/filters/Filters";
import { TournamentList } from "../../components/tournaments/tournamentList/TournamentList";
import "./SportTournaments.scss";

const SportTournaments = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  return (
    <Layout
      addClasses={`page-full-size ${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } ${panelOnep ? "smart-panel-is-visible" : ""}`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus={"sport-tournaments"}
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <div className="casino-game-tournament-filter">
        <div className="componentFilterWrapper-bc">
          <div className="componentFilterBody-bc">
            <div className="componentFilterElsWrapper-bc">
              <form className="filter-form-w-bc">
                <div className="componentFilterBody-content">
                  <StatusSelect />
                  <RegistrationStatuses />
                  <EntryTypes />
                </div>
                <div className="u-i-p-c-filter-footer-bc">
                  <button className="btn a-color " type="submit" title="Show">
                    <span>{t("admin.Show")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className="empty-b-text-v-bc">
        {t("admin.Therearenotournamentswithselectedfilters")}
      </p>
    </Layout>
  );
};

export { SportTournaments };
