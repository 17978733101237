import { t } from "i18next";
import React from "react";
import { useTypeSelector } from "../../../../../../../hooks/useTypeSelector";
import "./PersonalDetails.scss";

const PersonalDetails = () => {
  const { user } = useTypeSelector((data) => data.accountInfoReducer);
  return (
    <>
      <div className="overlay-header">{t("admin.PersonalDetails")}</div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content user-profile"
        data-scroll-lock-scrollable=""
      >
        <form>
          <div className="u-i-p-control-item-holder-bc">
            <div className="eligible-bonus">
              <i className="bc-i-player-info" />
              <span className="eligible-bonus-title">
                {t("admin.Eligibleforbonus")}:{" "}
              </span>
              <b className="eligible-bonus-status green">{t("admin.Yes")}</b>
            </div>
          </div>
          <div className="userProfile-content" data-scroll-lock-scrollable="">
            <div className="userProfileWrapper-bc userProfileSection-0">
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="id"
                      readOnly={true}
                      step={0}
                      defaultValue={user.id}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.ID")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="username"
                      readOnly={true}
                      step={0}
                      defaultValue={user.username}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Username")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="first_name"
                      readOnly={true}
                      step={0}
                      defaultValue={user.firstname}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Firstname")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="last_name"
                      readOnly={true}
                      step={0}
                      defaultValue={user.lastname}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Lastname")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="email"
                      className="form-control-input-bc"
                      autoComplete="username"
                      name="email"
                      readOnly={true}
                      step={0}
                      defaultValue={user.email}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Email")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="doc_number"
                      readOnly={true}
                      step={0}
                      defaultValue={user.passport}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.PassportID")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="birth_date"
                      readOnly={true}
                      step={0}
                      defaultValue={user.birthday}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Birthdate")}
                    </span>
                  </label>
                </div>
                <i className="dropdownIcon-bc bc-i-datepicker disabled" />
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="multi-select-bc disabled">
                  <div className="form-control-bc focused valid filled">
                    <div className="form-control-label-bc inputs">
                      <div className="form-control-select-bc ellipsis">
                        {t("admin.Male")}
                      </div>
                      <i className="form-control-icon-bc bc-i-small-arrow-down" />
                      <i className="form-control-input-stroke-bc" />
                      <span className="form-control-title-bc ellipsis">
                        {t("admin.Gender")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc dropdownArrowParent-bc">
                <div
                  className="form-control-bc select select-flag filled"
                  tabIndex={0}
                >
                  <div className="form-control-label-bc form-control-select-bc inputs ">
                    <i className="select-icon-bc ftr-lang-bar-flag-bc flag-bc kazakhstan" />
                    <span className="ellipsis">{user.country}</span>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Country")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="city"
                      step={0}
                      defaultValue={user.city}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.City")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default valid filled">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      className="form-control-input-bc"
                      name="address"
                      step={0}
                      defaultValue={user.address}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Address")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-controls-group-bc telephone">
                  <div className="form-controls-fields-bc">
                    <div className="form-controls-field-bc">
                      <div className="form-control-bc default  filled valid">
                        <label className="form-control-label-bc inputs">
                          <input
                            type="tel"
                            name="phoneNumber"
                            className="form-control-input-bc"
                            readOnly={true}
                            step={0}
                            defaultValue={user.phone}
                          />
                          <i className="form-control-input-stroke-bc" />
                          <span className="form-control-title-bc ellipsis">
                            {t("admin.Phonenumber")}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userProfileWrapper-bc userProfileSection-1">
              <div className="u-i-p-control-item-holder-bc">
                <hr className="passwordAboveSeparator" />
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="entrance-f-item-bc  ">
                  <div className="entrance-f-error-message-bc">
                    {t("admin.Enteryourpasswordtosavechanges")}.
                  </div>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc default has-icon ">
                  <label className="form-control-label-bc inputs">
                    <input
                      type="password"
                      className="form-control-input-bc"
                      autoComplete="current-password"
                      name="password"
                      step={0}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Currentpassword")}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="u-i-p-c-footer-bc">
            <button
              className="btn a-color right-aligned "
              type="submit"
              title="Save changes"
              disabled={false}
            >
              <span>{t("admin.Savechanges")}</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { PersonalDetails };
