import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import "./Sent.scss";

const Sent = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.Sent")}</div>
      <div className="messageBoxWrapper-bc">
        <p className="empty-b-text-v-bc">
          {t("admin.Youdonothaveanymessages")}
        </p>
        <Link
          className="btn a-color"
          to={{
            search: "/?profile=open&account=messages&page=new",
          }}
        >
          {t("admin.Newmessage")}
        </Link>
      </div>
    </>
  );
};

export { Sent };
