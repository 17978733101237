import React, { useState } from "react";
import { Layout } from "../../components";

import error_page_logo from "../../accets/images/png/error-page-logo.png";

import "./ErrorPage.scss";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../hooks/useTypeSelector";

const ErrorPage = () => {
  const [panelOpen, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);

  const navigation = useNavigate();

  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const moveToHomePage = () => {
    navigation(`/${language}`);
  };

  return (
    <Layout
      addClasses={` is-home-page ${panelOpen ? "smart-panel-is-visible" : ""}`}
      panelOnep={panelOpen}
      setPanelOpen={setPanelOpen}
      navStatus=""
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <div className="error-page ">
        <div className="error-page-content">
          <img src={error_page_logo} className="error-page-icon" alt="" />
          <p className="error-page-info">Sayfa bulunamadı</p>
          <button
            className="btn s-small a-color "
            type="button"
            title="ANA SAYFAYA GİT"
            onClick={moveToHomePage}
          >
            <span>ANA SAYFAYA GİT</span>
          </button>
        </div>
      </div>
    </Layout>
  );
};

export { ErrorPage };
