import { t } from "i18next";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { Link } from "react-router-dom";
import { userFormType } from "../../../ModalRegister";
import "./InputAgreement.scss";

interface IInputAgreement {
  register: UseFormRegister<userFormType>;
}

const InputAgreement = ({ register }: IInputAgreement) => {
  return (
    <div className="entrance-f-item-bc">
      <div className="checkbox-control-bc">
        <label className="checkbox-control-content-bc">
          <input
            type="checkbox"
            className="checkbox-control-input-bc"
            {...register("agree", { required: true })}
          />
          <i className="checkbox-control-icon-bc bc-i-checked" />
          <div className="checkbox-control-text-bc checkbox-control-text-content">
            <span>{t("admin.iconfirm")} </span>
            <p className="checkbox-control-text-privacy">
              <Link
                className=""
                to={{
                  search: "/?helpPageContent=privacy-policy",
                }}
              >
                <span>{t("admin.PrivacyPolicy")}</span>
              </Link>
              <span className="privacy-police-text">{t("admin.and")}</span>
              <Link
                className=""
                to={{
                  search: "/?helpPageContent=general-terms-and-conditions",
                }}
              >
                <span>{t("admin.GeneralTerms")}</span>
              </Link>
            </p>
          </div>
        </label>
      </div>
    </div>
  );
};

export { InputAgreement };
