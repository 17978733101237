import { t } from "i18next";
import React from "react";
import "./ModalResetForm.scss";

interface IModalResetForm {
  setWhitchForm: React.Dispatch<React.SetStateAction<string>>;
}

const ModalResetForm = ({ setWhitchForm }: IModalResetForm) => {
  return (
    <form
      className="entrance-form-bc login popup"
      data-scroll-lock-scrollable=""
    >
      <div className="sg-n-text-row-2-bc">{t("admin.ResetPassword")}</div>
      <div className="entrance-f-item-bc">
        <div className="radio-v-bc undefined">
          <div className="radio-control-bc ">
            <label className="radio-control-content-bc">
              <input
                type="radio"
                className="radio-control-input-bc"
                name="method"
                step={0}
                defaultValue="phone"
                defaultChecked={false}
              />
              <span className="radio-control-input-holder-bc" />
              <p className="radio-control-text-bc">SMS</p>
            </label>
          </div>
          <div className="radio-control-bc ">
            <label className="radio-control-content-bc">
              <input
                type="radio"
                className="radio-control-input-bc"
                name="method"
                step={0}
                defaultValue="email"
              />
              <span className="radio-control-input-holder-bc" />
              <p className="radio-control-text-bc">{t("admin.Email")}</p>
            </label>
          </div>
        </div>
      </div>
      <div className="entrance-f-item-bc">
        <div className="form-control-bc default ">
          <label className="form-control-label-bc inputs">
            <input
              type="text"
              className="form-control-input-bc"
              name="phone_number"
              step={0}
              defaultValue=""
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              {t("admin.Enterphonenumber")}
            </span>
          </label>
        </div>
      </div>
      <div className="entrance-form-actions-holder-bc login-ext-1">
        <div className="entrance-form-action-item-bc right">
          <button
            className="btn a-color "
            type="submit"
            title="Reset"
            disabled={true}
          >
            <span>{t("admin.Reset")}</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export { ModalResetForm };
