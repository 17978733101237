import { t } from "i18next";
import React from "react";
import { ImockSelectUserList } from "../../BuddyTransfer";
import "./SelectUser.scss";

interface ISelectUser {
  mockSelectUserList: ImockSelectUserList[];
  selectedRadioUser: ImockSelectUserList;
  setSelectedRadioUser: React.Dispatch<
    React.SetStateAction<ImockSelectUserList>
  >;
}

const SelectUser = ({
  mockSelectUserList,
  selectedRadioUser,
  setSelectedRadioUser,
}: ISelectUser) => {
  const handleRadioClick = (item: ImockSelectUserList) => {
    setSelectedRadioUser(item);
  };
  return (
    <div className="buddy-transfer-bc">
      <div className="radio-v-bc undefined">
        {mockSelectUserList.map((item) => {
          return (
            <div
              className="radio-control-bc "
              key={item.name}
              onClick={() => handleRadioClick(item)}
            >
              <label className="radio-control-content-bc">
                <input
                  type="radio"
                  className="radio-control-input-bc"
                  name="buddy_type"
                  step={0}
                  defaultValue={item.fullName}
                  checked={selectedRadioUser.name === item.name}
                />
                <span className="radio-control-input-holder-bc" />
                <p className="radio-control-text-bc">
                  {t(`admin.${item.fullName}`)}
                </p>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SelectUser };
