/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { NavigateFunction } from "react-router-dom";

import "./AviatorFreebet.scss";

interface IAviatorFreebet {
  navigate: NavigateFunction;
  language: string;
}

const AviatorFreebet = ({ navigate, language }: IAviatorFreebet) => {
  const handleCloseInfo = () => {
    navigate(`/${language}/promotions/all`);
  };

  return (
    <>
      <i
        className="e-p-close-icon-bc bc-i-close-remove"
        onClick={handleCloseInfo}
      />
      <div className="overlay-header">Her Yatırıma Aviator Freebet</div>
      <div className="promotion-details-container">
        <div className="promotion-details-header">
          <img
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_02b1e9a724621904f6e99dd0bc8bfd83.webp"
            className="promotion-details-img"
            alt=""
            title="Her Yatırıma Aviator Freebet"
          />
        </div>
        <div className="promotion-details-info" data-scroll-lock-scrollable="">
          <p>
            <strong>
              İLKLERİN SİTESİ MALTBAHİS'TEN YİNE&nbsp;BİR YENİLİK&nbsp;
            </strong>
          </p>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              Kurallar ve Şartlar
            </div>
            <div className="spoiler-content">
              <p>
                Yatırım işleminiz başarılı şekilde sonuçlandıktan sonra Freebet
                hakkınızı oyunlara katılım gerçekleştirmeden canlı destekten
                talep edebilirsiniz. Her başarılı yatırımınız için Freebet talep
                edebilirsiniz.
              </p>
              <p>
                <br />
                1.Freebet kampanyasından faydalanabilmek için gerekli olan
                minimum yatırım miktarı 100 TL dir. Freebet Aviator oyununda
                geçerlidir.
              </p>
              <p>
                2. Promosyon için belirlenen Freebet&nbsp;miktarları aşağıdaki
                gibidir:
              </p>
              <p>
                100 TL - 249 TL arası &nbsp;yatırımlara 20 &nbsp;Freebet
                <br />
                250 TL - 499 TL arası yatırımlara 50 &nbsp;Freebet
                <br />
                500 TL- 999 TL arası yatırımlara 75 Freebet
                <br />
                1000 TL &nbsp;ve üzeri yatırımlara 100 &nbsp;Freebet
              </p>
              <p>
                Bir gün içerisinde alabileceğiniz Maksimum Freebet miktari
                100'dür!
              </p>
              <p>
                3. Son 24 saat içerisindeki çekim miktarının yatırım miktarından
                fazla olduğu durumlarda Freebet alamazsınız. Freespin veya
                casino yatırım bonusu kampanyasından faydalanmış iseniz aynı
                anda Freebet kampanyasından faydalanamazsınız.
              </p>
              <p>
                4. Aynı IP cihaz bilgisi ve adres bilgilerine sahip kişilerden
                sadece 1 kişi bu promosyondan faydalanabilir.
              </p>
              <p>
                5. Maltbahis, promosyonları suistimal eden haksız kazanç elde
                etmeye çalışan veya Maltbahis genel kural ve şartlarını ihlal
                eden kullanıcıların hesaplarını kapatma hakkını saklı tutar.
              </p>
              <p>
                6. Maltbahis bu bonusu haber vermeksizin iptal etme, geri çekme
                veya kural ve şartlarını değiştirme hakkını saklı tutar. Ayrıca
                gerekli gördüğü kişilere ait hesaplara bonus vermeme hakkını da
                saklı tutar. Herhangi bir bonusun güncel kural ve şartlarını
                takip etme yükümlülüğü oyuncunun kendisine aittir.
              </p>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export { AviatorFreebet };
