import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "i18next";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTypeSelector } from "../../../../../../hooks/useTypeSelector";
import { setLanguage } from "../../../../../../store/action/settings/setlanguage";
import { Ilanguage } from "../../../../../../store/reducers/settingsReducer";
import "./LanguageSelectHeader.scss";

const LanguageSelectHeader = () => {
  const { en } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const language = useTypeSelector((data) => data.settingsReducer.language);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };
  const handleItemSelect = (item: Ilanguage) => {
    dispatch(setLanguage(item));
    i18n.changeLanguage(item.languageShort);
    setModalOpen(false);
    const currentPath = location.pathname;
    const newPath = en
      ? currentPath.replace(en, item.languageShort)
      : currentPath;
    navigate(newPath);
    if (en !== item.languageShort) {
      window.location.reload();
    }
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };
  const handleClosePanel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (en === "en") {
      handleItemSelect({
        languageShort: "en",
        languageName: "English",
        languageAbs: "ENG",
        languageFlag: "unitedkingdom",
      });
    } else if (en === "tr") {
      handleItemSelect({
        languageShort: "tr",
        languageName: "Türkçe",
        languageAbs: "TUR",
        languageFlag: "turkey",
      });
    }
  }, [en]);

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div
      className="form-control-bc select compact-mode filled"
      ref={wrapperRef}
    >
      <div
        className="form-control-label-bc form-control-select-bc inputs "
        onClick={handleOpenModal}
      >
        <i
          className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${language.languageFlag}`}
        />
        <span className="ellipsis">
          {language.languageAbs === "TRY" ? "TUR" : "ENG"}
        </span>
        <i
          className={`form-control-icon-bc bc-i-small-arrow-${
            modalOpen ? "up" : "down"
          }`}
        />
        <i className="form-control-input-stroke-bc" />
      </div>
      {modalOpen && (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          <label
            className={`checkbox-control-content-bc ${
              language.languageShort === "tr" ? "active" : ""
            } `}
            data-option-value="tur"
            onClick={() =>
              handleItemSelect({
                languageShort: "tr",
                languageName: "Türkçe",
                languageAbs: "TUR",
                languageFlag: "turkey",
              })
            }
          >
            <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkey" />
            <p className="checkbox-control-text-bc langModalText">TUR</p>
          </label>
          <label
            className={`checkbox-control-content-bc ${
              language.languageShort === "en" ? "active" : ""
            } `}
            data-option-value="eng"
            onClick={() =>
              handleItemSelect({
                languageShort: "en",
                languageName: "English",
                languageAbs: "ENG",
                languageFlag: "unitedkingdom",
              })
            }
          >
            
            <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedkingdom" />
            <p className="checkbox-control-text-bc langModalText">ENG</p>
          </label>
        </div>
      )}
    </div>
  );
};

export { LanguageSelectHeader };
