import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setModalLogin } from "../../../store/action/modals/setmodallogin";
import { setModalRegister } from "../../../store/action/modals/setmodalregister";
import "./BetsLipSport.scss";

const BetsLipSport = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch()
  const [betsLipSettingModal, setBetsLipSettingModal] = useState(false);
  const [betsLipSettingItem, setBetsLipSettingItem] = useState("Always ask");

  const handleOpenAuth = () => {
    dispatch(setModalLogin(true))
  };

  const handleOpenRegister = () => {
    dispatch(setModalRegister(true))
  };

  const handleSelectItem = (item: string) => {
    setBetsLipSettingItem(item);
    setBetsLipSettingModal(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setBetsLipSettingModal(false);
    }
  };

  const handleCloseModal = () => {
    setBetsLipSettingModal((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div
      className="betslip-bc Fixed  full"
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div id="betslip_container" className="">
        <div className="">
          <div className="banner-a-lot" />
        </div>
        <div className="betslip-full-content-bc">
          <div className="bs-f-header-bc">
            <div className="second-tabs-bc">
              <div
                className="tab-bc selected-underline betslip active"
                title="BetSlip"
              >
                <span>BetSlip</span>
              </div>
            </div>
            <div className="settings-r-b-row-bc">
              <div
                className="form-control-bc select s-small has-icon filled"
                tabIndex={0}
                ref={wrapperRef}
              >
                <div
                  className="form-control-label-bc form-control-select-bc inputs "
                  onClick={handleCloseModal}
                >
                  <i className="select-icon-bc bc-i-settings" />
                  <span className="ellipsis">{betsLipSettingItem}</span>
                  <i
                    className={`form-control-icon-bc bc-i-small-arrow-${
                      betsLipSettingModal ? "up" : "down"
                    }`}
                  />
                  <i className="form-control-input-stroke-bc" />
                </div>
                {betsLipSettingModal ? (
                  <div
                    className="multi-select-label-bc"
                    data-scroll-lock-scrollable=""
                  >
                    <label
                      className={`checkbox-control-content-bc ${
                        betsLipSettingItem === "Always ask" ? "active" : ""
                      } `}
                      data-option-value={0}
                      onClick={() => handleSelectItem("Always ask")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        Always ask
                      </p>
                    </label>
                    <label
                      className={`checkbox-control-content-bc ${
                        betsLipSettingItem === "Accept Higher odds"
                          ? "active"
                          : ""
                      } `}
                      data-option-value={1}
                      onClick={() => handleSelectItem("Accept Higher odds")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        Accept Higher odds
                      </p>
                    </label>
                    <label
                      className={`checkbox-control-content-bc ${
                        betsLipSettingItem === "Accept Any odds" ? "active" : ""
                      } `}
                      data-option-value={2}
                      onClick={() => handleSelectItem("Accept Any odds")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        Accept Any odds
                      </p>
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="bs-f-header-sub-navigation-bc " />
          <div className="bs-f-body-bc">
            <div
              className="bs-scroll-container-bc"
              data-scroll-lock-scrollable=""
            >
              <p className="empty-b-text-v-bc">Your betslip is empty</p>
              <div className="bs-actions-bc" />
            </div>
          </div>
          <div className="bs-f-footer ">
            <div className="bet-slip-switcher-wrapper-bc" />
            <div className="bs-info-massages">
              <div className="quickBetMsgWrapper-bc betslip-warning-message ">
                <i className="bs-notification-icon-bc bc-i-attention" />
                <span className="quickBetMsgText-bc">
                  To place your bet, please{" "}
                  <span className="bet-slip-info-link" onClick={handleOpenAuth}>
                    Sign in
                  </span>
                  or
                  <span
                    className="bet-slip-info-link"
                    onClick={handleOpenRegister}
                  >
                    Register
                  </span>
                </span>
              </div>
            </div>
            <div className="bs-numbers-bc ">
              <div className="bs-editable-number-buttons-bc">
                <span className="bs-number-button-bc i-2 ellipsis">50</span>
                <span className="bs-number-button-bc i-2 ellipsis">100</span>
                <span className="bs-number-button-bc i-2 ellipsis">500</span>
                <span className="bs-number-button-bc i-2 " title="Edit stakes">
                  <i className="bs-number-button-icon-bc bc-i-edit" />
                </span>
                <span className="bs-number-button-bc i-2 hidden">
                  <i className="bs-number-button-icon-bc bc-i-checked " />
                </span>
              </div>
            </div>
            <div className="bet-button-wrapper-bc content-type-0">
              <button
                className="btn a-color button-type-0 "
                type="button"
                title="Bet Now"
                disabled={true}
              >
                <span>Bet Now</span>
              </button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
            <div className="betslip-banner">
              <div className="sdr-item-holder-bc">
                <Link
                  to="/tr/promotions/all/17100/anl%C4%B1k--10-spor-kay%C4%B1p-bonusu"
                  target="_self"
                  className=""
                >
                  <img
                    src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_3ff67766543cd686d58e39b784a3b094.webp"
                    loading="lazy"
                    className="sdr-image-bc"
                    alt=""
                    title="kayıp"
                  />
                </Link>
              </div>
              <div className="sdr-item-holder-bc">
                <Link
                  to="/tr/promotions/all/17101/her-yat%C4%B1r%C4%B1ma--50-free-bet"
                  target="_self"
                  className=""
                >
                  <img
                    src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_b82172422154a70d6b5008ae70079451.webp"
                    loading="lazy"
                    className="sdr-image-bc"
                    alt=""
                    title="Freebet"
                  />
                </Link>
              </div>
              <div className="sdr-item-holder-bc">
                <Link
                  to="/tr/promotions/all/17102/-15-%C3%A7evrimsiz-spor-yat%C4%B1r%C4%B1m-bonusu"
                  target="_self"
                  className=""
                >
                  <img
                    src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_be35c22de506f0dabbc439a4e7d90272.webp"
                    loading="lazy"
                    className="sdr-image-bc"
                    alt=""
                    title="Spor yat"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BetsLipSport };
