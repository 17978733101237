import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoGameType } from "../common/types/Casino/CasinoGameType";
import { FavoritCasinoResponceType } from "../common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "../common/types/Casino/FavoritCasinoStateType";
import { CasinoInfo } from "../services";
import { setFavoritsLocalDelete } from "../store/action/favorits/setFavoritsLocalDelete";
import { setFavoritsLocalSelect } from "../store/action/favorits/setFavoritsLocalSelect";
import { setFavoritsUser } from "../store/action/favorits/setFavoritsUser";

interface IusePlayerLeftWindow {
  gamesSelected: CasinoGameType[];
  openGames: string[];
  favoritsGamesUser: FavoritCasinoResponceType[] | [];
  favoritsGamesLocal: FavoritCasinoStateType[] | [];
}

const usePlayerLeftWindow = ({
  gamesSelected,
  openGames,
  favoritsGamesUser,
  favoritsGamesLocal
}: IusePlayerLeftWindow) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closePlayer = () => {
    navigate("", {
      replace: true,
    });
  };

  const handleFavoriteUserSelect = () => {
    async function gandleSelectFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gamesSelected[0].id,
        type: 1,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    async function gandleRemoveFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gamesSelected[0].id,
        type: 0,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    favoritsGamesUser.some((obj) => obj.id === gamesSelected[0].id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };

  const handleFavoriteLocalSelect = () => {
    async function gandleSelectFavorit() {
      dispatch(
        setFavoritsLocalSelect({
          id: gamesSelected[0].id,
          slug: openGames[0],
          original_name: gamesSelected[0].original_name,
          is_demo_enabled: gamesSelected[0].is_demo_enabled,
          game_provider: {
            slug: gamesSelected[0].game_provider.slug,
          },
          banner: gamesSelected[0].banner,
        })
      );
    }

    async function gandleRemoveFavorit() {
      dispatch(
        setFavoritsLocalDelete({
          id: gamesSelected[0].id,
          slug: openGames[0],
          original_name: gamesSelected[0].original_name,
          is_demo_enabled: gamesSelected[0].is_demo_enabled,
          game_provider: {
            slug: gamesSelected[0].game_provider.slug,
          },
          banner: gamesSelected[0].banner,
        })
      );
    }
    favoritsGamesLocal.some((obj) => obj.id === gamesSelected[0].id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };
  
  return {
    handleFavoriteUserSelect,
    handleFavoriteLocalSelect,
    closePlayer
  };
};

export { usePlayerLeftWindow };
