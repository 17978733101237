import { t } from "i18next";
import React from "react";
import "./WinnerBlockPreloader.scss";

const WinnerBlockPreloader = () => {
  return (
    <div className="casino-info-block">
      <div className="main-tabs-bc">
        <div className="tab-bc selected-underline  active" title="">
          <span>{t("jackpot.LastWinner")}</span>
        </div>
        <div className="tab-bc selected-underline " title="">
          <span>{t("casino.TopWinners")}</span>
        </div>
      </div>
      <ul className="casino-info-content casino-info-content-winners">
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-winners-img skeleton-loader-full-banner" />
        </li>
      </ul>
    </div>
  );
};

export { WinnerBlockPreloader };
