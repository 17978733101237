import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import "./WarningVerification.scss";

const WarningVerification = () => {
  return (
    <Link
      className="u-i-p-p-u-i-edit-button-bc"
      to={{
        search: "?profile=open&account=profile&page=verify-account",
      }}
    >
      <i className="account-verified-edit-icon bc-i-not-verified" />
      <span className="account-verified-edit-title ellipsis">
        {t("admin.AccountNotVerified")}
      </span>
      <i className="u-i-p-l-h-icon-bc right bc-i-small-arrow-right" />
    </Link>
  );
};

export { WarningVerification };
