import React, { useEffect, useState } from "react";
// import { BannersListType } from "../../../common/types/Casino/BannersListType";
// import { CasinoInfo } from "../../../services";

import { mockCouruselMainType } from "./mockCourusel/mockCouruselMain";

import "./Courusel.scss";

interface ICourusel {
  mockCourusel: mockCouruselMainType[];
}

const Courusel = ({ mockCourusel }: ICourusel) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  // const [banners, setBanners] = useState<BannersListType>([]);
  const [windowSize, setWindowSize] = useState(0);
  const next = () => {
    if (currentIndex < mockCourusel.length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
      const currentMove = (currentIndex + 1) * 100;
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 9);
      }, 100);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 8);
      }, 110);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 7);
      }, 120);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 6);
      }, 130);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 5);
      }, 140);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 4);
      }, 150);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 3);
      }, 160);
      setTimeout(() => {
        setCurrentPosition(currentMove - 100 + 100 / 2);
      }, 170);
      setTimeout(() => {
        setCurrentPosition(currentMove);
      }, 300);
    } else {
      setCurrentIndex(() => 0);
      setCurrentPosition(0);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setCurrentPosition((currentIndex + 1) * 100);
    } else {
      setCurrentIndex(() => mockCourusel.length - 1);
      setCurrentPosition(0);
    }
  };
  // useEffect(() => {
  //   async function getBanners() {
  //     const res = await CasinoInfo.MainBanners();
  //     setBanners(res.data);
  //   }
  //   getBanners();
  // }, []);
  useEffect(() => {
    const updatePageWidth = () => {
      setWindowSize(document.documentElement.offsetWidth);
    };

    updatePageWidth();

    window.addEventListener("resize", updatePageWidth);

    return () => {
      window.removeEventListener("resize", updatePageWidth);
    };
  }, []);

  return (
    <div
      className="hm-row-bc has-slider"
      style={{ gridTemplateColumns: "12fr", marginBottom: 20 }}
    >
      <div className="slider-bc  ">
        <div className="carousel">
          <div className="carousel-viewport">
            <div
              className="carousel-container"
              style={{
                transform: `translate3d(-${currentPosition}%, 0px, 0px)`,
              }}
            >
              {mockCourusel.map((item) => {
                return (
                  <div
                    className="carousel-slide "
                    style={{ width: "100%" }}
                    key={item.id}
                  >
                    <div className="sdr-item-holder-bc">
                      <div className="sdr-item-bc">
                        <img
                          style={{ maxHeight: windowSize * 0.3 }}
                          src={item.img}
                          loading="lazy"
                          className="sdr-image-bc"
                          alt=""
                          title={item.title}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="carousel-count-arrow-container with-count">
            <i
              className="carousel-arrow-left bc-i-small-arrow-left "
              onClick={prev}
            />
            <div className="carousel-count-arrow">
              {currentIndex + 1} / {mockCourusel.length}
            </div>
            <i
              className="carousel-arrow-right bc-i-small-arrow-right "
              onClick={next}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Courusel };
