import React, { useState } from "react";
import { Layout } from "../../components/layout";
import "./Zeplin.scss";

const Zeplin = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const ifFramePath =
    "https://pi.njoybingo.com/game.do?token=aba480fafd06da488c2f98ccc5c7ac50_3e4c6d9c0e2f425cfa5aba22c5cc7cf6&pn=performads&game=PRPLAY-1301&type=CHARGED&lang=en";
  return (
    <Layout
      addClasses={`page-full-size ${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } hide-tab-navigation-footer-mobile compact-footer ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="zeplin"
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <iframe
        className="iframe-full-page"
        title={ifFramePath}
        src={ifFramePath}
        allowFullScreen={true}
        allow="autoplay; microphone *"
      />
    </Layout>
  );
};

export { Zeplin };
