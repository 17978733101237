import React from "react";
import { winners } from "../../WinnerBlock";
import "./WinnerCart.scss";

interface IWinnerCart {
  winner: winners;
}

const WinnerCart = ({ winner }: IWinnerCart) => {
  return (
    <li>
      <img className="casino-info-img" src={winner.img} alt={winner.alt} />
      <div className="casino-winners-info">
        <b>{winner.name}</b>
        <span className="ellipsis">{winner.title}</span>
      </div>
      <b className="casino-winners-amount">{winner.count} ₺</b>
    </li>
  );
};

export { WinnerCart };
