import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AdminPanel } from "../../../../../../../services";
import "./Bets.scss";
import { BetId } from "./components/BetId/BetId";
import { BetType } from "./components/BetType/BetType";
import { Period } from "./components/Period/Period";
import { RangePeriod } from "./components/RangePeriod/RangePeriod";
import { SportName } from "./components/SportName/SportName";
import loadGif from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const Bets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const [betType, setBetType] = useState("All");
  const [period, setPeriod] = useState("24/1");
  const [loadBets, setLoadBets] = useState(false);
  const handleShow = () => {
    async function getBets() {
      setLoadBets(true);
      try {
        const res = await AdminPanel.userBets(
          `?status=all&type=${betType}&page=1&limit=10&date=15.05.2023+-+22.05.2023`
        );
        setLoadBets(false);
      } catch {
        setLoadBets(false);
      }
    }
    getBets();
  };
  return (
    <>
      <div className="overlay-header">
        {page === "bets" ? "All" : ""}
        {page === "open-bets" ? "Open Bets" : ""}
        {page === "gifted" ? "Gifted Bets" : ""}
      </div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content"
        data-scroll-lock-scrollable=""
      >
        <div className="componentFilterWrapper-bc">
          <div className="componentFilterBody-bc">
            <div className="componentFilterElsWrapper-bc">
              <div className="filter-form-w-bc">
                <div className="componentFilterBody-content">
                  <BetId />
                  <SportName />
                  <BetType betType={betType} setBetType={setBetType} />
                  <Period period={period} setPeriod={setPeriod} />
                  {period === "custom" ? <RangePeriod /> : null}
                </div>
                <div className="u-i-p-c-filter-footer-bc">
                  <button className="btn a-color " onClick={handleShow}>
                    <span>{t("admin.Show")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loadBets ? (
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loadGif} alt="" />
            </div>
          </div>
        ) : (
          <p className="empty-b-text-v-bc">{t("admin.Nobetstoshow")}</p>
        )}
      </div>
    </>
  );
};

export { Bets };
