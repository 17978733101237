import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./BetType.scss";

interface IBetType {
  betType: string;
  setBetType: React.Dispatch<React.SetStateAction<string>>;
}

const BetType = ({ betType, setBetType }: IBetType) => {
  const wrapperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectItem = (item: string) => {
    setBetType(item);
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc ">
        <div className="form-control-bc valid filled">
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenModal}
          >
            <div className="form-control-select-bc ellipsis">{betType}</div>
            <i className="form-control-icon-bc bc-i-small-arrow-down" />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              {t("admin.BetType")}
            </span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  betType === "all" ? "active" : ""
                } `}
                data-option-value=""
                onClick={() => handleSelectItem("all")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.All")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  betType === "casino" ? "active" : ""
                } `}
                data-option-value={1}
                onClick={() => handleSelectItem("casino")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Casino")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  betType === "sportbook" ? "active" : ""
                } `}
                data-option-value={2}
                onClick={() => handleSelectItem("sportbook")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Sportbook")}
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { BetType };
