import { t } from "i18next";
import React, { useRef, useEffect } from "react";
import { useTypeSelector } from "../../../../../../../../../hooks/useTypeSelector";
import { LanguageSelect } from "./components/languageSelect/LanguageSelect";
import { OddsformatSelect } from "./components/oddsformatSelect/OddsformatSelect";
import { TimeFormatSelect } from "./components/timeFormatSelect/TimeFormatSelect";
import "./SidePanelSettings.scss";

interface ISidePanelSettings {
  settings: boolean;
  setSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidePanelSettings = ({ settings, setSettings }: ISidePanelSettings) => {
  const { oddsFormat, language, timeFormat } = useTypeSelector(
    (data) => data.settingsReducer
  );
  const wrapperRef = useRef(null);
  const handleClosePanel = () => {
    setSettings(false);
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className={`sidePanel-holder-bc ${settings ? "" : "hidden"}`}>
      <div className="sidePanel-holder" ref={wrapperRef}>
        <div className="overlay-header">
          <i className="bc-i-close-remove" onClick={handleClosePanel} />
          <span>{t("admin.Settings")}</span>
        </div>
        <div className="settings-selectors-bc">
          <div className="settings-selectors-item">
            <OddsformatSelect
              oddsFormat={oddsFormat}
              setSettings={setSettings}
            />
            <LanguageSelect language={language} setSettings={setSettings} />
            <TimeFormatSelect
              timeFormat={timeFormat}
              setSettings={setSettings}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { SidePanelSettings };
