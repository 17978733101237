import { FavoritCasinoResponceType } from "../../common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "../../common/types/Casino/FavoritCasinoStateType";

interface favoritsReducerType {
  favoritCasinoLocal: FavoritCasinoStateType[] | [];
  favoritCasinoUser: FavoritCasinoResponceType[] | [];
}

const initialState: favoritsReducerType = {
  favoritCasinoLocal: [],
  favoritCasinoUser: [],
};

type favoritsAction = {
  type: string;
  payload: FavoritCasinoStateType;
};

const favoritsReducer = (state = initialState, action: favoritsAction) => {
  switch (action.type) {
    case "SET_FAVORITS_LOCAL_DELETE":
      return { ...state, favoritCasinoLocal: state.favoritCasinoLocal.filter(
        (item) => item.id !== action.payload.id
      ) };
    case "SET_FAVORITS_LOCAL_SELECT":
      return {
        ...state,
        favoritCasinoLocal: [...state.favoritCasinoLocal, action.payload],
      };
    case "SET_FAVORITS_USER":
      return { ...state, favoritCasinoUser: action.payload };
    default:
      return state;
  }
};

export { favoritsReducer };

export type { favoritsReducerType };
