import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./BetHistory.scss";

interface IBetHistory {
  page: string;
}

const BetHistory = ({ page }: IBetHistory) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const account = searchParams.get("account") || "";
  const [modalActive, setModalActive] = useState(false);
  const handleOpenModal = () => {
    setModalActive((prev) => !prev);
  };

  useEffect(() => {
    if (account === "history") {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  }, []);
  return (
    <div className={`user-profile-nav ${modalActive ? "active" : ""}`}>
      <div className="user-profile-nav-header" onClick={handleOpenModal}>
        <i className="user-profile-nav-icon bc-i-history" />
        <span className="user-profile-nav-title">{t("admin.BetHistory")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i
          className={`user-profile-nav-arrow bc-i-small-arrow-${
            modalActive ? "up" : "down"
          }`}
        />
      </div>
      <div className="user-profile-nav-list">
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "bets" && account === "history" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=history&page=bets",
          }}
        >
          {t("admin.All")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "open-bets" && account === "history" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=history&page=open-bets",
          }}
        >
          {t("admin.Openbets")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "gifted" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=history&page=gifted",
          }}
        >
          {t("admin.Giftedbets")} 
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "gifts" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=history&page=gifts",
          }}
        >
          {t("admin.Receivedgifts")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <div className="user-profile-nav-item-cursor " />
      </div>
    </div>
  );
};

export { BetHistory };
