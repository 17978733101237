import React from "react";
import { useTypeSelector } from "../../../../../../../../hooks/useTypeSelector";
import "./PanelItemsUnauth.scss";

interface IPanelItemsUnauth {
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFavorits: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  betsLipOpen?: boolean;
}

const PanelItemsUnauth = ({
  setNotificationOpen,
  setFavorits,
  setSettings,
  setBetsLipOpen,
}: IPanelItemsUnauth) => {
  const handleOpenNotification = () => {
    setNotificationOpen((prev) => !prev);
  };
  const handleOpenFavorits = () => {
    setFavorits((prev) => !prev);
  };

  const handleOpenSettings = () => {
    setSettings((prev) => !prev);
  };
  const handleOpenBetsLip = () => {
    if (setBetsLipOpen) {
      setBetsLipOpen((prev) => !prev);
    }
  };
  const favoritCasinoLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  return (
    <div className="hdr-smart-panel-holder-bc custom-margin">
      <button
        className="sp-button-bc "
        title="Whats new"
        data-component="Notifications"
        onClick={handleOpenNotification}
      >
        <i
          id="smart-panel-notification-button-id"
          className="sp-button-icon-bc bc-i-notification"
        ></i>
        <i className="  count-blink-even " data-badge=""></i>
      </button>
      <button
        className="sp-button-bc "
        title="Favorites"
        data-component="FavoriteGames"
        onClick={handleOpenFavorits}
      >
        <i
          id="smart-panel-favorites-button-id"
          className="sp-button-icon-bc bc-i-star"
        ></i>
        {favoritCasinoLocal.length > 0 ? (
          <i
            className=" count-odd-animation count-blink-odd "
            data-badge={favoritCasinoLocal.length.toString()}
          ></i>
        ) : null}
        <i className="  count-blink-even " data-badge=""></i>
      </button>
      <button
        className="sp-button-bc "
        title="Settings"
        data-component="Settings"
        onClick={handleOpenSettings}
      >
        <i
          id="smart-panel-settings-button-id"
          className="sp-button-icon-bc bc-i-settings"
        ></i>
        <span className="sp-button-content-text-bc"></span>
      </button>
      <button className="sp-button-bc " type="button" title="Contact support">
        <i className="sp-button-icon-bc bc-i-live-chat"></i>
      </button>
      <button
        className="sp-button-bc "
        title="BetSlip"
        data-component="Betslip"
        onClick={handleOpenBetsLip}
      >
        <i className="sp-button-icon-bc bc-i-betslip"></i>
        {/* <i className=" count-odd-animation count-blink-odd " data-badge="4"></i> */}
      </button>
    </div>
  );
};

export { PanelItemsUnauth };
