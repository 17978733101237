import React from "react";
import ProductBanner from "../../banner/ProductBanner";

import "./ProductBannerMain.scss";

const ProductBannerMain = () => {
  return (
    <div className="hm-row-bc ">
      <ProductBanner>
        <a
          target="_self"
          className="product-banner-info-bc product-banner-bc"
        >
          <img
            className="product-banner-img-bc"
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_b3ba077352dbc034b67121b81c374e36.webp"
            alt=""
          />
        </a>
        <a
          target="_self"
          className="product-banner-info-bc product-banner-bc"
        >
          <img
            className="product-banner-img-bc"
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_68005423afbfc11afaf25fe539a2f34f.webp"
            alt=""
          />
        </a>
        <a
          target="_self"
          className="product-banner-info-bc product-banner-bc"
        >
          <img
            className="product-banner-img-bc"
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_a17242ccfec22b07a32b103ef0ac15c0.webp"
            alt=""
          />
        </a>
        <a
          target="_self"
          className="product-banner-info-bc product-banner-bc"
        >
          <img
            className="product-banner-img-bc"
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_e41cce470307ff0aa5cfa0757e1e4b99.webp"
            alt=""
          />
        </a>
      </ProductBanner>
    </div>
  );
};

export { ProductBannerMain };
