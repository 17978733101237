import { t } from "i18next";
import React, { useState } from "react";
import "./BetSlipModal.scss";

interface IBetSlipModal {
  setBetsLipOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  betsLipOpen: boolean | undefined;
}

const BetSlipModal = ({ setBetsLipOpen }: IBetSlipModal) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [seletedSetting, setSelectedSetting] = useState("Always ask");

  const handleMoveSettingsStatus = () => {
    setSettingsOpen((prev) => !prev);
  };

  const handleCloseModal = () => {
    if (setBetsLipOpen) {
      setBetsLipOpen(false);
    }
  };

  const handleSelectSetting = (item: string) => {
    setSelectedSetting(item);
    setSettingsOpen(false);
  };
  return (
    <div
      className="betslip-bc Full  full"
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div id="betslip_container" className="">
        <div className="">
          <button
            className="e-p-close-icon-bc bc-i-close-remove "
            type="button"
            onClick={handleCloseModal}
          />
        </div>
        <div className="betslip-full-content-bc">
          <div className="bs-f-header-bc">
            <div className="second-tabs-bc">
              <div
                className="tab-bc selected-underline betslip active"
                title="BetSlip"
              >
                <span>{t("admin.BetSlip")}</span>
              </div>
              {/* <div
                className="tab-bc selected-underline open-bets"
                title="Open Bets"
              >
                <span>Open Bets</span>
              </div> */}
            </div>
            <div className="settings-r-b-row-bc">
              <div
                className="form-control-bc select s-small has-icon filled"
                tabIndex={0}
              >
                <div
                  className="form-control-label-bc form-control-select-bc inputs "
                  onClick={handleMoveSettingsStatus}
                >
                  <i className="select-icon-bc bc-i-settings" />
                  <span className="ellipsis">
                    {t(`admin.${seletedSetting}`)}
                  </span>
                  <i
                    className={`form-control-icon-bc bc-i-small-arrow-${
                      settingsOpen ? "up" : "down"
                    }`}
                  />
                  <i className="form-control-input-stroke-bc" />
                </div>
                {settingsOpen ? (
                  <div
                    className="multi-select-label-bc"
                    data-scroll-lock-scrollable=""
                  >
                    <label
                      className={`checkbox-control-content-bc ${
                        seletedSetting === "Always ask" ? "active" : ""
                      }`}
                      data-option-value={0}
                      onClick={() => handleSelectSetting("Always ask")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        {t("admin.Always ask")}
                      </p>
                    </label>
                    <label
                      className={`checkbox-control-content-bc ${
                        seletedSetting === "Accept Higher odds" ? "active" : ""
                      }`}
                      data-option-value={1}
                      onClick={() => handleSelectSetting("Accept Higher odds")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        {t("admin.Accept Higher odds")}
                      </p>
                    </label>
                    <label
                      className={`checkbox-control-content-bc ${
                        seletedSetting === "Accept Any odds" ? "active" : ""
                      }`}
                      data-option-value={2}
                      onClick={() => handleSelectSetting("Accept Any odds")}
                    >
                      <p
                        className="checkbox-control-text-bc"
                        style={{ pointerEvents: "none" }}
                      >
                        {t("admin.Accept Any odds")}
                      </p>
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="bs-f-header-sub-navigation-bc " />
          <div className="bs-f-body-bc">
            <div
              className="bs-scroll-container-bc"
              data-scroll-lock-scrollable=""
            >
              <p className="empty-b-text-v-bc">Your betslip is empty</p>
              <div className="bs-actions-bc" />
            </div>
          </div>
          <div className="bs-f-footer ">
            <div className="bet-slip-switcher-wrapper-bc" />
            <div className="bs-info-massages" />
            <div className="bs-numbers-bc ">
              <div className="bs-editable-number-buttons-bc">
                <span className="bs-number-button-bc i-2 ellipsis">50</span>
                <span className="bs-number-button-bc i-2 ellipsis">100</span>
                <span className="bs-number-button-bc i-2 ellipsis">500</span>
                <span className="bs-number-button-bc i-2 " title="Edit stakes">
                  <i className="bs-number-button-icon-bc bc-i-edit" />
                </span>
                <span className="bs-number-button-bc i-2 hidden">
                  <i className="bs-number-button-icon-bc bc-i-checked " />
                </span>
              </div>
            </div>
            <div className="bet-button-wrapper-bc content-type-0">
              <button
                className="btn a-color button-type-0 "
                type="button"
                title="Bet Now"
                disabled={false}
              >
                <span>{t("admin.BetNow")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BetSlipModal };
