import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";

import { CasinoGames } from "../../components/casino/casinoGames";
import { CasinoGamesPreloader } from "../../components/casino/casinoGames/preloader/CasinoGamesPreloader";
import { CenterRow } from "../../components/casino/centerRow";
import { Categories } from "../../components/casino/centerRow/components/Categories/Categories";
import { SelectorProvider } from "../../components/casino/selectorProvider/SelectorProvider";
import { Layout } from "../../components/layout";
import { useCasinoGames } from "../../hooks/useCasinoGames";
import { GameProvider } from "../../common/types/Casino/GameProvider";
import { CategoriesType } from "../../common/types/Casino/CategoriesType";
import { Courusel } from "../../components/main/Courusel/Courusel";

import { mockCouruselCasino } from "./mockCourusel/mockCouruselCasino";

export interface IsdrImageBc {
  img: string;
  alt: string;
  title: string;
}

const Casino = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);

  const [providersList, setProvidersList] = useState<GameProvider | object>({});
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [selectedProvidersUrl, setSelectedProvidersUrl] = useState("");

  const [categoryList, setCategoryList] = useState<CategoriesType | object>({});
  const [selectedCategory, setSelectedCategory] = useState(6);

  const [casinoGamesList, setCasinoGamesList] = useState<
    CasinoIconGameType[] | []
  >([]);

  const [seacrhGames, setSeacrhGames] = useState("");

  const page = useRef(1);

  useCasinoGames({
    selectedProviders,
    selectedProvidersUrl,
    selectedCategory,
    seacrhGames,
    page,
    setLoadingGames,
    setLoadingGamesAdd,
    setProvidersList,
    setSelectedProvidersUrl,
    setCategoryList,
    setCasinoGamesList,
    loadStop,
    setLoadStop,
  });
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  
  return (
    <Layout
      addClasses={`
       page-full-size ${
         betsLipOpen ? "betslip-Full" : "betslip-Hidden"
       }  casino-slots compact-footer ${
        panelOnep ? "smart-panel-is-visible" : ""
      } `}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus={"casino"}
      betsLipPage={"betsLipPage"}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      {loading ? null : <Courusel mockCourusel={mockCouruselCasino} />}
      <CenterRow loading={loading} />
      <Categories
        categoryList={categoryList}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <SelectorProvider
        providersList={providersList}
        selectedProviders={selectedProviders}
        setSelectedProviders={setSelectedProviders}
        seacrhGames={seacrhGames}
        setSeacrhGames={setSeacrhGames}
      />
      {loadingGames ? (
        <CasinoGamesPreloader />
      ) : (
        <CasinoGames
          casinoGamesList={casinoGamesList}
          loadingGamesAdd={loadingGamesAdd}
        />
      )}
    </Layout>
  );
};

export { Casino };
