import { t } from "i18next";
import React from "react";
import "./SportBonus.scss";

const SportBonus = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.SportBonus")}</div>
      <div className="sportBonusWrapper" data-scroll-lock-scrollable="">
        <p className="empty-b-text-v-bc">
        {t("admin.Therearenobonusesforselectedtype")}
        </p>
      </div>
    </>
  );
};

export { SportBonus };
