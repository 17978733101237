import React from "react";
import { CategoriesType } from "../../../../../../../common/types/Casino/CategoriesType";
import "./CategoriesItem.scss";

type IcategoryItem = {
  style: string;
  title: string;
  dataId: number;
};

interface ICategoriesItem {
  categoryItem: CategoriesType;
  selectedCategory: number;
  setSelectedCategory: React.Dispatch<React.SetStateAction<number>>;
}

const CategoriesItem = ({
  categoryItem,
  selectedCategory,
  setSelectedCategory,
}: ICategoriesItem) => {
  const handleSelectCategory = (item: number) => {
    setSelectedCategory(item);
  };
  return (
    <div
      className={`horizontal-sl-item-bc ${
        selectedCategory === categoryItem.id ? "active" : ""
      }`}
      data-id={categoryItem.id}
      title={categoryItem.name}
      onClick={() => handleSelectCategory(categoryItem.id)}
    >
      <i className={`horizontal-sl-icon-bc bc-i-default-icon `} />
      <p className="horizontal-sl-title-bc">{categoryItem.name}</p>
    </div>
  );
};

export { CategoriesItem };

export type { IcategoryItem };
