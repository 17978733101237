import { t } from "i18next";
import React from "react";
import "./Withdraws.scss";

const Withdraws = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.WithdrawStatus")}</div>
      <div className="historyLayoutWrapper-bc">
        <div className="historyList-table-details">
          <div className="historyList-thead">
            <div className="historyListEl">
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.DateandID")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.PaidTime")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.System")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.VoucherCode")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.Amount")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.Status")}</div>
              </div>
              <div className="historyListEl-list-item ">
                <div className="historyListEl-info">{t("admin.Cancel")}</div>
              </div>
            </div>
          </div>
          <div className="historyList-tbody">
            <p className="empty-b-text-v-bc">{t("admin.NoWithdrawalInformation")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export { Withdraws };
