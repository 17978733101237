import { FavoritCasinoResponceType } from "../../../common/types/Casino/FavoritCasinoResponceType";

type setFavoritsUserType = {
  type: string;
  payload: FavoritCasinoResponceType[];
};

function setFavoritsUser(payload: FavoritCasinoResponceType[]): setFavoritsUserType {
  return {
    type: 'SET_FAVORITS_USER',
    payload,
  };
}

export { setFavoritsUser };
