import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { CasinoInfo } from "../../../services";
import { GameItem } from "../../liveCasino/gamesList/components/GameItem/GameItem";

import "./MainCasinoList.scss";

const MainCasinoList = () => {
  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  useEffect(() => {
    async function getCasinoGames() {
      const res = await CasinoInfo.games(
        `/6?current_page=1&limit=5&page=1&is_mobile=true`
      );
      setCasinoGames(res.data.data);
    }
    getCasinoGames();
  }, []);
  return (
    <div className="hm-row-bc ">
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis">{t("admin.Casinogames")}</h3>
        <a className="view-see-more" href={`/${language}/casino/slots/`}>
          {t("navHeader.More")}
          <i className="view-arrow-more bc-i-small-arrow-right"></i>
        </a>
      </div>
      <div className="games-horiz ">
        <div className="games-horiz-scroll" data-scroll-lock-scrollable="">
          {casinoGames.map((item) => {
            return <GameItem key={item.id} gameItem={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export { MainCasinoList };
