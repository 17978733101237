import React, { useState } from "react";
import { Layout } from "../../components/layout";

import "./Main.scss";

import { Courusel } from "../../components/main/Courusel/Courusel";
import { JackpotBannerMain } from "../../components/main/JackpotBannerMain/JackpotBannerMain";
import { ProductBannerMain } from "../../components/main/ProductBannerMain/ProductBannerMain";
import { UpcomingMatches } from "../../components/main/UpcomingMatches/UpcomingMatches";
import { MainCasinoList } from "../../components/main/MainCasinoList/MainCasinoList";
import { MainLiveList } from "../../components/main/MainLiveList/MainLiveList";
import { MainGamesList } from "../../components/main/MainGamesList/MainGamesList";
import { FuturedGamesNew } from "../../components/main/FuturedGamesNew/FuturedGamesNew";

import { mockCouruselMain } from "../../components/main/Courusel/mockCourusel/mockCouruselMain";

const Main = () => {
  const [panelOpen, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);

  return (
    <Layout
      addClasses={` is-home-page ${panelOpen ? "smart-panel-is-visible" : ""}`}
      panelOnep={panelOpen}
      setPanelOpen={setPanelOpen}
      navStatus=""
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <Courusel mockCourusel={mockCouruselMain} />
      <JackpotBannerMain />
      {/* <FuturedGames /> */}
      <FuturedGamesNew />
      <ProductBannerMain />
      <UpcomingMatches />
      <MainCasinoList />
      <MainLiveList />
      <MainGamesList />
    </Layout>
  );
};

export { Main };
