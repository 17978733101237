import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import "./BalanceInfo.scss";

const BalanceInfo = () => {
  return (
    <div className="u-i-p-amount-holder-bc">
      <div className="u-i-p-amounts-bc withdrawable">
        <div className="u-i-p-a-content-bc">
          <div className="total-balance-r-bc">
            <div className="u-i-p-a-user-balance">
              <span className="u-i-p-a-title-bc ellipsis">{t("admin.MainBalance")}</span>
              <b className="u-i-p-a-amount-bc">*******</b>
            </div>
            <i className="u-i-p-a-c-icon-bc bc-i-eye-hidden" />
          </div>
          <div className="u-i-p-a-buttons-bc">
            <Link
              className="u-i-p-a-deposit-bc ellipsis"
              to={{
                search: "?profile=open&account=balance&page=deposit",
              }}
            >
              <i className="bc-i-wallet" />
              <span className="ellipsis" title="Deposit">
              {t("admin.Deposit")}
              </span>
            </Link>
            <Link
              className="u-i-p-a-withdraw-bc ellipsis"
              to={{
                search: "?profile=open&account=balance&page=withdraw",
              }}
            >
              <i className="bc-i-withdraw" />
              <span className="ellipsis" title="Withdraw">
              {t("admin.Withdraw")}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="u-i-p-amounts-bc bonuses">
        <div className="u-i-p-a-content-bc">
          <span className="u-i-p-a-title-bc ellipsis">{t("admin.TotalBonusBalance")}</span>
          <span className="u-i-p-a-amount-bc">******</span>
          <div className="bonus-info-section">
            <div>
              <span className="ellipsis">{t("admin.BonusBalance")}</span>
              <b>******</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BalanceInfo };
