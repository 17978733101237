import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./Bonuses.scss";

interface IBonuses {
  page: string;
}

const Bonuses = ({ page }: IBonuses) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const account = searchParams.get("account") || "";
  const [modalActive, setModalActive] = useState(false);
  const handleOpenModal = () => {
    setModalActive((prev) => !prev);
  };

  useEffect(() => {
    if (account === "bonuses") {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  }, []);

  return (
    <div className={`user-profile-nav ${modalActive ? "active" : ""}`}>
      <div className="user-profile-nav-header" onClick={handleOpenModal}>
        <i className="user-profile-nav-icon bc-i-promotion" />
        <span className="user-profile-nav-title">{t("admin.Bonuses")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i
          className={`user-profile-nav-arrow bc-i-small-arrow-${
            modalActive ? "up" : "down"
          }`}
        />
      </div>
      <div className="user-profile-nav-list">
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "loyalty-points" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=loyalty-points",
          }}
        >
          {t("admin.LoyaltyPoints")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "exchange-shop" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=exchange-shop",
          }}
        >
          {t("admin.ExchangeShop")}          
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "sport" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=sport",
          }}
        >
          {t("admin.SportBonus")}     
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "bonus-history" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=bonus-history",
          }}
        >
          {t("admin.Bonushistory")}  
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "casino" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=casino",
          }}
        >
          {t("admin.CasinoBonus")}          
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "promo-code" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=promo-code",
          }}
        >
          {t("admin.Promocode")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "casino-free-spins" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=casino-free-spins",
          }}
        >
          {t("admin.CasinoFreeSpins")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <div className="user-profile-nav-item-cursor " />
      </div>
    </div>
  );
};

export { Bonuses };
