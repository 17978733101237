import React from "react";
import { ImockCarts } from "../../TournamentBlock";
import "./TournamentCart.scss";

interface ITournamentCart {
  mockCart: ImockCarts;
}

const TournamentCart = ({ mockCart }: ITournamentCart) => {
  return (
    <li>
      <img className="casino-info-img" src={mockCart.img} alt={mockCart.alr} />
      <div className="casino-tournament-info">
        <span className="casino-tournament-name ellipsis">{mockCart.name}</span>
        <b className="casino-tournament-amount">{mockCart.price} ₺</b>
      </div>
    </li>
  );
};

export { TournamentCart };
