import React from "react";
import { useDispatch } from "react-redux";
import { setShowRedirectIfno } from "../../../../store/action/settings/setshowredirectifno";

import "./RedirectInfo.scss";

const RedirectInfo = () => {
  const dispatch = useDispatch();
  const handleCloseInfo = () => {
    dispatch(setShowRedirectIfno(false));
  };
  return (
    <div className="hdr-dynamic-content">
      <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
        <i
          className="e-p-close-icon-bc bc-i-close-remove"
          onClick={handleCloseInfo}
        />
        <div className="carousel">
          <div className="carousel-viewport">
            <div
              className="carousel-container"
              style={{ transform: "translate3d(0%, 0px, 0px)" }}
            >
              <div
                className="carousel-slide active-slide"
                style={{ width: "100%" }}
              >
                <div className="informative-widget">
                  <a className="informative-widget-link custom-text-bg">
                    <p className="custom-text">
                      Here we can display information about the redirect
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { RedirectInfo };
