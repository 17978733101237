import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { userFormType } from "../../../ModalRegister";
import { CountryNumberSelect } from "./countryNumber/CountryNumberSelect";
import "./InputPhoneNumber.scss";

interface IInputPhoneNumber {
  register: UseFormRegister<userFormType>;
  watch: UseFormWatch<userFormType>;
  countryCode: number;
  setCountryCode: React.Dispatch<React.SetStateAction<number>>;
}

const InputPhoneNumber = ({
  countryCode,
  setCountryCode,
  register,
  watch,
}: IInputPhoneNumber) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [shortError, setShortError] = useState(false);
  const [longError, setLongError] = useState(false);
  const name = watch("phone");

  useEffect(() => {
    setInputValue(name);
    setFieldError(false);
    setShortError(false);
    setLongError(false);
  }, [name]);

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    if (inputValue.length === 0) {
      setInputFocus(false);
      setFieldError(true);
    }
    if (inputValue.length < 2 && inputValue.length > 0) {
      setShortError(true);
    }
    if (inputValue.length > 20) {
      setLongError(true);
    }
  };

  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default ${
          fieldError || shortError || longError ? "invalid" : ""
        } ${inputFocus ? "focused" : ""}`}
      >
        <div className="form-controls-field-content">
          <CountryNumberSelect
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
          <div className="form-controls-field-bc custom-phone-input">
            <label className="form-control-label-bc inputs">
              <input
                type="tel"
                className="form-control-input-bc"
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "",
                  },
                })}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc ellipsis">
                {t("admin.Phonenumber")}
              </span>
            </label>
            {fieldError || shortError || longError ? (
              <div className="form-control-message-holder-bc">
                <span className="form-control-message-bc">
                  {fieldError ? t("admin.Thisfieldisrequired") : ""}
                  {shortError ? t("admin.Tooshort") : ""}
                  {longError ? t("admin.Toolong") : ""}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { InputPhoneNumber };
