import { t } from "i18next";
import React from "react";
import { useTypeSelector } from "../../../../../../../../../hooks/useTypeSelector";
import "./TransferTrack.scss";

const TransferTrack = () => {
  const { user, balance } = useTypeSelector((data) => data.accountInfoReducer);
  return (
    <div className="buddy-transfer-item casino-game-tr-bc">
      <div className="casino-game-tr-form-block">
        <div className="casino-game-tr-from-to-bc">
          <div className="casino-game-tr-from-block-bc ellipsis">
            <span className="casino-game-tr-label">{t("admin.From")}</span>
            <span className="casino-game-tr-title ellipsis">
              {user.firstname} {user.lastname}
            </span>
            <span className="casino-game-tr-price">
              {balance.balance.amount.toFixed(2)} ₺
            </span>
          </div>
          <i className="casino-game-tr-switcher-bc bc-i-arrow-to-right" />
          <div className="casino-game-tr-to-block-bc ellipsis">
            <span className="casino-game-tr-label">{t("admin.To")}</span>
            <span className="casino-game-tr-title ellipsis">******</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TransferTrack };
