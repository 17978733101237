import React, { useEffect, useRef, useState } from "react";
import "./InputCurrency.scss";

interface IInputCurrency {
  currenctSelect: string;
  setCurrenctSelect: React.Dispatch<React.SetStateAction<string>>;
}

const InputCurrency = ({
  currenctSelect,
  setCurrenctSelect,
}: IInputCurrency) => {
  const [modalOpen, setModalOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleSelectCurrency = (item: string) => {
    setCurrenctSelect(item);
    setModalOpen(false);
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  const handleOpenInput = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="entrance-f-item-bc">
      <div className="multi-select-bc " ref={wrapperRef}>
        <div className="form-control-bc focused filled">
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenInput}
          >
            <div className="form-control-select-bc ellipsis">
              {currenctSelect}
            </div>
            <i className="form-control-icon-bc bc-i-small-arrow-down" />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">Currency</span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  currenctSelect === "TRY" ? "active" : ""
                } `}
                data-option-value="TRY"
                onClick={() => handleSelectCurrency("TRY")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  TRY
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  currenctSelect === "USD" ? "active" : ""
                } `}
                data-option-value="USD"
                onClick={() => handleSelectCurrency("USD")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  USD
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { InputCurrency };
