import { t } from "i18next";
import React from "react";
import "./Providaers.scss";

const Providaers = () => {
  return (
    <div className="accordion-title">
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis custom-font-size">{t("casino.Providers")}</h3>
      </div>
    </div>
  );
};

export { Providaers };
