import React, { ReactNode } from "react";
import "./ValidationInfo.scss";

interface IValidationInfo {
  children: ReactNode;
}

const ValidationInfo = ({ children }: IValidationInfo) => {
  return (
    <div className="buddy-transfer-bc">
      <div className="entrance-f-item-bc  ">
        <div className="entrance-f-error-message-bc">
          {children}
        </div>
      </div>
    </div>
  );
};

export { ValidationInfo };
