import React, {useRef, useState} from "react";
import {useEffect} from "react";

import "./JackpotBanner.scss"
import Odometer from "odometer";
import "odometer/themes/odometer-theme-default.css";
import { t } from "i18next";

interface IJackpotBanner {
    title: string;
    amount: number;
}

export const JackpotBanner = ({title, amount}: IJackpotBanner) => {
    const odometerRef = useRef(null);
    const [visibleJackpools, setVisibleJackpools] = useState(false)
    const [jackpools] = useState([
        {
            name: 'MEGA',
            amount: Math.floor(Math.random() * (450000 - 415287 + 1) + 415287),
            ref: useRef(null),
            od: null,
            iconType: 'bc-i-mega-jackpot',
        },
        {
            name: 'MAJOR',
            amount: Math.floor(Math.random() * (450000 - 415287 + 1) + 415287),
            ref: useRef(null),
            od: null,
            iconType: 'bc-i-major-jackpot'
        },
        {
            name: 'MINOR',
            amount: Math.floor(Math.random() * (450000 - 415287 + 1) + 415287),
            ref: useRef(null),
            od: null,
            iconType: 'bc-i-minor-jackpot'
        },
        {
            name: 'MINI',
            amount: Math.floor(Math.random() * (450000 - 415287 + 1) + 415287),
            ref: useRef(null),
            od: null,
            iconType: 'bc-i-mini-jackpot'
        }
    ]);

    let od:any;

    useEffect(() => {
        od = new Odometer({
            el: odometerRef.current,
            value: amount,   // initial value
            format: "(,ddd).dd",
            theme: "default",
        });
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            amount = amount + Math.floor(Math.random() * (55 - 15 + 1) + 15);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            od.update(amount);
        }, 5000);
        // clearing interval
        return () => clearInterval(timer);
    }, [])

    useEffect(() => {
        jackpools.forEach((item) => {
            item.od = new Odometer({
                el: item.ref.current,
                value: item.amount,   // initial value
                format: "(,ddd).dd",
                theme: "default"
            });
        })
    }, [jackpools])

    useEffect(() => {
        const timersR: NodeJS.Timer[] = [];
        jackpools.forEach((item) => {
            timersR.push(
                setInterval(() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                item.od.update(item.amount + Math.floor(Math.random() * (55 - 15 + 1) + 15))
            }, Math.floor(Math.random() * (5000 - 2000 + 1) + 2000))
            );
        })

        // clearing interval
        return () => timersR.forEach((timer) => clearInterval(timer));
    }, [])

    return (
        <div className="jackpot-banner jackpot-widget-full">
            <div className="casinoJackpotWrapper-bc">
                <div className="carousel">
                    <div className="carousel-viewport">
                        <div className="carousel-container">
                            <div className="carousel-slide active-slide" style={{ 'width': '100%' }}>
                                <div className="casinoJackpotLevelEl-bc">
                                    <h3 className="casinoJackpotLevelTitle-bc">{title}</h3>
                                    <div ref={odometerRef} id="odometer" className="odometer casinoJackpotLevelPrice-bc odometer-auto-theme" data-cur="₺"></div>
                                </div>
                                <div className="jackpot-pool-container">
                                    {jackpools.map(function(item, index){
                                        return <ul onClick={() => setVisibleJackpools(prevState => !prevState)} key={index} className="jackpot-pool-wrapper ">
                                            <li className="jackpot-pool-item"><i
                                                className={`jackpot-pool-icon ${item.iconType}`}></i>
                                                <div className="jackpot-pool-money"><span
                                                    className="jackpot-pool-title">{ item.name }</span>
                                                    <div
                                                        ref={item.ref}
                                                        className="jackpot-pool-price odometer"
                                                        data-cur="₺">
                                                    </div>
                                                </div>
                                                <i className={`jackpot-pool-arrow-icon ${!visibleJackpools ? "bc-i-small-arrow-down" : "bc-i-small-arrow-up"}`}/>
                                            </li>
                                            {
                                                visibleJackpools && <li className="jackpot-pool-list">
                                                    <div className="jackpot-pool-list-item">
                                                        <div className="jackpot-pool-list-item-header"><h5
                                                            className="jackpot-pool-status">{t("jackpot.LargestWinner")}</h5>
                                                            <time className="jackpot-pool-info-date">22.04.2021, 18:14</time>
                                                        </div>
                                                        <span className="jackpot-pool-info">ID: 82489816</span><span
                                                        className="jackpot-pool-price">9,988,818.98 ₺</span></div>
                                                    <div className="jackpot-pool-list-item"><h5
                                                        className="jackpot-pool-status">Min qualifying bet</h5><span
                                                        className="jackpot-pool-price">856.34 ₺</span></div>
                                                    <div className="jackpot-pool-list-item">
                                                        <div className="jackpot-pool-list-item-header"><h5
                                                            className="jackpot-pool-status">{t("jackpot.LastWinner")}</h5>
                                                            <time className="jackpot-pool-info-date">03.05.2023, 18:42</time>
                                                        </div>
                                                        <span className="jackpot-pool-info">ID: 1271828232</span><span
                                                        className="jackpot-pool-price">562,075.12 ₺</span></div>
                                                    <div className="jackpot-pool-list-item"><h5
                                                        className="jackpot-pool-status">{t("jackpot.LastWinner")}</h5><span
                                                        className="jackpot-pool-price">25</span></div>
                                                    <div className="jackpot-pool-list-item"><h5
                                                        className="jackpot-pool-status">Total won</h5><span
                                                        className="jackpot-pool-price">25,260,976.25 ₺</span></div>
                                                </li>
                                            }
                                        </ul>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JackpotBanner;