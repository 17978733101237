import React from "react";
import "./TopBar.scss";

interface ITopBar {
  jackpotBackground: string;
  setJackpotBackground: React.Dispatch<React.SetStateAction<string>>;
}

const TopBar = ({ jackpotBackground, setJackpotBackground }: ITopBar) => {
  const handleChangeBackround = (item: string) => {
    setJackpotBackground(item);
  };
  return (
    <div className="casinoJackpotTopBar-bc">
      <div className="casinoJackpotSlider-bc">
        <div className="casinoJackpotSliderWrapper-bc">
          <div className="casino-jackpot-slider-bc">
            <ul
              className="casinoJackpotSliderElContainer-bc"
              style={{ transform: "translateX(0%)" }}
            >
              <li
                onClick={() => handleChangeBackround("egt")}
                className={`casinoJackpotSliderEl-bc bc-i-egt ${
                  jackpotBackground === "egt" ? "active" : ""
                }`}
                style={{ width: "50%" }}
              />
              <li
                onClick={() => handleChangeBackround("fgs")}
                className={`casinoJackpotSliderEl-bc bc-i-fgs ${
                  jackpotBackground === "fgs" ? "active" : ""
                }`}
                style={{ width: "50%" }}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TopBar };
