import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AdminPanel, AuthService } from "../../../../../../../services";
import { setAuth } from "../../../../../../../store/action/auth/setauth";
import { LoginInput } from "./authFormInputs/LoginInput/LoginInput";
import { PasswordInput } from "./authFormInputs/PasswordInput/PasswordInput";
import { RemindMeInput } from "./authFormInputs/RemindMeInput/RemindMeInput";
import loadGif from "../../../../../../../accets/images/gif/loader/loader.gif";
import "./ModalAuthForm.scss";
import { setUserInfo } from "../../../../../../../store/action/auth/setuserinfo";
import { setUserBalance } from "../../../../../../../store/action/auth/setuserbalance";
import { setModalLogin } from "../../../../../../../store/action/modals/setmodallogin";
import { t } from "i18next";

type FormLogin = {
  login: string;
  password: string;
  remindMe: boolean;
};

interface IModalAuthForm {
  setWhitchForm: React.Dispatch<React.SetStateAction<string>>;
}

const ModalAuthForm = ({ setWhitchForm }: IModalAuthForm) => {
  const dispatch = useDispatch();
  const [formPort, setFormPort] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const { register, handleSubmit, watch, setValue } = useForm<FormLogin>();
  const onSubmit = (data: FormLogin) => {
    async function autotizationLogin() {
      try {
        setFormPort(true);
        const res = await AuthService.login({
          password: data.password,
          username: data.login,
        });
        if (res.status === 200) {
          dispatch(setAuth("player"));
          dispatch(setModalLogin(false));
          localStorage.setItem("token", res.data);
          const data = await AdminPanel.userInfo();
          dispatch(setUserInfo(data.data));
          const resdata = await AdminPanel.userBalance();
          dispatch(setUserBalance(resdata.data));
        }
        setFormPort(false);
      } catch {
        setFormPort(false);
        setInvalidData(true);
      }
    }
    autotizationLogin();
  };
  const handleMoveToReset = () => {
    setWhitchForm("reset");
  };
  return (
    <form
      className="entrance-form-bc login popup"
      data-scroll-lock-scrollable=""
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-sign-bc">
        <div className="sg-n-text-row-1-bc">
          {t("admin.Alreadyhaveaccount")}?
        </div>
        <div className="sg-n-text-row-2-bc">
          {t("admin.Signinwearewaitingforyou")}!
        </div>
        <LoginInput register={register} watch={watch} />
        <PasswordInput register={register} watch={watch} setValue={setValue} />
        <RemindMeInput register={register} />
        {invalidData ? (
          <div className="entrance-f-item-bc  ">
            <div className="entrance-f-error-message-bc">
              Invalid Username or Password
            </div>
          </div>
        ) : null}

        {formPort ? (
          <div className="entrance-form-actions-holder-bc login-ext-1">
            <div className="entrance-form-action-item-bc right">
              <div className="bc-loader small ">
                <div className="bc-loader-contain">
                  <img src={loadGif} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="entrance-form-actions-holder-bc login-ext-1">
            <div className="entrance-form-action-item-bc right">
              <button className="btn a-color " type="submit" title="Sign in">
                <span>{t("admin.Signin")}</span>
              </button>
            </div>
          </div>
        )}

        <div className="sg-n-forgot-password-text" onClick={handleMoveToReset}>
        {t("admin.Forgotyourpassword")}?
        </div>
      </div>
      <div title="Contact support" className="live-chat-adviser-bc">
        <i className="bc-i-live-chat" />
        <span className="ellipsis">{t("admin.Contactsupport")}</span>
      </div>
    </form>
  );
};

export { ModalAuthForm };

export type { FormLogin };
