import React, { useEffect, useRef, useState } from "react";
import "./StatusSelect.scss";

const statusSelectMock = ["Upcoming", "Live", "Finished", "Canceled"];

const StatusSelect = () => {
  const wrapperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusSelect, setStatusSelect] = useState([
    statusSelectMock[0],
    statusSelectMock[1],
  ]);
  const [prevStatusSelect, setPrevStatusSelect] = useState(statusSelect);
  const [selectTextList, setSelectTextList] = useState("");

  const handleSelectItem = (item: string) => {
    const itemIndex = prevStatusSelect.indexOf(item);
    const newStatusSelect = [...prevStatusSelect];
    if (itemIndex > -1) {
      newStatusSelect.splice(itemIndex, 1);
    } else {
      newStatusSelect.push(item);
    }
    setPrevStatusSelect(newStatusSelect);
  };

  const handleSelectAll = () => {
    if (prevStatusSelect.length !== statusSelectMock.length) {
      setPrevStatusSelect(statusSelectMock);
    } else {
      setPrevStatusSelect([statusSelectMock[0]]);
    }
  };

  const handleModalOpen = () => {
    setModalOpen((prev) => !prev);
    if (prevStatusSelect.length === 0) {
      setPrevStatusSelect(statusSelect);
    } else {
      setStatusSelect(prevStatusSelect);
    }
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
      if (prevStatusSelect.length === 0) {
        setPrevStatusSelect(statusSelect);
      } else {
        setStatusSelect(prevStatusSelect);
      }
    }
  };

  useEffect(() => {
    if (prevStatusSelect.length === 1) {
      setSelectTextList(prevStatusSelect[0]);
    } else {
      let mockText = "";
      prevStatusSelect.map((item) => {
        mockText = mockText + item + ", ";
      });
      const mockTextResult = mockText.slice(0, -2);
      setSelectTextList(mockTextResult);
    }
  }, [prevStatusSelect, statusSelect]);

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc">
        <div className="form-control-bc filled valid">
          <div
            className="form-control-label-bc inputs"
            onClick={handleModalOpen}
          >
            <div className="form-control-select-bc">{selectTextList} </div>
            <i
              className={`form-control-icon-bc bc-i-small-arrow-${
                modalOpen ? "up" : "down"
              }`}
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">Statuses</span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">All </p>
                <input
                  type="checkbox"
                  className="checkbox-control-input-bc"
                  onClick={handleSelectAll}
                  checked={prevStatusSelect.length === statusSelectMock.length}
                />
                <i className="checkbox-control-icon-bc bc-i-checked custom-checker" />
              </label>

              {statusSelectMock.map((item) => {
                return (
                  <label className="checkbox-control-content-bc" key={item}>
                    <i className="multi-select-icon-bc undefined" />
                    <p className="checkbox-control-text-bc">{item} </p>
                    <input
                      type="checkbox"
                      className="checkbox-control-input-bc"
                      checked={prevStatusSelect.includes(item)}
                      onClick={() => handleSelectItem(item)}
                    />
                    <i className="checkbox-control-icon-bc bc-i-checked custom-checker" />
                  </label>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { StatusSelect };
