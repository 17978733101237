import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./MyProfile.scss";

interface IMyProfile {
  page: string;
}

const MyProfile = ({ page }: IMyProfile) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const account = searchParams.get("account") || "";
  const [modalActive, setModalActive] = useState(false);
  const handleOpenModal = () => {
    setModalActive((prev) => !prev);
  };

  useEffect(() => {
    if (account === "profile") {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  }, []);

  return (
    <div className={`user-profile-nav ${modalActive ? "active" : ""}`}>
      <div className="user-profile-nav-header" onClick={handleOpenModal}>
        <i className="user-profile-nav-icon bc-i-user" />
        <span className="user-profile-nav-title">{t("admin.MyProfile")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i
          className={`user-profile-nav-arrow bc-i-small-arrow-${
            modalActive ? "up" : "down"
          }`}
        />
      </div>
      <div className="user-profile-nav-list">
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "details" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=details",
          }}
        >
          {t("admin.PersonalDetails")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "change-password" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=change-password",
          }}
        >
          {t("admin.ChangePassword")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "verify-account" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=verify-account",
          }}
        >
          {t("admin.VerifyAccount")}  
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`user-profile-nav-item ellipsis ${
            page === "two-factor-authentication" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=two-factor-authentication",
          }}
        >
          {t("admin.TwoStepAuthentication")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <div className="user-profile-nav-item-cursor " />
      </div>
    </div>
  );
};

export { MyProfile };
