import React, { useState } from "react";
import { Layout } from "../../components/layout";
import { PromotionSelect } from "../../components/promotion/promotionSelect/PromotionSelect";
import { PromotionSlot } from "../../components/promotion/promotionSlots/PromotionSlots";
import "./Promotions.scss";

const Promotions = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [selected, setSelected] = useState("all");
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  return (
    <Layout
      addClasses={`${betsLipOpen ? "betslip-Full" : "betslip-Hidden"} ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="promotions"
      betsLipPage={"betsLipPage"}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <PromotionSelect selected={selected} setSelected={setSelected} />
      <PromotionSlot />
    </Layout>
  );
};

export { Promotions };
