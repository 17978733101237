import React, { useEffect, useRef } from "react";
import { CasinoIconGameType } from "../common/types/Casino/CasinoIconGameType";
import { CategoriesType } from "../common/types/Casino/CategoriesType";
import { GameProvider } from "../common/types/Casino/GameProvider";
import { CasinoInfo } from "../services";

interface IuseCasinoGames {
  selectedProviders: number[];
  selectedProvidersUrl: string;
  selectedCategory: number;
  page: React.MutableRefObject<number>;
  seacrhGames: string;
  loadStop: boolean;
  setLoadingGames: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingGamesAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setProvidersList: React.Dispatch<React.SetStateAction<GameProvider | object>>;
  setSelectedProvidersUrl: React.Dispatch<React.SetStateAction<string>>;
  setCategoryList: React.Dispatch<
    React.SetStateAction<CategoriesType | object>
  >;
  setCasinoGamesList: React.Dispatch<
    React.SetStateAction<CasinoIconGameType[] | []>
  >;
  setLoadStop: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCasinoGames = ({
  selectedProviders,
  selectedProvidersUrl,
  selectedCategory,
  page,
  seacrhGames,
  loadStop,
  setLoadingGames,
  setLoadingGamesAdd,
  setProvidersList,
  setSelectedProvidersUrl,
  setCategoryList,
  setCasinoGamesList,
  setLoadStop,
}: IuseCasinoGames) => {

  const selectedProvidersRef = useRef("");

  useEffect(() => {
    selectedProvidersRef.current = selectedProvidersUrl;
  }, [selectedProvidersUrl]);

  useEffect(() => {
    setLoadingGames(true);
    page.current = 1;
    async function casinoGames() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.games(
        `/${selectedCategory}?current_page=1&limit=25&page=${
          page.current
        }&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList((prevState) => prevState.concat(res.data.data));
      setLoadingGamesAdd(false);
    }
    async function casinoGamesSearch() {
      const res = await CasinoInfo.searchGames(
        `?current_page=${page}&limit=25&page=1&search=${seacrhGames}&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList(res.data.data);
      setLoadingGames(false);
    }
    if (seacrhGames.length > 0) {
      casinoGamesSearch();
    } else {
      casinoGames();
    }
  }, [seacrhGames]);

  //Serach by providersUrl
  useEffect(() => {
    setLoadingGames(true);
    page.current = 1;
    async function casinoGames() {
      const res = await CasinoInfo.games(
        `/${selectedCategory}?current_page=${page}&limit=25&page=1&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList(res.data.data);
      setLoadingGames(false);
    }
    if (seacrhGames.length === 0) {
      casinoGames();
    }
    casinoGames();
  }, [selectedProvidersUrl]);

  //selectProviders
  useEffect(() => {
    page.current = 1;
    let timeUrl = "";
    selectedProviders.map((item: number) => {
      timeUrl = timeUrl + item + ",";
    });
    setSelectedProvidersUrl(timeUrl);
  }, [selectedProviders]);

  useEffect(() => {
    page.current = 1;
    async function getProvidersList() {
      const res = await CasinoInfo.providers("");
      setProvidersList(res.data);
    }
    getProvidersList();
  }, []);

  //serach by category
  useEffect(() => {
    page.current = 1;
    setLoadingGames(true);
    async function casinoGames() {
      const res = await CasinoInfo.games(
        `/${selectedCategory}?current_page=${page}&limit=25&page=1&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList(res.data.data);
      setLoadingGames(false);
    }
    if (seacrhGames.length === 0) {
      casinoGames();
    }
  }, [selectedCategory]);

  //search categoryList
  useEffect(() => {
    page.current = 1;
    async function getCategoryList() {
      const res = await CasinoInfo.categories("");
      setCategoryList(res.data);
    }
    getCategoryList();
  }, []);

  //search casino slots
  const loadMore = () => {
    async function casinoGames() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.games(
        `/${selectedCategory}?current_page=1&limit=25&page=${page.current}&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList((prevState) => prevState.concat(res.data.data));
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
    }
    async function casinoGamesSearch() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=false${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersRef.current}`
            : ""
        }`
      );
      setCasinoGamesList((prevState) => prevState.concat(res.data.data));
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
    }
    if (seacrhGames.length > 0) {
      if (!loadStop) {
        casinoGamesSearch();
      }
    } else {
      if (!loadStop) {
        casinoGames();
      }
    }
  };

  useEffect(() => {
    if (seacrhGames.length === 0) {
      loadMore();
      setLoadStop(false);
    }
  }, [seacrhGames]);

  window.onscroll = function () {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      loadMore();
    }
  };
};

export { useCasinoGames };
