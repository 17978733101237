import React, { useState } from "react";
import "./ModalAuth.scss";
import { ModalAuthForm } from "./modalAuthForm/ModalAuthForm";
import logo from "../../../../../../accets/images/png/logo.png";
import { ModalResetForm } from "./modalResetForm/ModalResetForm";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalLogin } from "../../../../../../store/action/modals/setmodallogin";
import { setModalRegister } from "../../../../../../store/action/modals/setmodalregister";
import { t } from "i18next";
import { useTypeSelector } from "../../../../../../hooks/useTypeSelector";

const ModalAuth = () => {
  const dispatch = useDispatch();
  const [whitchForm, setWhitchForm] = useState("auth");
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const handleMoveToRegister = () => {
    dispatch(setModalRegister(true));
    dispatch(setModalLogin(false));
  };

  const handleMoveToAuth = () => {
    setWhitchForm("auth");
  };
  return (
    <div className="entrance-popup-bc sign-in">
      <div className="e-p-content-holder-bc">
        <div className="e-p-content-bc">
          <div className="e-p-header-bc">
            <Link className="popup-t-logo-w-bc" to={`/${language}/`}>
              <img className="hdr-logo-bc" src={logo} alt="" />
            </Link>
            <div className="e-p-sections-bc">
              <div className="e-p-section-item-bc">
                {whitchForm === "auth" ? (
                  <button
                    className="btn s-small register "
                    type="button"
                    title="Register"
                    onClick={handleMoveToRegister}
                  >
                    <span>{t("admin.Register")}</span>
                  </button>
                ) : (
                  <div
                    className="e-p-section-item-bc"
                    onClick={handleMoveToAuth}
                  >
                    <span className="e-p-section-title-bc">
                      {t("admin.Signin")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="e-p-body-bc">
            {whitchForm === "auth" ? (
              <ModalAuthForm setWhitchForm={setWhitchForm} />
            ) : (
              <ModalResetForm setWhitchForm={setWhitchForm} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalAuth };
