import React from "react";

import "./BottomButton.scss";

const BottomButton = () => {
  const handleScrollTo = () => {
    const scrollStep = -window.scrollY / (500 / 15);

    const scrollAnimation = () => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
        requestAnimationFrame(scrollAnimation);
      }
    };

    scrollAnimation();
  };
  return (
    <div className="center-bottom-btn">
      <button
        className="btn nav-floating-btn bc-i-double-arrow-top custom-button"
        type="button"
        onClick={handleScrollTo}
      />
    </div>
  );
};

export { BottomButton };
