import { t } from "i18next";
import React from "react";
import "./TransferButton.scss";

const TransferButton = () => {
  return (
    <div className="buddy-transfer-item buddy-transfer-button">
      <button
        className="btn a-color "
        // type="submit"
        title="Transfer"
        disabled={true}
      >
        <span>{t("admin.Transfer")}</span>
      </button>
    </div>
  );
};

export { TransferButton };
