import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./SelectPeriod.scss";

interface ISelectPeriod {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  periodSelected: string;
  setPeriodSelected: React.Dispatch<React.SetStateAction<string>>;
}

const SelectPeriod = ({
  modalOpen,
  setModalOpen,
  periodSelected,
  setPeriodSelected,
}: ISelectPeriod) => {
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleSellectPeriod = (item: string) => {
    setPeriodSelected(item);
    setModalOpen(false);
  };

  const handleOpenSelect = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="componentFilterWrapper-bc">
      <div className="componentFilterBody-bc">
        <div className="componentFilterElsWrapper-bc">
          <form className="filter-form-w-bc">
            <div className="componentFilterBody-content" ref={wrapperRef}>
              <div className="u-i-p-control-item-holder-bc">
                <div
                  className={`form-control-bc ${
                    focused ? "focused" : ""
                  } default `}
                >
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      inputMode="decimal"
                      className="form-control-input-bc"
                      name="bet_id"
                      step={0}
                      defaultValue=""
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.BetID")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="multi-select-bc ">
                  <div className="form-control-bc valid filled">
                    <div
                      className="form-control-label-bc inputs"
                      onClick={handleOpenSelect}
                    >
                      <div className="form-control-select-bc ellipsis">
                        {periodSelected === "24/1" ? "24 hourse" : ""}
                        {periodSelected === "24/3" ? "72 hourse" : ""}
                        {periodSelected === "24/7" ? "One week" : ""}
                        {periodSelected === "24/30" ? "30 dayes" : ""}
                        {periodSelected === "custom" ? "Custom" : ""}
                      </div>
                      <i className="form-control-icon-bc bc-i-small-arrow-down" />
                      <i className="form-control-input-stroke-bc" />
                      <span className="form-control-title-bc ellipsis">
                        {t("admin.Period")}
                      </span>
                    </div>
                    {modalOpen ? (
                      <div className="multi-select-label-bc">
                        <label
                          className={`checkbox-control-content-bc ${
                            periodSelected === "24/1" ? "active" : ""
                          }`}
                          data-option-value={24}
                          onClick={() => handleSellectPeriod("24/1")}
                        >
                          <p
                            className="checkbox-control-text-bc"
                            style={{ pointerEvents: "none" }}
                          >
                            24 {t("admin.hours")}
                          </p>
                        </label>
                        <label
                          className={`checkbox-control-content-bc ${
                            periodSelected === "24/3" ? "active" : ""
                          }`}
                          data-option-value={72}
                          onClick={() => handleSellectPeriod("24/3")}
                        >
                          <p
                            className="checkbox-control-text-bc"
                            style={{ pointerEvents: "none" }}
                          >
                            72 {t("admin.hours")}
                          </p>
                        </label>
                        <label
                          className={`checkbox-control-content-bc ${
                            periodSelected === "24/7" ? "active" : ""
                          }`}
                          data-option-value={168}
                          onClick={() => handleSellectPeriod("24/7")}
                        >
                          <p
                            className="checkbox-control-text-bc"
                            style={{ pointerEvents: "none" }}
                          >
                            {t("admin.Birhafta")}
                          </p>
                        </label>
                        <label
                          className={`checkbox-control-content-bc ${
                            periodSelected === "24/30" ? "active" : ""
                          }`}
                          data-option-value={720}
                          onClick={() => handleSellectPeriod("24/30")}
                        >
                          <p
                            className="checkbox-control-text-bc"
                            style={{ pointerEvents: "none" }}
                          >
                            30 {t("admin.Days")}
                          </p>
                        </label>
                        <label
                          className={`checkbox-control-content-bc ${
                            periodSelected === "custom" ? "active" : ""
                          }`}
                          data-option-value=""
                          onClick={() => handleSellectPeriod("custom")}
                        >
                          <p
                            className="checkbox-control-text-bc"
                            style={{ pointerEvents: "none" }}
                          >
                            {t("admin.Custom")}
                          </p>
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {periodSelected === "custom" ? (
                <>
                  <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
                    <div className="react-datepicker-wrapper">
                      <div className="react-datepicker__input-container">
                        <div className="form-control-bc default filled ">
                          <label className="form-control-label-bc inputs">
                            <input
                              className="form-control-input-bc "
                              type="text"
                              placeholder="From"
                              readOnly={true}
                              defaultValue=""
                            />
                            <i className="form-control-input-stroke-bc" />
                            <span className="form-control-title-bc ellipsis">
                            {t("admin.Range")}
                            </span>
                            <i className="dropdownIcon-bc bc-i-datepicker" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
                    <div className="react-datepicker-wrapper">
                      <div className="react-datepicker__input-container">
                        <div className="form-control-bc default filled ">
                          <label className="form-control-label-bc inputs">
                            <input
                              className="form-control-input-bc "
                              type="text"
                              placeholder="To"
                              readOnly={true}
                              defaultValue=""
                            />
                            <i className="form-control-input-stroke-bc" />
                            <span className="form-control-title-bc ellipsis" />
                            <i className="dropdownIcon-bc bc-i-datepicker" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="u-i-p-c-filter-footer-bc">
              <button className="btn a-color " type="submit" title="Show">
                <span>{t("admin.Show")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { SelectPeriod };
