import React, { ReactNode, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { BetsLipContainer } from "./betsLipContainer/BetsLipContainer";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { PopupManagerPlayer } from "./popup/playerPopup/PopupManagerPlayer";
import { PopupManager } from "./popup/unauthPopup/PopupManager";
import "./index.scss";
import { CasinoPlayer } from "../CasinoPlayer/CasinoPlayer";
import { CasinoSelectPopap } from "../CasinoSelectPopap/CasinoSelectPopap";
import { CompanyInfoModal } from "./footer/FooterModal/CompanyInfoModal/CompanyInfoModal";

interface ILayout {
  addClasses: string;
  children: ReactNode;
  panelOnep: boolean;
  setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navStatus: string;
  betsLipPage: string;
  betsLipOpen?: boolean;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Layout = ({
  addClasses,
  children,
  panelOnep,
  setPanelOpen,
  navStatus,
  betsLipPage,
  betsLipOpen,
  setBetsLipOpen,
}: ILayout) => {
  const [gameSelectOpen, setGameSelectOpen] = useState(false);
  const { role } = useTypeSelector((data) => data.accountInfoReducer);
  const [searchParams] = useSearchParams();
  const helpPageContent = searchParams.get("helpPageContent") || "";
  const profile = searchParams.get("profile") || "";
  const openGames = searchParams.get("openGames") || "";
  const gameNames = searchParams.get("gameNames") || "";
  return (
    <div
      className={`${addClasses} layout-bc theme-default ${
        openGames && gameNames ? "casino-lock-scroll" : ""
      } ${betsLipOpen ? "betslip-Full" : "betslip-Hidden"}`}
    >
      {/* <CasinoInfo /> */}
      <Header
        panelOnep={panelOnep}
        setPanelOpen={setPanelOpen}
        navStatus={navStatus}
        betsLipOpen={betsLipOpen}
        setBetsLipOpen={setBetsLipOpen}
      />
      <div className="layout-content-holder-bc">
        {openGames && gameNames ? (
          <CasinoPlayer seGameSelectOpen={setGameSelectOpen} />
        ) : null}
        {children}
        {gameSelectOpen ? (
          <CasinoSelectPopap setGameSelectOpen={setGameSelectOpen} />
        ) : null}
        {helpPageContent ? <CompanyInfoModal helpPageContent={helpPageContent}/> : null}
      </div>
      <Footer />
      <PopupManager role={role} />
      {role === "player" ? (
        <>{profile === "open" ? <PopupManagerPlayer /> : null}</>
      ) : null}
      <BetsLipContainer
        betsLipPage={betsLipPage}
        betsLipOpen={betsLipOpen}
        setBetsLipOpen={setBetsLipOpen}
      />
    </div>
  );
};

export { Layout };
