import React from "react";
import { BetSlipModal } from "../../betsLip/betSlipModal/BetSlipModal";
import { BetsLipSport } from "../../betsLip/betsLipSport/BetsLipSport";
import "./BetsLipContainer.scss";

interface IBetsLipContainer {
  betsLipPage: string;
  betsLipOpen?: boolean;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BetsLipContainer = ({
  betsLipPage,
  betsLipOpen,
  setBetsLipOpen,
}: IBetsLipContainer) => {
  return (
    <div className="layout-betslip-holder-bc">
      {betsLipPage === "sport" ? (
        <BetsLipSport
        />
      ) : (
        <>
          {betsLipOpen ? (
            <BetSlipModal
              setBetsLipOpen={setBetsLipOpen}
              betsLipOpen={betsLipOpen}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export { BetsLipContainer };
