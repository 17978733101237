import { t } from "i18next";
import React from "react";
import "./New.scss";

const New = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.New")}</div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content message-content"
        data-scroll-lock-scrollable=""
      >
        <form noValidate={false}>
          <div className="u-i-p-control-item-holder-bc">
            <div className="form-control-bc default ">
              <label className="form-control-label-bc inputs">
                <input
                  type="text"
                  className="form-control-input-bc"
                  name="subject"
                  step={0}
                  defaultValue=""
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Subject")}
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-control-item-holder-bc">
            <div className="form-control-bc default ">
              <label className="form-control-label-bc textareas">
                <div className="form-control-textarea-bc">
                  <textarea
                    placeholder="Enter text here"
                    className="form-control-textarea"
                    name="body"
                    maxLength={4000}
                    defaultValue={""}
                  />
                </div>
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Message")}
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-c-footer-bc">
            <button
              className="btn a-color "
              type="submit"
              title="Submit"
              disabled={true}
            >
              <span>{t("admin.Submit")}</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export { New };
