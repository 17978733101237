import React from "react";
import JackpotBanner from "../../banner/JackpotBanner";

import "./JackpotBannerMain.scss";

const JackpotBannerMain = () => {
  return (
    <div className="hm-row-bc">
      <JackpotBanner
        title={"Sportsbook jackpot"}
        amount={681761.49}
      ></JackpotBanner>
      {/*<JackpotBanner title={'AMUSNET INTERACTIVE'} amount={637761.49}></JackpotBanner>*/}
    </div>
  );
};

export { JackpotBannerMain };
