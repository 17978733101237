import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import "./LoyaltyPoints.scss";

const LoyaltyPoints = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.LoyaltyPoints")}</div>
      <div className="loyaltyPointsWrapper">
        <div className="loyaltyPointsCurrentPlanBlock">
          <div className="loyaltyPointsCurrentPlanBlock-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/loyalty_points/bronze.png"
              className="loyaltyPointsCurrentPlanMedal-bc"
              alt=""
            />
            <div className="loyaltyPoints-content">
              <div className="loyaltyPoints-title-block">
                <h3 className="loyaltyPointsCurrentPlanTitle-bc ellipsis">
                  {t("admin.BRONZE")}
                </h3>
                <span
                  className="loyaltyPointsCurrentPlanLabel-bc"
                  style={{ color: "rgb(163, 144, 111)" }}
                >
                  {t("admin.Yourcurrentplan")}
                </span>
              </div>
              <div className="loyaltyPoints-item">
                <div className="loyalty-status-block">
                  <div className="loyalty-status-block-left">
                    <ul className="loyalty-status">
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-main-info">
                          <span className="loyalty-status-title ellipsis">
                          {t("admin.Nextstatusupdate")}
                          </span>
                          <i className="loyalty-status-info-icon bc-i-player-info" />
                        </div>
                        <div className="loyalty-status-point">{t("admin.BRONZE")}</div>
                      </li>
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-period ellipsis">
                        {t("admin.In")} 0 {t("admin.days")}
                        </div>
                        <div className="loyalty-status-point-small" />
                      </li>
                    </ul>
                    <ul className="loyalty-status">
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-main-info">
                          <span className="loyalty-status-title ellipsis">
                            Total points
                          </span>
                          <i className="loyalty-status-info-icon bc-i-player-info" />
                        </div>
                        <div className="loyalty-status-point">0</div>
                      </li>
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-period ellipsis">
                          Pack Minimum
                        </div>
                        <div className="loyalty-status-point-small">0+</div>
                      </li>
                    </ul>
                  </div>
                  <div className="loyalty-status-block-right">
                    <ul className="loyalty-status">
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-main-info">
                          <span className="loyalty-status-title ellipsis">
                            All time
                          </span>
                          <i className="loyalty-status-info-icon bc-i-player-info" />
                        </div>
                        <div className="loyalty-status-point">0</div>
                      </li>
                    </ul>
                    <ul className="loyalty-status">
                      <li className="loyalty-status-row">
                        <div className="loyalty-status-main-info">
                          <span className="loyalty-status-title ellipsis">
                            Exchangable
                          </span>
                          <i className="loyalty-status-info-icon bc-i-player-info" />
                        </div>
                        <div className="loyalty-status-point">0</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="loyaltyButtons-bc">
                  <Link
                    className="btn a-color s-small ellipsis"
                    to={{
                      search:
                        "/?profile=open&account=bonuses&page=exchange-shop",
                    }}
                  >
                    <span>Exchange Shop</span>
                  </Link>
                  <button
                    className="btn a-outline s-small ellipsis "
                    type="button"
                    title="Exchange"
                  >
                    <span>Exchange</span>
                  </button>
                  <Link
                    className="btn a-outline s-small ellipsis"
                    to={{
                      search:
                        "/?profile=open&account=bonuses&page=exchange-shop-history",
                    }}
                  >
                    <span>Exchange Shop History</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="loyaltyPointsCurrentPlanProgressBlockInner-bc"
            style={{ color: "rgb(163, 144, 111)" }}
          >
            <div className="loyaltyPointsCurrentPlanProgressBar-bc">
              <span
                className="progress-bar loyalProgressBarStick-bc"
                style={{ width: "0%" }}
              />
              <div
                className="loyalProgressBarNumPar-bc"
                style={{ background: "rgb(163, 144, 111)" }}
              >
                <span>0</span>
              </div>
            </div>
            <div className="loyalty-points-progress-numbers">
              <span>0</span>
              <span>3592421</span>
            </div>
          </div>
        </div>
        <div className="loyaltyMedalSlider-bc" data-scroll-lock-scrollable="">
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/loyalty_points/bronze.png"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">BRONZE</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(163, 144, 111)" }}
              >
                0+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/loyalty_points/silver.png"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">SILVER</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(194, 197, 205)" }}
              >
                5001+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/loyalty_points/gold.png"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">GOLD</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(185, 149, 82)" }}
              >
                25001+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/loyalty_points/diamond.png"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">DIAMOND</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(17, 161, 194)" }}
              >
                87771+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/aa222957583ff591fd2c7c2c88925618_1873892_loyalty_point.webp"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">PLATINUM</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(211, 208, 207)" }}
              >
                259242+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/e14f41c2b4c36926318f5c9b63b98b8d_1873892_loyalty_point.webp"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">STELLAR</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                877726+/Month
              </span>
            </div>
          </div>
          <div className="loyaltyMedalSliderElBody-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/bf878fbcc863a0d1937e177854509d5f_1873892_loyalty_point.webp"
              className="loyaltySliderImage-bc"
              alt=""
            />
            <div className="loyaltyPoints-title-block">
              <h3 className="loyaltySliderTitle-bc ellipsis">ELITE</h3>
              <span
                className="loyaltyNum-bc ellipsis"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                3592421+/Month
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { LoyaltyPoints };
