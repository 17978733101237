import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InfoBlock } from "./infoBlock/InfoBlock";
import "./PopupManagerPlayer.scss";
import { SideBar } from "./sideBar/SideBar";

const PopupManagerPlayer = () => {
  const wrapperRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const profile = searchParams.get("profile") || "";
  const navigate = useNavigate();

  const handleCloseWindow = () => {
    navigate("", {
      replace: true,
    });
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="popup-holder-bc windowed  user-profile-container ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc" ref={wrapperRef}>
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleCloseWindow}
          />
          <div className="u-i-p-c-body-bc">
            <SideBar />
            <InfoBlock />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PopupManagerPlayer };
