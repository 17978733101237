import { Ilanguage } from "../../reducers/settingsReducer";

type setLanguage = {
  type: string;
  payload: Ilanguage;
};

function setLanguage(payload: Ilanguage): setLanguage {
  return {
    type: "SET_LANGUAGE",
    payload,
  };
}

export { setLanguage };
