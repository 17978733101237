import { t } from "i18next";
import React, { useState } from "react";
import "./PromoCode.scss";

const PromoCode = () => {
  const [inputfocused, setInputFocused] = useState(false);

  const handleFocus = () => {
    setInputFocused(true);
  };
  const handleBlur = () => {
    setInputFocused(false);
  };
  return (
    <div className="promoCodeWrapper-bc profile-panel-promo-code">
      <form>
        <div className="u-i-p-control-item-holder-bc">
          <div
            className={`form-control-bc default ${
              inputfocused ? "focused" : ""
            }`}
          >
            <label className="form-control-label-bc inputs">
              <input
                type="text"
                className="form-control-input-bc"
                name="promoCode"
                step={0}
                defaultValue=""
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc ellipsis">{t("admin.Promocode")}</span>
            </label>
          </div>
        </div>
        <div className="u-i-p-c-footer-bc">
          <button
            className="btn a-color big-btn button-custom"
            type="submit"
            title="Apply"
            disabled={true}
          >
            <span>{t("admin.Apply")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export { PromoCode };
