import { t } from "i18next";
import React, { useState } from "react";
import "./SportName.scss";

const SportName = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputFocus = () => {
    setInputFocused(true);
  };

  const inputBlur = () => {
    setInputFocused(false);
  };

  return (
    <div className="u-i-p-control-item-holder-bc">
      <div
        className={`form-control-bc default ${inputFocused ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            value={inputValue}
            type="text"
            className="form-control-input-bc"
            name="name"
            step={0}
            defaultValue=""
            onFocus={inputFocus}
            onBlur={inputBlur}
            onChange={handleChangeValue}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">{t("admin.SportName")}</span>
        </label>
      </div>
      <i className="sport-search-icon bc-i-search" />
    </div>
  );
};

export { SportName };
