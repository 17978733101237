import { t } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../../../../common/types/Auth/changePassword";
import { AuthService } from "../../../../../../../services";
import { setAuth } from "../../../../../../../store/action/auth/setauth";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<changePassword>();
  const dispatch = useDispatch();
  const password = watch("password");
  const [currentPasswordFocus, setCurrentPasswordFocus] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [newPasswordConfirmFocus, setNewPasswordConfirFocus] = useState(false);
  const validateCurrentPassword = (currentPassword: string) => {
    if (currentPassword === password) {
      return true;
    }
  };

  const onSubmit = (data: changePassword) => {
    async function setChangePassword() {
      try {
        await AuthService.changePassword({
          current_password: data.current_password,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });
        dispatch(setAuth("unauth"));
        localStorage.removeItem("token");
      } catch (error) {
        console.error(error);
      }
    }
    setChangePassword();
  };
  return (
    <>
      <div className="overlay-header">{t("admin.ChangePassword")}</div>
      <div className="u-i-edit-change-password-page-bc">
        <div className="u-i-e-ch-p-content-bc u-i-common-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc ${
                  currentPasswordFocus ? "focused" : ""
                } default has-icon `}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type="password"
                    className="form-control-input-bc"
                    autoComplete="current-password"
                    {...register("current_password", {
                      required: true,
                    })}
                    onFocus={() => setCurrentPasswordFocus(true)}
                    onBlur={() => setCurrentPasswordFocus(false)}
                  />
                  <i className="form-control-input-stroke-bc" />
                  <span className="form-control-title-bc ellipsis">
                    {t("admin.Currentpassword")}
                  </span>
                </label>
              </div>
            </div>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc ${
                  newPasswordFocus ? "focused" : ""
                } default has-icon `}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type="password"
                    className="form-control-input-bc"
                    autoComplete="current-password"
                    {...register("password", {
                      required: true,
                    })}
                    onFocus={() => setNewPasswordFocus(true)}
                    onBlur={() => setNewPasswordFocus(false)}
                  />
                  <i className="form-control-input-stroke-bc" />
                  <span className="form-control-title-bc ellipsis">
                    {t("admin.Newpassword")}
                  </span>
                </label>
              </div>
            </div>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc ${
                  newPasswordConfirmFocus ? "focused" : ""
                } default has-icon `}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type="password"
                    className="form-control-input-bc"
                    autoComplete="current-password"
                    {...register("password_confirmation", {
                      required: true,
                      validate: validateCurrentPassword,
                    })}
                    onFocus={() => setNewPasswordConfirFocus(true)}
                    onBlur={() => setNewPasswordConfirFocus(false)}
                  />
                  <i className="form-control-input-stroke-bc" />
                  <span className="form-control-title-bc ellipsis">
                    {t("admin.Confirmnewpassword")}
                  </span>
                </label>
              </div>
            </div>
            <div className="u-i-p-c-footer-bc">
              <button
                className="btn a-color right-aligned "
                type="submit"
                title="Change Password"
                disabled={false}
              >
                <span>{t("admin.ChangePassword")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { ChangePassword };
