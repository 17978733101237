import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";
import { Layout } from "../../components/layout";
import { Carousel } from "../../components/liveCasino/carousel/Carousel";
import { GamePreloader } from "../../components/liveCasino/gamesList/components/preloader/GamePreloader";
import { GamesList } from "../../components/liveCasino/gamesList/GamesList";
import { LiveCasinoProviders } from "../../components/liveCasino/liveCasinoProviders/LiveCasinoProviders";
import { useLiveCasinoGames } from "../../hooks/useLiveCasinoGames";

const LiveCasino = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);

  const [providersList, setProvidersList] = useState({});
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [selectedProvidersUrl, setSelectedProvidersUrl] = useState("");

  const [casinoGamesList, setCasinoGamesList] = useState<
    CasinoIconGameType[] | []
  >([]);

  const [seacrhGames, setSeacrhGames] = useState("");
  const page = useRef(1);

  useLiveCasinoGames({
    selectedProviders,
    selectedProvidersUrl,
    seacrhGames,
    page,
    loadStop,
    setLoadingGames,
    setLoadingGamesAdd,
    setProvidersList,
    setSelectedProvidersUrl,
    setCasinoGamesList,
    setLoadStop,
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <Layout
      addClasses={`
      page-full-size live-casino-games compact-footer ${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } ${panelOnep ? "smart-panel-is-visible" : ""}`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus={"livecasino"}
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <div className="live-casino-block">
        {loading ? null : <Carousel />}
        <LiveCasinoProviders
          providersList={providersList}
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
          seacrhGames={seacrhGames}
          setSeacrhGames={setSeacrhGames}
        />
        {loadingGames ? (
          <GamePreloader />
        ) : (
          <GamesList
            casinoGamesList={casinoGamesList}
            loadingGamesAdd={loadingGamesAdd}
          />
        )}
      </div>
    </Layout>
  );
};

export { LiveCasino };
