type setShowRedirectIfnoType = {
  type: string;
  payload: boolean;
};

function setShowRedirectIfno(payload: boolean): setShowRedirectIfnoType {
  return {
    type: "CHANGE_REDIRECT_INFO",
    payload,
  };
}

export { setShowRedirectIfno };
