import React, { useState } from "react";
import "./Carousel.scss";
import { CarouselItem, liveCasinoItem } from "./carouselUi/CarouselItem";

const dataLength = 4;

const carouselItems: liveCasinoItem[] = [
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_a32fb3bbfcdf7ee43d64826379aa6ff0.webp?v=1",
    name: "%10 ek",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_8bd4b1dd8d46ea10cd744d8898d7c0e2.webp?v=2",
    name: "10",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_234014d647e8a63e0a57de72e8e6cb82.webp?v=3",
    name: "Drop",
  },
  {
    img: "https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_5ab4d2a32ce34653d8d521589c567990.webp?v=3",
    name: "Sınırsız fs",
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const next = () => {
    if (currentIndex < dataLength - 1) {
      setCurrentIndex((prevState) => prevState + 1);
      setCurrentPosition((currentIndex + 1) * 100);
    } else {
      setCurrentIndex(() => 0);
      setCurrentPosition(0);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setCurrentPosition((currentIndex + 1) * 100);
    } else {
      setCurrentIndex(() => dataLength - 1);
      setCurrentPosition(0);
    }
  };
  return (
    <div
      className="hm-row-bc has-slider"
      style={{ gridTemplateColumns: "12fr" }}
    >
      <div className="slider-bc  ">
        <div className="carousel">
          <div className="carousel-viewport">
            <div
              className="carousel-container carousel-custom-move"
              style={{
                transform: `translate3d(-${currentPosition}%, 0px, 0px)`,
              }}
            >
              {carouselItems.map((item) => {
                return <CarouselItem liveCasinoItem={item} key={item.img} />;
              })}
            </div>
          </div>
          <div className="carousel-count-arrow-container with-count">
            <i
              className="carousel-arrow-left bc-i-small-arrow-left "
              onClick={prev}
            />
            <div className="carousel-count-arrow">
              {currentIndex + 1} / {dataLength}
            </div>
            <i
              className="carousel-arrow-right bc-i-small-arrow-right "
              onClick={next}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Carousel };
