import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { CasinoInfo } from "../../../services";
import { GameItem } from "../../liveCasino/gamesList/components/GameItem/GameItem";

import "./MainGamesList.scss";

const MainGamesList = () => {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  useEffect(() => {
    async function getCasinoGames() {
      const res = await CasinoInfo.games(
        `/6?current_page=1&limit=5&page=2&is_mobile=true`
      );
      setCasinoGames(res.data.data);
    }
    getCasinoGames();
  }, []);
  return (
    <div className="hm-row-bc ">
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis">{t("navHeader.Games")}</h3>
        <Link className="view-see-more" to={`/${language}/casino/slots/`}>
          {t("navHeader.More")}
          <i className="view-arrow-more bc-i-small-arrow-right"></i>
        </Link>
      </div>
      <div className="games-horiz ">
        <div className="games-horiz-scroll" data-scroll-lock-scrollable="">
          {casinoGames.map((item) => {
            return <GameItem key={item.id} gameItem={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export { MainGamesList };
