import React, { useEffect, useRef, useState } from "react";
import { MockCategoryData } from "./MockCategoryData";
import "./Category.scss";
import { t } from "i18next";

export interface IMockCategoryData {
  name: string;
  dataId: number;
}

const Category = () => {
  const wrapperRef = useRef(null);

  const [selectedType, setSelectedType] = useState(MockCategoryData[0].name);
  const [selectModalOpen, setSelectModalOpen] = useState(false);

  const handleTakeSelectedType = (item: string) => {
    setSelectedType(item);
    setSelectModalOpen(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setSelectModalOpen(false);
    }
  };

  const handleOpenInput = () => {
    setSelectModalOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  
  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc ">
        <div
          className={`form-control-bc ${
            selectModalOpen ? "focused" : ""
          } valid filled`}
        >
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenInput}
          >
            <div className="form-control-select-bc ellipsis">
              {selectedType}
            </div>
            <i className="form-control-icon-bc bc-i-small-arrow-down" />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">{t("admin.Category")}</span>
          </div>
          {selectModalOpen ? (
            <div className="multi-select-label-bc">
              {MockCategoryData.map((item) => {
                return (
                  <label
                    className="checkbox-control-content-bc  "
                    data-option-value={item.dataId}
                    key={item.dataId}
                    onClick={() => handleTakeSelectedType(item.name)}
                  >
                    <p
                      className="checkbox-control-text-bc"
                      style={{ pointerEvents: "none" }}
                    >
                      {item.name}
                    </p>
                  </label>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Category };
