import React from "react";
import { CasinoGameType } from "../../../common/types/Casino/CasinoGameType";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { GameItem } from "../../liveCasino/gamesList/components/GameItem/GameItem";
import "./JackpotList.scss";

interface IJackpotList {
  casinoGamesList: CasinoIconGameType[] | [];
  loadingGamesAdd: boolean;
}

const JackpotList = ({
  casinoGamesList,
  loadingGamesAdd,
}:IJackpotList) => {
  return (
    <div
      className="casino-category-types casino-category-types-full"
      id="casino_games_container"
    >
      <div className="infinite-scroll-component__outerdiv">
        <div
          className="infinite-scroll-component casino-category-games"
          style={{ height: "auto", overflow: "auto" }}
        >
          {casinoGamesList.map((item) => {
            return <GameItem gameItem={item} key={item.id}/>;
          })}
          {loadingGamesAdd
            ? Array.from({ length: 25 }).map((_, index) => (
                <div
                  key={index}
                  className="casino-game-item skeleton-loader-game-cube"
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export { JackpotList };
