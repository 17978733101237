import React from "react";
import "./TournamentBlockPreloader.scss";

const TournamentBlockPreloader = () => {
  return (
    <div className="casino-info-block">
      <div className="casino-tournament-header">
        <i className="casino-tournament-header-icon bc-i-tournament" />
        <span className="casino-tournament-header-title ellipsis">
          Tournaments
        </span>
      </div>
      <ul className="casino-info-content casino-info-content-tournament">
        <li>
          <span className="casino-tournament-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-tournament-img skeleton-loader-full-banner" />
        </li>
        <li>
          <span className="casino-tournament-img skeleton-loader-full-banner" />
        </li>
      </ul>
    </div>
  );
};

export { TournamentBlockPreloader };
