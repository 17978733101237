import React, { useEffect, useState } from "react";
import { CasinoGameType } from "../../../../common/types/Casino/CasinoGameType";
import { FavoritCasinoResponceType } from "../../../../common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "../../../../common/types/Casino/FavoritCasinoStateType";
import { usePlayerLeftWindow } from "../../../../hooks/usePlayerLeftWindow";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";

import "./LeftWindowPlay.scss";

interface ILeftWindowPlay {
  gamesSelected: CasinoGameType[];
  openGames: string[];
  favoritsGamesUser: FavoritCasinoResponceType[] | [];
  favoritsGamesLocal: FavoritCasinoStateType[] | [];
  casinoLink: string[];
  windowWidth: number;
  openWindows: number;
}

const LeftWindowPlay = ({
  gamesSelected,
  favoritsGamesUser,
  favoritsGamesLocal,
  casinoLink,
  openGames,
  windowWidth,
  openWindows,
}: ILeftWindowPlay) => {
  

  const { handleFavoriteUserSelect, handleFavoriteLocalSelect, closePlayer } =
    usePlayerLeftWindow({
      gamesSelected: gamesSelected,
      openGames: openGames,
      favoritsGamesUser: favoritsGamesUser,
      favoritsGamesLocal: favoritsGamesLocal,
    });
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  
  const [switcherActive, setSwitcherActive] = useState(role === "player");

  const [iframeKey, setIframeKey] = useState(1);
  const handlUpdateIframe = () => {
    setIframeKey((prev) => prev + 1);
  };

  const handleSwitcherMove = () => {
    setSwitcherActive((prev) => !prev);
    handlUpdateIframe();
  };

  useEffect(() => {
    if (role === "player") {
      setSwitcherActive(true);
    } else {
      setSwitcherActive(false);
    }
  }, [role]);
  return (
    <div
      className="casino-full-game-block"
      style={
        openWindows === 1
          ? { width: windowWidth - 120, height: windowWidth * 0.4 }
          : { width: (windowWidth - 120) / 2, height: windowWidth / 3 / 2 }
      }
    >
      <div className="casino-full-game-panel">
        <i
          title="KAPAT"
          className="casino-full-game-panel-element bc-i-close-remove"
          onClick={closePlayer}
        />
        <i
          title="UYGUN PENCEREDE AÇ"
          className="casino-full-game-panel-element bc-i-multiview"
        />
        <i
          title="YENİLE"
          className="casino-full-game-panel-element bc-i-refresh"
          onClick={handlUpdateIframe}
        />
        {role === "player" ? (
          <i
            title="FAVORİ OYUNLARA EKLE"
            className={`casino-full-game-panel-element ${
              favoritsGamesUser.some((obj) => obj?.id === gamesSelected[0]?.id)
                ? "bc-i-star"
                : "bc-i-favorite"
            } `}
            onClick={handleFavoriteUserSelect}
          />
        ) : (
          <i
            title="FAVORİ OYUNLARA EKLE"
            className={`casino-full-game-panel-element ${
              favoritsGamesLocal.some((obj) => obj?.id === gamesSelected[0]?.id)
                ? "bc-i-star"
                : "bc-i-favorite"
            } `}
            onClick={handleFavoriteLocalSelect}
          />
        )}

        <span
          title="GERÇEK MODA GEÇMEK İÇİN TIKLA"
          className={`casino-full-game-panel-element casino-full-game-switcher ${
            switcherActive ? "active" : ""
          }`}
          onClick={handleSwitcherMove}
        />
      </div>
      <iframe
        allowFullScreen={true}
        allow="autoplay; microphone *"
        src={casinoLink[0]}
        key={iframeKey}
      />
    </div>
  );
};

export { LeftWindowPlay };
