import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Inbox.scss";
import { Message } from "./message/Message";

const Inbox = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      message: "askmfa;sdnfa;sdjnfasd",
      subject: "Omar",
      date: new Date("Thu May 26 2023 20:46:05 GMT+0600 (Восточный Казахстан)"),
    },
    {
      id: 2,
      message: "askmfa;sdasdavaKSKAKSKSKSKSKSKnfa;sdjnfasd",
      subject: "Tokav",
      date: new Date("Thu May 25 2023 20:46:05 GMT+0600 (Восточный Казахстан)"),
    },
  ]);
  return (
    <>
      <div className="overlay-header">{t("admin.Inbox")}</div>
      <div className="messageBoxWrapper-bc">
        {messages.length > 0 ? (
          <ul className="messageBoxList-bc">
            {messages.map((item) => {
              return (
                <Message
                  key={item.id}
                  message={item.message}
                  subject={item.subject}
                  date={item.date}
                />
              );
            })}
          </ul>
        ) : (
          <p className="empty-b-text-v-bc">
            {t("admin.Youdonothaveanymessages")}
          </p>
        )}

        <Link
          className="btn a-color"
          to={{
            search: "/?profile=open&account=messages&page=new",
          }}
        >
          {t("admin.Newmessage")}
        </Link>
      </div>
    </>
  );
};

export { Inbox };
