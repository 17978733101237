import React from "react";

interface ICarousel {
    children: React.ReactNode | React.ReactNode[];
}
export const ProductBanner = ({ children }: ICarousel) => {
    return (
        <div className="product-banner-container-bc col-4 product-banner-without-titles">
            {children}
        </div>
    );

};

export default ProductBanner;