type HeaderDataType = {
  status: string;
  outSideLink: boolean;
  path: string;
  name: string;
  additionalLinksStatus: boolean;
  icon?: string;
};

const HeaderData: HeaderDataType[] = [
  {
    status: "live",
    outSideLink: false,
    path: "/sports/live/event-view/",
    name: "navHeader.Live",
    additionalLinksStatus: false,
  },
  {
    status: "sport",
    outSideLink: false,
    path: "/sports/pre-match/event-view",
    name: "navHeader.Sport",
    additionalLinksStatus: true,
  },
  {
    status: "casino",
    outSideLink: false,
    path: "/casino/slots/-1",
    name: "navHeader.Casino",
    additionalLinksStatus: false,
  },
  {
    status: "aviator",
    outSideLink: false,
    path: "/casino/game-view/806666",
    name: "navHeader.AVIATOR",
    additionalLinksStatus: false,
  },
  {
    status: "spaceman",
    outSideLink: false,
    path: "/casino/game-view/400043028",
    name: "navHeader.SPACEMAN",
    additionalLinksStatus: false,
  },
  {
    status: "zeplin",
    outSideLink: false,
    path: "/casino/game-view/9010",
    name: "navHeader.ZEPLIN",
    additionalLinksStatus: false,
  },
  {
    status: "livecasino",
    outSideLink: false,
    path: "/live-casino/home/-1/All",
    name: "navHeader.LiveCasino",
    additionalLinksStatus: false,
  },
  {
    status: "maltbahiscarc",
    outSideLink: false,
    path: "/wonderwheel",
    name: "navHeader.MALTBAHISCARK",
    additionalLinksStatus: false,
    icon: "bc-i-wonderwheel",
  },
  {
    status: "jackpot",
    outSideLink: false,
    path: "/casino/jackpot",
    name: "navHeader.Jackpot",
    additionalLinksStatus: false,
  },
  {
    status: "none",
    outSideLink: true,
    path: "https://www.to-harmony.com",
    name: "navHeader.TOHARMONY",
    additionalLinksStatus: false,
  },
  {
    status: "tournaments",
    outSideLink: false,
    path: "/casino/tournaments",
    name: "navHeader.Tournaments",
    additionalLinksStatus: false,
  },
  {
    status: "promotions",
    outSideLink: false,
    path: "/promotions/all",
    name: "navHeader.Promotions",
    additionalLinksStatus: false,
  },
  {
    status: "games",
    outSideLink: false,
    path: "/games",
    name: "navHeader.Games",
    additionalLinksStatus: false,
  },
  {
    status: "canitakvim",
    outSideLink: false,
    path: "/sports/live-calendar",
    name: "navHeader.CANLITAKVIM",
    additionalLinksStatus: false,
  },
  {
    status: "none",
    outSideLink: true,
    path: "https://statistics.bcapps.org/#/tr",
    name: "navHeader.Statistics",
    additionalLinksStatus: false,
  },
  {
    status: "bahisrurnuvalari",
    outSideLink: false,
    path: "/sports/tournaments",
    name: "navHeader.BAHISTURNUVALARI",
    additionalLinksStatus: false,
  },
];

export { HeaderData };
