import { t } from "i18next";
import React, {useEffect, useRef, useState} from "react";
import "./LevelList.scss";
import Odometer from "odometer";

const LevelList = () => {
  const [jackpools] = useState([
    {
      title: t("jackpot.Hearts"),
      amount: 15040.71,
      ref: useRef(null),
      odometer: null,
      listItems: [
        {
          time: '17.04.2023, 20:34',
          amount: '46,013.29',
          title: t("jackpot.LargestWinner"),
          id: '',
        },
        {
          time: '30.04.2023, 05:19',
          amount: '25,882.23',
          title: t("jackpot.LastWinner"),
          id: '',
        },
        {
          amount: '156',
          title: t("jackpot.NumberOfWinners"),
        },
      ]
    },
    {
      title: t("jackpot.Diamonds"),
      amount: 15040.71,
      ref: useRef(null),
      odometer: null,
      listItems: [
        {
          time: '17.04.2023, 20:34',
          amount: '46,013.29',
          title: t("jackpot.LargestWinner"),
          id: '',
        },
        {
          time: '30.04.2023, 05:19',
          amount: '25,882.23',
          title: t("jackpot.LastWinner"),
          id: '',
        },
        {
          amount: '156',
          title: t("jackpot.NumberOfWinners"),
        },
      ]
    },
    {
      title: t("jackpot.Clubs"),
      amount: 15040.71,
      ref: useRef(null),
      odometer: null,
      listItems: [
        {
          time: '17.04.2023, 20:34',
          amount: '46,013.29',
          title: t("jackpot.LargestWinner"),
          id: '',
        },
        {
          time: '30.04.2023, 05:19',
          amount: '25,882.23',
          title: t("jackpot.LastWinner"),
          id: '',
        },
        {
          amount: '156',
          title: t("jackpot.NumberOfWinners"),
        },
      ]
    },
  ]);

  useEffect(() => {
    jackpools.forEach((item) => {
      item.odometer = new Odometer({
        el: item.ref.current,
        value: item.amount,   // initial value
        format: "(,ddd).dd",
        theme: "default"
      });
    })
  }, [])

  useEffect(() => {
    const timersR: NodeJS.Timer[] = [];
    jackpools.forEach((item, index) => {
      timersR.push(
          setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item.odometer.update(item.amount + Math.floor(Math.random() * (55 - 15 + 1) + 15))
          }, 5000)
      );
    })

    // clearing interval
    return () => timersR.forEach((timer) => clearInterval(timer));
  }, [])

  return (
    <div className="casinoJackpotLevelList-bc">
      {jackpools.map(function(item, index){
        return <ul key={index} className={`jackpot-pool-wrapper ${'active'}`}>
          <li className="jackpot-pool-item">
            <i className="jackpot-pool-icon bc-i-undefined-jackpot" />
            <div className="jackpot-pool-money">
              <span className="jackpot-pool-title">{item.title}</span>
              <div ref={item.ref} id="odometer" className="odometer jackpot-pool-price odometer-auto-theme" data-cur="₺"></div>
            </div>
          </li>
          <li className="jackpot-pool-list">
            {item.listItems.map(function(item, index) {

              return <div key={index} className="jackpot-pool-list-item">
                <h5 className="jackpot-pool-status">
                  {item.title}
                </h5>
                {(item.time || item.id) &&
                    <div className="jackpot-pool-info">
                      {item.id && <span>ID: </span>}
                      {item.time && <time className="">{item.time}</time>}
                    </div>
                }
                <span className="jackpot-pool-price">{item.amount}</span>
                {index < 2 && <span className="jackpot-pool-price" />}
              </div>
            })
            }
          </li>
        </ul>;
      })}
    </div>
  );
};

export { LevelList };
