import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { LastWinnerType } from "../../../../../common/types/Casino/LastWinnerType";
import { CasinoInfo } from "../../../../../services";
import { LastWiinerCart } from "./WinnerBlcokUi/LastWiinerCart/LastWiinerCart";
import { WinnerCart } from "./WinnerBlcokUi/WinnerCart/WinnerCart";
import "./WinnerBlock.scss";

export interface winners {
  img: string;
  alt: string;
  name: string;
  title: string;
  count: number;
}

const topWinners: winners[] = [
  {
    img: "https://cmsbetconstruct.com/content/images/casino/icon2/168792c819b73e35a0c247120affc3bd_casinoGameIcon2.jpg?v=6",
    alt: "Hit the Gold!: Hold and Win",
    name: "667***4462",
    title: "Hit the Gold!: Hold and Win",
    count: 25,
  },
  {
    img: "https://cmsbetconstruct.com/content/images/casino/icon2/150b3a5da28233e5e38cc473baa8e325_casinoGameIcon2.jpg?v=4",
    alt: "Gates of Olympus™",
    name: "395***3551",
    title: "Gates of Olympus™",
    count: 25.2,
  },
  {
    img: "https://cmsbetconstruct.com/content/images/casino/icon2/150b3a5da28233e5e38cc473baa8e325_casinoGameIcon2.jpg?v=5",
    alt: "Gates of Olympus™",
    name: "395***3551",
    title: "Gates of Olympus™",
    count: 25.2,
  },
];

const WinnerBlock = () => {
  const [whichWinner, setWhichWinner] = useState("last");
  const [lastWinners, setLastWinners] = useState<LastWinnerType[]>([]);

  const handleChangeWinner = (item: string) => {
    setWhichWinner(item);
  };

  useEffect(() => {
    async function getLastWinners() {
      const res = await CasinoInfo.lastWinners();
      setLastWinners(res.data.casino);
    }
    getLastWinners();
  }, []);
  return (
    <div className="casino-info-block">
      <div className="main-tabs-bc">
        <div
          className={`tab-bc selected-underline ${
            whichWinner === "last" ? "active" : ""
          }`}
          title=""
          onClick={() => handleChangeWinner("last")}
        >
          <span>{t("casino.LastWinners")}</span>
        </div>
        <div
          className={`tab-bc selected-underline ${
            whichWinner === "top" ? "active" : ""
          }`}
          title=""
          onClick={() => handleChangeWinner("top")}
        >
          <span>{t("casino.TopWinners")}</span>
        </div>
      </div>
      <ul className="casino-info-content casino-info-content-winners">
        {whichWinner === "top" ? (
          <>
            {topWinners.map((item) => (
              <WinnerCart key={item.img} winner={item} />
            ))}
          </>
        ) : (
          <>
            {lastWinners?.map((item) => (
              <LastWiinerCart key={item?.created_at} winner={item} />
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export { WinnerBlock };
