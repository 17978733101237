import { combineReducers } from "redux";
import { accountInfoReducer, stateUser } from "./accountInfoReducer";
import { favoritsReducer, favoritsReducerType } from "./favoritsReducer";
import { modalsReducer, modalsReducerType } from "./modalsReducer";
import { settingsReducer, settingsState } from "./settingsReducer";

type RootState = {
  accountInfoReducer: stateUser;
  settingsReducer: settingsState;
  modalsReducer: modalsReducerType;
  favoritsReducer: favoritsReducerType
};

const rootReducer = combineReducers({
  accountInfoReducer,
  settingsReducer,
  modalsReducer,
  favoritsReducer
});

export { rootReducer };

export type { RootState };
