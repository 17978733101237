import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./InputGender.scss";

interface IInputGender {
  selectedGender: number;
  setSelectedGender: React.Dispatch<React.SetStateAction<number>>;
}

const InputGender = ({ selectedGender, setSelectedGender }: IInputGender) => {
  const [modalOpen, setModalOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleChooseGender = (item: number) => {
    setSelectedGender(item);
    setModalOpen(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const handleCloseModal = () => {
    setModalOpen((prev) => !prev);
  };
  return (
    <div className="entrance-f-item-bc">
      <div className="multi-select-bc ">
        <div className="form-control-bc focused" ref={wrapperRef}>
          <div
            className="form-control-label-bc inputs"
            onClick={handleCloseModal}
          >
            <div className="form-control-select-bc ellipsis">
              {selectedGender === 0 ? "Choose gender" : null}
              {selectedGender === 1 ? "Male" : null}
              {selectedGender === 2 ? "Female" : null}
            </div>
            <i
              className={`form-control-icon-bc bc-i-small-arrow-${
                modalOpen ? "up" : "down"
              }`}
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              {t("admin.Gender")}
            </span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  selectedGender === 1 ? "active" : ""
                }`}
                data-option-value="M"
                onClick={() => handleChooseGender(1)}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Male")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  selectedGender === 2 ? "active" : ""
                }`}
                data-option-value="F"
                onClick={() => handleChooseGender(2)}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Female")}
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { InputGender };
