type IsetTimeFormat = {
  type: string;
  payload: string;
};

function setTimeFormat(payload: string): IsetTimeFormat {
  return {
    type: "SET_TIME_FORMAT",
    payload,
  };
}

export { setTimeFormat };
