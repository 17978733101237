import { t } from "i18next";
import React, { useState } from "react";
import "./JackpotsSearch.scss";

interface IJackpotsSearch {
  seacrhGames: string
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>
}

const JackpotsSearch = ({seacrhGames, setSeacrhGames}:IJackpotsSearch) => {
  const [statusOpen, setStatusOpen] = useState(false);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeacrhGames(e.target.value);
  };

  const handleOpen = () => {
    setStatusOpen((prev) => !prev);
  };
  
  return (
    <div className="casino-jackpot-search casino-title-search">
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis custom-font-size">{t("casino.Games")}</h3>
      </div>
      <div className={`sport-search-bc ${statusOpen ? "active" : ""}`}>
        {statusOpen ? (
          <input
            type="text"
            className="ss-input-bc"
            placeholder="Search casino game"
            value={seacrhGames}
            onChange={handleChangeValue}
          />
        ) : null}

        <div className="ss-icon-holder-bc" onClick={handleOpen}>
          <i
            className={`ss-icon-bc bc-i-${
              statusOpen ? "close-remove" : "search"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export { JackpotsSearch };
