import React, { useEffect, useRef, useState } from "react";
import "./SelectTypeId.scss";

const SelectTypeId = () => {
  const [typeId, setTypeId] = useState("Bank Slip");
  const [modalOpen, setModalOpen] = useState(false);

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  const handleOpenInput = () => {
    setModalOpen((prev) => !prev);
  };

  const hadleCheckItem = (item: string) => {
    setTypeId(item);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  const wrapperRef = useRef(null);
  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc ">
        <div
          className="form-control-bc focused valid filled"
          onClick={handleOpenInput}
        >
          <div className="form-control-label-bc inputs">
            <div className="form-control-select-bc ellipsis">{typeId}</div>
            <i
              className={`form-control-icon-bc bc-i-small-arrow-${
                modalOpen ? "up" : "down"
              }`}
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              Please select your type of ID
            </span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Bank Slip" ? "active" : ""
                } `}
                data-option-value={1}
                onClick={() => hadleCheckItem("Bank Slip")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Bank Slip
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Document" ? "active" : ""
                } `}
                data-option-value={2}
                onClick={() => hadleCheckItem("Document")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Document
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Passport/ ID card" ? "active" : ""
                } `}
                data-option-value={3}
                onClick={() => hadleCheckItem("Passport/ ID card")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Passport/ ID card
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Driver License" ? "active" : ""
                } `}
                data-option-value={4}
                onClick={() => hadleCheckItem("Driver License")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Driver License
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "IBAN" ? "active" : ""
                } `}
                data-option-value={5}
                onClick={() => hadleCheckItem("IBAN")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  IBAN
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Social Card ID" ? "active" : ""
                } `}
                data-option-value={6}
                onClick={() => hadleCheckItem("Social Card ID")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Social Card ID
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Other" ? "active" : ""
                } `}
                data-option-value={7}
                onClick={() => hadleCheckItem("Other")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Other
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  typeId === "Selfie" ? "active" : ""
                } `}
                data-option-value={15}
                onClick={() => hadleCheckItem("Selfie")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  Selfie
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { SelectTypeId };
