import React, { useState } from "react";

interface IMessage {
  message: string;
  subject: string;
  date: Date;
}

const Message = ({ message, subject, date }: IMessage) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleMoveModal = () => {
    setModalOpen((prev) => !prev);
  };

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  const formattedDate = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}, ${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;



  return (
    <div className="message-block-bc" data-status="read">
      <div className="message-title-block">
        <h3 className={`message-title-bc ${modalOpen ? "expanded" : ""}`}>
          {subject}
        </h3>
        <i className="message-remove-icon bc-i-clear" title="Delete" />
      </div>
      {modalOpen ? <p className="message-info-bc">{message}</p> : null}
      <div className="message-more-time-bc">
        <time className="message-time-bc">{formattedDate}</time>
        <i
          className="message-more-icon bc-i-double-arrow-top"
          onClick={handleMoveModal}
        />
      </div>
    </div>
  );
};

export { Message };
