import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./InputCountry.scss";

interface IInputCountry {
  selectedCountryShort: string;
  setSelectedCountryShort: React.Dispatch<React.SetStateAction<string>>;
}

const InputCountry = ({
  selectedCountryShort,
  setSelectedCountryShort,
}: IInputCountry) => {
  const wrapperRef = useRef(null);
  const [countrySelected, setCountrySelected] = useState("Turkey");
  const [countrySelectedFlag, setCountrySelectedFlag] = useState("turkey");
  const [modalOpen, setModalOpen] = useState(false);
  const countrySelectShort = (item: string) => {
    setSelectedCountryShort(item);
    setModalOpen(false);
  };
  const countrySelect = (item: string) => {
    setCountrySelected(item);
  };
  const countrySelectFlag = (item: string) => {
    setCountrySelectedFlag(item);
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const handleCloseModal = () => {
    setModalOpen((prev) => !prev);
  };
  return (
    <div className="entrance-f-item-bc">
      <div
        className="form-control-bc select select-flag filled"
        tabIndex={0}
        ref={wrapperRef}
      >
        <div
          className="form-control-label-bc form-control-select-bc inputs "
          onClick={handleCloseModal}
        >
          <i
            className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${countrySelectedFlag}`}
          />
          <span className="ellipsis">{countrySelected}</span>
          <i
            className={`form-control-icon-bc bc-i-small-arrow-${
              modalOpen ? "up" : "down"
            }`}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Country")}
          </span>
        </div>
        {modalOpen ? (
          <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AF" ? "active" : null
              }`}
              data-option-value="AF"
              onClick={() => {
                countrySelectShort("AF");
                countrySelect("Afghanistan");
                countrySelectFlag("afghanistan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc afghanistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Afghanistan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AX" ? "active" : null
              }`}
              data-option-value="AX"
              onClick={() => {
                countrySelectShort("AX");
                countrySelect("Aland Islands");
                countrySelectFlag("alandislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc alandislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Aland Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AL" ? "active" : null
              }`}
              data-option-value="AL"
              onClick={() => {
                countrySelectShort("AL");
                countrySelect("Albania");
                countrySelectFlag("albania");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc albania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Albania
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DZ" ? "active" : null
              }`}
              data-option-value="DZ"
              onClick={() => {
                countrySelectShort("DZ");
                countrySelect("Algeria");
                countrySelectFlag("algeria");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc algeria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Algeria
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AS" ? "active" : null
              }`}
              data-option-value="AS"
              onClick={() => {
                countrySelectShort("AS");
                countrySelect("American Samoa");
                countrySelectFlag("americansamoa");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc americansamoa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                American Samoa
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AD" ? "active" : null
              }`}
              data-option-value="AD"
              onClick={() => {
                countrySelectShort("AD");
                countrySelect("Andorra");
                countrySelectFlag("andorra");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc andorra" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Andorra
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AO" ? "active" : null
              }`}
              data-option-value="AO"
              onClick={() => {
                countrySelectShort("AO");
                countrySelect("Angola");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc angola" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Angola
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AI" ? "active" : null
              }`}
              data-option-value="AI"
              onClick={() => {
                countrySelectShort("AI");
                countrySelect("Anguilla");
                countrySelectFlag("anguilla");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc anguilla" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Anguilla
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AQ" ? "active" : null
              }`}
              data-option-value="AQ"
              onClick={() => {
                countrySelectShort("AQ");
                countrySelect("Antarctica");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc antarctica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Antarctica
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AG" ? "active" : null
              }`}
              data-option-value="AG"
              onClick={() => {
                countrySelectShort("AG");
                countrySelect("Antigua and Barbuda");
                countrySelectFlag("antiguaandbarbuda");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc antiguaandbarbuda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Antigua and Barbuda
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AR" ? "active" : null
              }`}
              data-option-value="AR"
              onClick={() => {
                countrySelectShort("AR");
                countrySelect("Argentina");
                countrySelectFlag("argentina");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc argentina" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Argentina
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AM"
              onClick={() => {
                countrySelectShort("AM");
                countrySelect("Armenia");
                countrySelectFlag("armenia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc armenia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Armenia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AW" ? "active" : null
              }`}
              data-option-value="AW"
              onClick={() => {
                countrySelectShort("AW");
                countrySelect("Aruba");
                countrySelectFlag("aruba");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc aruba" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Aruba
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AU" ? "active" : null
              }`}
              data-option-value="AU"
              onClick={() => {
                countrySelectShort("AU");
                countrySelect("Australia");
                countrySelectFlag("australia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc australia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Australia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AT" ? "active" : null
              }`}
              data-option-value="AT"
              onClick={() => {
                countrySelectShort("AT");
                countrySelect("Austria");
                countrySelectFlag("austria");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc austria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Austria
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AZ"
              onClick={() => {
                countrySelectShort("AZ");
                countrySelect("Azerbaijan");
                countrySelectFlag("azerbaijan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc azerbaijan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Azerbaijan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BS" ? "active" : null
              }`}
              data-option-value="BS"
              onClick={() => {
                countrySelectShort("BS");
                countrySelect("Bahamas");
                countrySelectFlag("bahamas");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bahamas" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bahamas
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BH" ? "active" : null
              }`}
              data-option-value="BH"
              onClick={() => {
                countrySelectShort("BH");
                countrySelect("Bahrain");
                countrySelectFlag("bahrain");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bahrain" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bahrain
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BD" ? "active" : null
              }`}
              data-option-value="BD"
              onClick={() => {
                countrySelectShort("BD");
                countrySelect("Bangladesh");
                countrySelectFlag("bangladesh");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bangladesh" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bangladesh
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BB" ? "active" : null
              }`}
              data-option-value="BB"
              onClick={() => {
                countrySelectShort("BB");
                countrySelect("Barbados");
                countrySelectFlag("barbados");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc barbados" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Barbados
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BY" ? "active" : null
              }`}
              data-option-value="BY"
              onClick={() => {
                countrySelectShort("BY");
                countrySelect("Belarus");
                countrySelectFlag("belarus");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belarus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Belarus
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BE" ? "active" : null
              }`}
              data-option-value="BE"
              onClick={() => {
                countrySelectShort("BE");
                countrySelect("Belgium");
                countrySelectFlag("belgium");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belgium" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Belgium
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BZ" ? "active" : null
              }`}
              data-option-value="BZ"
              onClick={() => {
                countrySelectShort("BZ");
                countrySelect("Belize");
                countrySelectFlag("belize");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belize" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Belize
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BJ" ? "active" : null
              }`}
              data-option-value="BJ"
              onClick={() => {
                countrySelectShort("BJ");
                countrySelect("Benin");
                countrySelectFlag("benin");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc benin" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Benin
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BM" ? "active" : null
              }`}
              data-option-value="BM"
              onClick={() => {
                countrySelectShort("BM");
                countrySelect("Bermuda");
                countrySelectFlag("bermuda");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bermuda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bermuda
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BT" ? "active" : null
              }`}
              data-option-value="BT"
              onClick={() => {
                countrySelectShort("BT");
                countrySelect("Bhutan");
                countrySelectFlag("bhutan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bhutan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bhutan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BO" ? "active" : null
              }`}
              data-option-value="BO"
              onClick={() => {
                countrySelectShort("BO");
                countrySelect("Bolivia");
                countrySelectFlag("bolivia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bolivia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bolivia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BQ" ? "active" : null
              }`}
              data-option-value="BQ"
              onClick={() => {
                countrySelectShort("BQ");
                countrySelect("Bonaire");
                countrySelectFlag("bonaire");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bonaire" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bonaire
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BA" ? "active" : null
              }`}
              data-option-value="BA"
              onClick={() => {
                countrySelectShort("BA");
                countrySelect("Bosnia and Herzegovina");
                countrySelectFlag("bosniaandherzegovina");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bosniaandherzegovina" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bosnia and Herzegovina
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BW" ? "active" : null
              }`}
              data-option-value="BW"
              onClick={() => {
                countrySelectShort("BW");
                countrySelect("Botswana");
                countrySelectFlag("botswana");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc botswana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Botswana
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BV" ? "active" : null
              }`}
              data-option-value="BV"
              onClick={() => {
                countrySelectShort("BV");
                countrySelect("Bouvet Island");
                countrySelectFlag("bouvetisland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bouvetisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bouvet Island
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BR" ? "active" : null
              }`}
              data-option-value="BR"
              onClick={() => {
                countrySelectShort("BR");
                countrySelect("Brazil");
                countrySelectFlag("brazil");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc brazil" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Brazil
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IO" ? "active" : null
              }`}
              data-option-value="IO"
              onClick={() => {
                countrySelectShort("IO");
                countrySelect("British Indian Ocean Territory");
                countrySelectFlag("britishindianoceanterritory");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc britishindianoceanterritory" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                British Indian Ocean Territory
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BN" ? "active" : null
              }`}
              data-option-value="BN"
              onClick={() => {
                countrySelectShort("BN");
                countrySelect("Brunei Darussalam");
                countrySelectFlag("bruneidarussalam");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bruneidarussalam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Brunei Darussalam
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BG" ? "active" : null
              }`}
              data-option-value="BG"
              onClick={() => {
                countrySelectShort("BG");
                countrySelect("Bulgaria");
                countrySelectFlag("bulgaria");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bulgaria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Bulgaria
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BF" ? "active" : null
              }`}
              data-option-value="BF"
              onClick={() => {
                countrySelectShort("BF");
                countrySelect("Burkina Faso");
                countrySelectFlag("burkinafaso");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc burkinafaso" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Burkina Faso
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BI" ? "active" : null
              }`}
              data-option-value="BI"
              onClick={() => {
                countrySelectShort("BI");
                countrySelect("Burundi");
                countrySelectFlag("burundi");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc burundi" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Burundi
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CV" ? "active" : null
              }`}
              data-option-value="CV"
              onClick={() => {
                countrySelectShort("CV");
                countrySelect("Cabo Verde");
                countrySelectFlag("caboverde");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc caboverde" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cabo Verde
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KH" ? "active" : null
              }`}
              data-option-value="KH"
              onClick={() => {
                countrySelectShort("KH");
                countrySelect("Cambodia");
                countrySelectFlag("cambodia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cambodia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cambodia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CM" ? "active" : null
              }`}
              data-option-value="CM"
              onClick={() => {
                countrySelectShort("CM");
                countrySelect("Cameroon");
                countrySelectFlag("cameroon");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cameroon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cameroon
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CA" ? "active" : null
              }`}
              data-option-value="CA"
              onClick={() => {
                countrySelectShort("CA");
                countrySelect("Canada");
                countrySelectFlag("canada");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc canada" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Canada
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KY" ? "active" : null
              }`}
              data-option-value="KY"
              onClick={() => {
                countrySelectShort("KY");
                countrySelect("Cayman Islands");
                countrySelectFlag("caymanislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc caymanislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cayman Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CF" ? "active" : null
              }`}
              data-option-value="CF"
              onClick={() => {
                countrySelectShort("CF");
                countrySelect("Central African Republic");
                countrySelectFlag("centralafricanrepublic");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc centralafricanrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Central African Republic
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TD" ? "active" : null
              }`}
              data-option-value="TD"
              onClick={() => {
                countrySelectShort("TD");
                countrySelect("Chad");
                countrySelectFlag("chad");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc chad" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Chad
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CL" ? "active" : null
              }`}
              data-option-value="CL"
              onClick={() => {
                countrySelectShort("CL");
                countrySelect("Chile");
                countrySelectFlag("chile");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc chile" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Chile
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CN" ? "active" : null
              }`}
              data-option-value="CN"
              onClick={() => {
                countrySelectShort("CN");
                countrySelect("China");
                countrySelectFlag("china");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc china" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                China
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CX" ? "active" : null
              }`}
              data-option-value="CX"
              onClick={() => {
                countrySelectShort("CX");
                countrySelect("Christmas Island");
                countrySelectFlag("christmasisland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc christmasisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Christmas Island
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CC" ? "active" : null
              }`}
              data-option-value="CC"
              onClick={() => {
                countrySelectShort("CC");
                countrySelect("Cocos (Keeling) Islands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cocoskeelingislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cocos (Keeling) Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CO" ? "active" : null
              }`}
              data-option-value="CO"
              onClick={() => {
                countrySelectShort("CO");
                countrySelect("Colombia");
                countrySelectFlag("colombia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc colombia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Colombia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KM" ? "active" : null
              }`}
              data-option-value="KM"
              onClick={() => {
                countrySelectShort("KM");
                countrySelect("Comoros");
                countrySelectFlag("comoros");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc comoros" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Comoros
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CG" ? "active" : null
              }`}
              data-option-value="CG"
              onClick={() => {
                countrySelectShort("CG");
                countrySelect("Congo");
                countrySelectFlag("congobrazzaville");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc congobrazzaville" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Congo
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CD" ? "active" : null
              }`}
              data-option-value="CD"
              onClick={() => {
                countrySelectShort("CD");
                countrySelect("Congo, Democratic Republic of the (Zaire)");
                countrySelectFlag("congokinshasa");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc congokinshasa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Congo, Democratic Republic of the (Zaire)
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CK" ? "active" : null
              }`}
              data-option-value="CK"
              onClick={() => {
                countrySelectShort("CK");
                countrySelect("Cook Islands");
                countrySelectFlag("cookislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cookislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cook Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CR" ? "active" : null
              }`}
              data-option-value="CR"
              onClick={() => {
                countrySelectShort("CR");
                countrySelect("Costa Rica");
                countrySelectFlag("costarica");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc costarica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Costa Rica
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CI" ? "active" : null
              }`}
              data-option-value="CI"
              onClick={() => {
                countrySelectShort("CI");
                countrySelect("Cote dIvoire");
                countrySelectFlag("cotedivoire");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cotedivoire" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cote dIvoire
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HR" ? "active" : null
              }`}
              data-option-value="HR"
              onClick={() => {
                countrySelectShort("HR");
                countrySelect("Croatia");
                countrySelectFlag("croatia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc croatia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Croatia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CU" ? "active" : null
              }`}
              data-option-value="CU"
              onClick={() => {
                countrySelectShort("CU");
                countrySelect("Cuba");
                countrySelectFlag("cuba");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cuba" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cuba
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CW" ? "active" : null
              }`}
              data-option-value="CW"
              onClick={() => {
                countrySelectShort("CW");
                countrySelect("Curacao");
                countrySelectFlag("curacao");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc curacao" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Curacao
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CY" ? "active" : null
              }`}
              data-option-value="CY"
              onClick={() => {
                countrySelectShort("CY");
                countrySelect("Cyprus");
                countrySelectFlag("cyprus");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cyprus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Cyprus
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CZ" ? "active" : null
              }`}
              data-option-value="CZ"
              onClick={() => {
                countrySelectShort("CZ");
                countrySelect("Czech Republic");
                countrySelectFlag("czechrepublic");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc czechrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Czech Republic
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DK" ? "active" : null
              }`}
              data-option-value="DK"
              onClick={() => {
                countrySelectShort("DK");
                countrySelect("Denmark");
                countrySelectFlag("denmark");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc denmark" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Denmark
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DJ" ? "active" : null
              }`}
              data-option-value="DJ"
              onClick={() => {
                countrySelectShort("DJ");
                countrySelect("Djibouti");
                countrySelectFlag("djibouti");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc djibouti" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Djibouti
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DM" ? "active" : null
              }`}
              data-option-value="DM"
              onClick={() => {
                countrySelectShort("DM");
                countrySelect("Dominica");
                countrySelectFlag("dominica");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc dominica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Dominica
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DO" ? "active" : null
              }`}
              data-option-value="DO"
              onClick={() => {
                countrySelectShort("DO");
                countrySelect("Dominican Republic");
                countrySelectFlag("dominicanrepublic");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc dominicanrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Dominican Republic
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "EC" ? "active" : null
              }`}
              data-option-value="EC"
              onClick={() => {
                countrySelectShort("EC");
                countrySelect("Ecuador");
                countrySelectFlag("ecuador");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ecuador" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Ecuador
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "EG" ? "active" : null
              }`}
              data-option-value="EG"
              onClick={() => {
                countrySelectShort("EG");
                countrySelect("Egypt");
                countrySelectFlag("egypt");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc egypt" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Egypt
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SV" ? "active" : null
              }`}
              data-option-value="SV"
              onClick={() => {
                countrySelectShort("SV");
                countrySelect("El Salvador");
                countrySelectFlag("elsalvador");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc elsalvador" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                El Salvador
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GQ" ? "active" : null
              }`}
              data-option-value="GQ"
              onClick={() => {
                countrySelectShort("GQ");
                countrySelect("Equatorial Guinea");
                countrySelectFlag("equatorialguinea");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc equatorialguinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Equatorial Guinea
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ER" ? "active" : null
              }`}
              data-option-value="ER"
              onClick={() => {
                countrySelectShort("ER");
                countrySelect("Eritrea");
                countrySelectFlag("eritrea");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc eritrea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Eritrea
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "EE" ? "active" : null
              }`}
              data-option-value="EE"
              onClick={() => {
                countrySelectShort("EE");
                countrySelect("Estonia");
                countrySelectFlag("estonia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc estonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Estonia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ET" ? "active" : null
              }`}
              data-option-value="ET"
              onClick={() => {
                countrySelectShort("ET");
                countrySelect("Ethiopia");
                countrySelectFlag("ethiopia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ethiopia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Ethiopia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FK" ? "active" : null
              }`}
              data-option-value="FK"
              onClick={() => {
                countrySelectShort("FK");
                countrySelect("Falkland Islands");
                countrySelectFlag("falklandislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc falklandislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Falkland Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FO" ? "active" : null
              }`}
              data-option-value="FO"
              onClick={() => {
                countrySelectShort("FO");
                countrySelect("Faroe Islands");
                countrySelectFlag("faroeislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc faroeislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Faroe Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FJ" ? "active" : null
              }`}
              data-option-value="FJ"
              onClick={() => {
                countrySelectShort("FJ");
                countrySelect("Fiji");
                countrySelectFlag("fiji");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc fiji" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Fiji
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FI" ? "active" : null
              }`}
              data-option-value="FI"
              onClick={() => {
                countrySelectShort("FI");
                countrySelect("Finland");
                countrySelectFlag("finland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc finland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Finland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FR" ? "active" : null
              }`}
              data-option-value="FR"
              onClick={() => {
                countrySelectShort("FR");
                countrySelect("France");
                countrySelectFlag("france");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc france" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                France
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GF" ? "active" : null
              }`}
              data-option-value="GF"
              onClick={() => {
                countrySelectShort("GF");
                countrySelect("French Guiana");
                countrySelectFlag("frenchguiana");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchguiana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                French Guiana
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PF" ? "active" : null
              }`}
              data-option-value="PF"
              onClick={() => {
                countrySelectShort("PF");
                countrySelect("French Polynesia");
                countrySelectFlag("frenchpolynesia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchpolynesia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                French Polynesia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TF" ? "active" : null
              }`}
              data-option-value="TF"
              onClick={() => {
                countrySelectShort("TF");
                countrySelect("French Southern Territories");
                countrySelectFlag("frenchsouthernterritories");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchsouthernterritories" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                French Southern Territories
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GA" ? "active" : null
              }`}
              data-option-value="GA"
              onClick={() => {
                countrySelectShort("GA");
                countrySelect("Gabon");
                countrySelectFlag("gabon");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gabon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Gabon
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GM" ? "active" : null
              }`}
              data-option-value="GM"
              onClick={() => {
                countrySelectShort("GM");
                countrySelect("Gambia");
                countrySelectFlag("gambia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gambia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Gambia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GE" ? "active" : null
              }`}
              data-option-value="GE"
              onClick={() => {
                countrySelectShort("GE");
                countrySelect("Georgia");
                countrySelectFlag("georgia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc georgia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Georgia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "DE" ? "active" : null
              }`}
              data-option-value="DE"
              onClick={() => {
                countrySelectShort("DE");
                countrySelect("Germany");
                countrySelectFlag("germany");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc germany" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Germany
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GH" ? "active" : null
              }`}
              data-option-value="GH"
              onClick={() => {
                countrySelectShort("GH");
                countrySelect("Ghana");
                countrySelectFlag("ghana");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ghana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Ghana
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GI" ? "active" : null
              }`}
              data-option-value="GI"
              onClick={() => {
                countrySelectShort("GI");
                countrySelect("Gibraltar");
                countrySelectFlag("gibraltar");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gibraltar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Gibraltar
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GR" ? "active" : null
              }`}
              data-option-value="GR"
              onClick={() => {
                countrySelectShort("GR");
                countrySelect("Greece");
                countrySelectFlag("greece");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc greece" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Greece
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GL" ? "active" : null
              }`}
              data-option-value="GL"
              onClick={() => {
                countrySelectShort("GL");
                countrySelect("Greenland");
                countrySelectFlag("greenland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc greenland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Greenland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GD" ? "active" : null
              }`}
              data-option-value="GD"
              onClick={() => {
                countrySelectShort("GD");
                countrySelect("Grenada");
                countrySelectFlag("grenada");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc grenada" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Grenada
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GP" ? "active" : null
              }`}
              data-option-value="GP"
              onClick={() => {
                countrySelectShort("GP");
                countrySelect("Guadeloupe");
                countrySelectFlag("guadeloupe");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guadeloupe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guadeloupe
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GU" ? "active" : null
              }`}
              data-option-value="GU"
              onClick={() => {
                countrySelectShort("GU");
                countrySelect("Guam");
                countrySelectFlag("guam");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guam
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GT" ? "active" : null
              }`}
              data-option-value="GT"
              onClick={() => {
                countrySelectShort("GT");
                countrySelect("Guatemala");
                countrySelectFlag("guatemala");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guatemala" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guatemala
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GG" ? "active" : null
              }`}
              data-option-value="GG"
              onClick={() => {
                countrySelectShort("GG");
                countrySelect("Guernsey");
                countrySelectFlag("guernsey");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guernsey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guernsey
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GN" ? "active" : null
              }`}
              data-option-value="GN"
              onClick={() => {
                countrySelectShort("GN");
                countrySelect("Guinea");
                countrySelectFlag("guinea");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guinea
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GW" ? "active" : null
              }`}
              data-option-value="GW"
              onClick={() => {
                countrySelectShort("GW");
                countrySelect("Guinea-Bissau");
                countrySelectFlag("guinea-bissau");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guinea-bissau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guinea-Bissau
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GY" ? "active" : null
              }`}
              data-option-value="GY"
              onClick={() => {
                countrySelectShort("GY");
                countrySelect("Guyana");
                countrySelectFlag("guyana");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guyana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Guyana
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HT" ? "active" : null
              }`}
              data-option-value="HT"
              onClick={() => {
                countrySelectShort("HT");
                countrySelect("Haiti");
                countrySelectFlag("haiti");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc haiti" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Haiti
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HM" ? "active" : null
              }`}
              data-option-value="HM"
              onClick={() => {
                countrySelectShort("HM");
                countrySelect("Heard Island and McDonald Islands");
                countrySelectFlag("heardislandandmcdonaldislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc heardislandandmcdonaldislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Heard Island and McDonald Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VA" ? "active" : null
              }`}
              data-option-value="VA"
              onClick={() => {
                countrySelectShort("VA");
                countrySelect("Holy See");
                countrySelectFlag("holysee");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc holysee" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Holy See
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HN" ? "active" : null
              }`}
              data-option-value="HN"
              onClick={() => {
                countrySelectShort("HN");
                countrySelect("Honduras");
                countrySelectFlag("honduras");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc honduras" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Honduras
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HK" ? "active" : null
              }`}
              data-option-value="HK"
              onClick={() => {
                countrySelectShort("HK");
                countrySelect("Hong Kong");
                countrySelectFlag("hongkong");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc hongkong" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Hong Kong
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "HU" ? "active" : null
              }`}
              data-option-value="HU"
              onClick={() => {
                countrySelectShort("HU");
                countrySelect("Hungary");
                countrySelectFlag("hungary");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc hungary" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Hungary
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IS" ? "active" : null
              }`}
              data-option-value="IS"
              onClick={() => {
                countrySelectShort("IS");
                countrySelect("Iceland");
                countrySelectFlag("iceland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iceland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Iceland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IN" ? "active" : null
              }`}
              data-option-value="IN"
              onClick={() => {
                countrySelectShort("IN");
                countrySelect("India");
                countrySelectFlag("india");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc india" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                India
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ID" ? "active" : null
              }`}
              data-option-value="ID"
              onClick={() => {
                countrySelectShort("ID");
                countrySelect("Indonesia");
                countrySelectFlag("indonesia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc indonesia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Indonesia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IR" ? "active" : null
              }`}
              data-option-value="IR"
              onClick={() => {
                countrySelectShort("IR");
                countrySelect("Iran");
                countrySelectFlag("iran");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iran" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Iran
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IQ" ? "active" : null
              }`}
              data-option-value="IQ"
              onClick={() => {
                countrySelectShort("IQ");
                countrySelect("Iraq");
                countrySelectFlag("iraq");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iraq" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Iraq
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IE" ? "active" : null
              }`}
              data-option-value="IE"
              onClick={() => {
                countrySelectShort("IE");
                countrySelect("Ireland");
                countrySelectFlag("ireland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ireland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Ireland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IM" ? "active" : null
              }`}
              data-option-value="IM"
              onClick={() => {
                countrySelectShort("IM");
                countrySelect("Isle of Man");
                countrySelectFlag("isleofman");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc isleofman" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Isle of Man
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IL" ? "active" : null
              }`}
              data-option-value="IL"
              onClick={() => {
                countrySelectShort("IL");
                countrySelect("Israel");
                countrySelectFlag("israel");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc israel" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Israel
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "IT" ? "active" : null
              }`}
              data-option-value="IT"
              onClick={() => {
                countrySelectShort("IT");
                countrySelect("Italy");
                countrySelectFlag("italy");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc italy" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Italy
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "JM" ? "active" : null
              }`}
              data-option-value="JM"
              onClick={() => {
                countrySelectShort("JM");
                countrySelect("Jamaica");
                countrySelectFlag("jamaica");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jamaica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Jamaica
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "JP" ? "active" : null
              }`}
              data-option-value="JP"
              onClick={() => {
                countrySelectShort("JP");
                countrySelect("Japan");
                countrySelectFlag("japan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc japan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Japan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "JE" ? "active" : null
              }`}
              data-option-value="JE"
              onClick={() => {
                countrySelectShort("JE");
                countrySelect("Jersey");
                countrySelectFlag("jersey");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jersey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Jersey
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "JO" ? "active" : null
              }`}
              data-option-value="JO"
              onClick={() => {
                countrySelectShort("JO");
                countrySelect("Jordan");
                countrySelectFlag("jordan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jordan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Jordan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KZ" ? "active" : null
              }`}
              data-option-value="KZ"
              onClick={() => {
                countrySelectShort("KZ");
                countrySelect("Kazakhstan");
                countrySelectFlag("kazakhstan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kazakhstan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Kazakhstan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KE" ? "active" : null
              }`}
              data-option-value="KE"
              onClick={() => {
                countrySelectShort("KE");
                countrySelect("Kenya");
                countrySelectFlag("kenya");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kenya" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Kenya
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KI" ? "active" : null
              }`}
              data-option-value="KI"
              onClick={() => {
                countrySelectShort("KI");
                countrySelect("Kiribati");
                countrySelectFlag("kiribati");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kiribati" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Kiribati
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KP" ? "active" : null
              }`}
              data-option-value="KP"
              onClick={() => {
                countrySelectShort("KP");
                countrySelect("Korea, North");
                countrySelectFlag("korea,north");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc korea,north" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Korea, North
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KW" ? "active" : null
              }`}
              data-option-value="KW"
              onClick={() => {
                countrySelectShort("KW");
                countrySelect("Kuwait");
                countrySelectFlag("kuwait");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kuwait" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Kuwait
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KG" ? "active" : null
              }`}
              data-option-value="KG"
              onClick={() => {
                countrySelectShort("KG");
                countrySelect("Kyrgyzstan");
                countrySelectFlag("kyrgyzstan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kyrgyzstan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Kyrgyzstan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LA" ? "active" : null
              }`}
              data-option-value="LA"
              onClick={() => {
                countrySelectShort("LA");
                countrySelect("Laos");
                countrySelectFlag("laos");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc laos" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Laos
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LV" ? "active" : null
              }`}
              data-option-value="LV"
              onClick={() => {
                countrySelectShort("LV");
                countrySelect("Latvia");
                countrySelectFlag("latvia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc latvia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Latvia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LB" ? "active" : null
              }`}
              data-option-value="LB"
              onClick={() => {
                countrySelectShort("LB");
                countrySelect("Lebanon");
                countrySelectFlag("lebanon");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lebanon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Lebanon
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LS" ? "active" : null
              }`}
              data-option-value="LS"
              onClick={() => {
                countrySelectShort("LS");
                countrySelect("Lesotho");
                countrySelectFlag("lesotho");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lesotho" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Lesotho
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LR" ? "active" : null
              }`}
              data-option-value="LR"
              onClick={() => {
                countrySelectShort("LR");
                countrySelect("Liberia");
                countrySelectFlag("liberia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc liberia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Liberia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LY" ? "active" : null
              }`}
              data-option-value="LY"
              onClick={() => {
                countrySelectShort("LY");
                countrySelect("Libya");
                countrySelectFlag("libya");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc libya" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Libya
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LI" ? "active" : null
              }`}
              data-option-value="LI"
              onClick={() => {
                countrySelectShort("LI");
                countrySelect("Liechtenstein");
                countrySelectFlag("liechtenstein");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc liechtenstein" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Liechtenstein
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LT" ? "active" : null
              }`}
              data-option-value="LT"
              onClick={() => {
                countrySelectShort("LT");
                countrySelect("Lithuania");
                countrySelectFlag("lithuania");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lithuania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Lithuania
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LU" ? "active" : null
              }`}
              data-option-value="LU"
              onClick={() => {
                countrySelectShort("LU");
                countrySelect("Luxembourg");
                countrySelectFlag("luxembourg");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc luxembourg" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Luxembourg
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MO" ? "active" : null
              }`}
              data-option-value="MO"
              onClick={() => {
                countrySelectShort("MO");
                countrySelect("Macao");
                countrySelectFlag("macao");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc macao" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Macao
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MG" ? "active" : null
              }`}
              data-option-value="MG"
              onClick={() => {
                countrySelectShort("MG");
                countrySelect("Madagascar");
                countrySelectFlag("madagascar");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc madagascar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Madagascar
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MW" ? "active" : null
              }`}
              data-option-value="MW"
              onClick={() => {
                countrySelectShort("MW");
                countrySelect("Malawi");
                countrySelectFlag("malawi");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malawi" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Malawi
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MY" ? "active" : null
              }`}
              data-option-value="MY"
              onClick={() => {
                countrySelectShort("MY");
                countrySelect("Malaysia");
                countrySelectFlag("malaysia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malaysia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Malaysia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MV" ? "active" : null
              }`}
              data-option-value="MV"
              onClick={() => {
                countrySelectShort("MV");
                countrySelect("Maldives");
                countrySelectFlag("maldives");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc maldives" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Maldives
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ML" ? "active" : null
              }`}
              data-option-value="ML"
              onClick={() => {
                countrySelectShort("ML");
                countrySelect("Mali");
                countrySelectFlag("mali");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mali" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mali
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MT" ? "active" : null
              }`}
              data-option-value="MT"
              onClick={() => {
                countrySelectShort("MT");
                countrySelect("Malta");
                countrySelectFlag("malta");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malta" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Malta
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MH" ? "active" : null
              }`}
              data-option-value="MH"
              onClick={() => {
                countrySelectShort("MH");
                countrySelect("Marshall Islands");
                countrySelectFlag("marshallislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc marshallislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Marshall Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MQ" ? "active" : null
              }`}
              data-option-value="MQ"
              onClick={() => {
                countrySelectShort("MQ");
                countrySelect("Martinique");
                countrySelectFlag("martinique");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc martinique" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Martinique
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MR" ? "active" : null
              }`}
              data-option-value="MR"
              onClick={() => {
                countrySelectShort("MR");
                countrySelect("Mauritania");
                countrySelectFlag("mauritania");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mauritania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mauritania
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MU" ? "active" : null
              }`}
              data-option-value="MU"
              onClick={() => {
                countrySelectShort("MU");
                countrySelect("Mauritius");
                countrySelectFlag("mauritius");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mauritius" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mauritius
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "YT" ? "active" : null
              }`}
              data-option-value="YT"
              onClick={() => {
                countrySelectShort("YT");
                countrySelect("Mayotte");
                countrySelectFlag("mayotte");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mayotte" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mayotte
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MX" ? "active" : null
              }`}
              data-option-value="MX"
              onClick={() => {
                countrySelectShort("MX");
                countrySelect("Mexico");
                countrySelectFlag("mexico");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mexico" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mexico
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "FM" ? "active" : null
              }`}
              data-option-value="FM"
              onClick={() => {
                countrySelectShort("FM");
                countrySelect("Micronesia, Federated States of");
                countrySelectFlag("micronesiafederatedstatesof");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc micronesiafederatedstatesof" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Micronesia, Federated States of
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MD" ? "active" : null
              }`}
              data-option-value="MD"
              onClick={() => {
                countrySelectShort("MD");
                countrySelect("Moldova");
                countrySelectFlag("moldova");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc moldova" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Moldova
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MC" ? "active" : null
              }`}
              data-option-value="MC"
              onClick={() => {
                countrySelectShort("MC");
                countrySelect("Monaco");
                countrySelectFlag("monaco");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc monaco" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Monaco
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MN" ? "active" : null
              }`}
              data-option-value="MN"
              onClick={() => {
                countrySelectShort("MN");
                countrySelect("Mongolia");
                countrySelectFlag("mongolia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mongolia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mongolia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ME" ? "active" : null
              }`}
              data-option-value="ME"
              onClick={() => {
                countrySelectShort("ME");
                countrySelect("Montenegro");
                countrySelectFlag("montenegro");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc montenegro" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Montenegro
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MS" ? "active" : null
              }`}
              data-option-value="MS"
              onClick={() => {
                countrySelectShort("MS");
                countrySelect("Montserrat");
                countrySelectFlag("montserrat");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc montserrat" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Montserrat
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MA" ? "active" : null
              }`}
              data-option-value="MA"
              onClick={() => {
                countrySelectShort("MA");
                countrySelect("Morocco");
                countrySelectFlag("morocco");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc morocco" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Morocco
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MZ" ? "active" : null
              }`}
              data-option-value="MZ"
              onClick={() => {
                countrySelectShort("MZ");
                countrySelect("Mozambique");
                countrySelectFlag("mozambique");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mozambique" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Mozambique
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MM" ? "active" : null
              }`}
              data-option-value="MM"
              onClick={() => {
                countrySelectShort("MM");
                countrySelect("Myanmar");
                countrySelectFlag("myanmar");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc myanmar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Myanmar
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NA" ? "active" : null
              }`}
              data-option-value="NA"
              onClick={() => {
                countrySelectShort("NA");
                countrySelect("Namibia");
                countrySelectFlag("namibia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc namibia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Namibia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NR" ? "active" : null
              }`}
              data-option-value="NR"
              onClick={() => {
                countrySelectShort("NR");
                countrySelect("Nauru");
                countrySelectFlag("nauru");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nauru" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Nauru
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NP" ? "active" : null
              }`}
              data-option-value="NP"
              onClick={() => {
                countrySelectShort("NP");
                countrySelect("Nepal");
                countrySelectFlag("nepal");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nepal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Nepal
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NL" ? "active" : null
              }`}
              data-option-value="NL"
              onClick={() => {
                countrySelectShort("NL");
                countrySelect("Netherlands");
                countrySelectFlag("netherlands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc netherlands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Netherlands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NC" ? "active" : null
              }`}
              data-option-value="NC"
              onClick={() => {
                countrySelectShort("NC");
                countrySelect("New Caledonia");
                countrySelectFlag("newcaledonia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc newcaledonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                New Caledonia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NZ" ? "active" : null
              }`}
              data-option-value="NZ"
              onClick={() => {
                countrySelectShort("NZ");
                countrySelect("New Zealand");
                countrySelectFlag("newzealand");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc newzealand" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                New Zealand
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NI" ? "active" : null
              }`}
              data-option-value="NI"
              onClick={() => {
                countrySelectShort("NI");
                countrySelect("Nicaragua");
                countrySelectFlag("nicaragua");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nicaragua" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Nicaragua
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NE" ? "active" : null
              }`}
              data-option-value="NE"
              onClick={() => {
                countrySelectShort("NE");
                countrySelect("Niger");
                countrySelectFlag("niger");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc niger" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Niger
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NG" ? "active" : null
              }`}
              data-option-value="NG"
              onClick={() => {
                countrySelectShort("NG");
                countrySelect("Nigeria");
                countrySelectFlag("nigeria");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nigeria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Nigeria
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NU" ? "active" : null
              }`}
              data-option-value="NU"
              onClick={() => {
                countrySelectShort("NU");
                countrySelect("Niue");
                countrySelectFlag("niue");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc niue" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Niue
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NF" ? "active" : null
              }`}
              data-option-value="NF"
              onClick={() => {
                countrySelectShort("NF");
                countrySelect("Norfolk Island");
                countrySelectFlag("norfolkisland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc norfolkisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Norfolk Island
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MP" ? "active" : null
              }`}
              data-option-value="MP"
              onClick={() => {
                countrySelectShort("MP");
                countrySelect("Northern Mariana Islands");
                countrySelectFlag("northernmarianaislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northernmarianaislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Northern Mariana Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MK" ? "active" : null
              }`}
              data-option-value="MK"
              onClick={() => {
                countrySelectShort("MK");
                countrySelect("North Macedonia");
                countrySelectFlag("northmacedonia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northmacedonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                North Macedonia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "NO" ? "active" : null
              }`}
              data-option-value="NO"
              onClick={() => {
                countrySelectShort("NO");
                countrySelect("Norway");
                countrySelectFlag("norway");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc norway" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Norway
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "OM" ? "active" : null
              }`}
              data-option-value="OM"
              onClick={() => {
                countrySelectShort("OM");
                countrySelect("Oman");
                countrySelectFlag("oman");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc oman" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Oman
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PK" ? "active" : null
              }`}
              data-option-value="PK"
              onClick={() => {
                countrySelectShort("PK");
                countrySelect("Pakistan");
                countrySelectFlag("pakistan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc pakistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Pakistan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PW" ? "active" : null
              }`}
              data-option-value="PW"
              onClick={() => {
                countrySelectShort("PW");
                countrySelect("Palau");
                countrySelectFlag("palau");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc palau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Palau
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PS" ? "active" : null
              }`}
              data-option-value="PS"
              onClick={() => {
                countrySelectShort("PS");
                countrySelect("Palestine, State of");
                countrySelectFlag("palestine");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc palestine" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Palestine, State of
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PA" ? "active" : null
              }`}
              data-option-value="PA"
              onClick={() => {
                countrySelectShort("PA");
                countrySelect("Panama");
                countrySelectFlag("panama");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc panama" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Panama
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PG" ? "active" : null
              }`}
              data-option-value="PG"
              onClick={() => {
                countrySelectShort("PG");
                countrySelect("Papua New Guinea");
                countrySelectFlag("papuanewguinea");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc papuanewguinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Papua New Guinea
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PY" ? "active" : null
              }`}
              data-option-value="PY"
              onClick={() => {
                countrySelectShort("PY");
                countrySelect("Paraguay");
                countrySelectFlag("paraguay");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc paraguay" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Paraguay
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PE" ? "active" : null
              }`}
              data-option-value="PE"
              onClick={() => {
                countrySelectShort("PE");
                countrySelect("Peru");
                countrySelectFlag("peru");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc peru" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Peru
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PH" ? "active" : null
              }`}
              data-option-value="PH"
              onClick={() => {
                countrySelectShort("PH");
                countrySelect("Philippines");
                countrySelectFlag("philippines");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc philippines" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Philippines
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PN" ? "active" : null
              }`}
              data-option-value="PN"
              onClick={() => {
                countrySelectShort("PN");
                countrySelect("Pitcairn");
                countrySelectFlag("pitcairn");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc pitcairn" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Pitcairn
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PL" ? "active" : null
              }`}
              data-option-value="PL"
              onClick={() => {
                countrySelectShort("PL");
                countrySelect("Poland");
                countrySelectFlag("poland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc poland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Poland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PT" ? "active" : null
              }`}
              data-option-value="PT"
              onClick={() => {
                countrySelectShort("PT");
                countrySelect("Portugal");
                countrySelectFlag("portugal");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc portugal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Portugal
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PR" ? "active" : null
              }`}
              data-option-value="PR"
              onClick={() => {
                countrySelectShort("PR");
                countrySelect("Puerto Rico");
                countrySelectFlag("puertorico");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc puertorico" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Puerto Rico
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "QA" ? "active" : null
              }`}
              data-option-value="QA"
              onClick={() => {
                countrySelectShort("QA");
                countrySelect("Qatar");
                countrySelectFlag("qatar");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc qatar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Qatar
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "RE" ? "active" : null
              }`}
              data-option-value="RE"
              onClick={() => {
                countrySelectShort("RE");
                countrySelect("Reunion");
                countrySelectFlag("reunion");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc reunion" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Reunion
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "RO" ? "active" : null
              }`}
              data-option-value="RO"
              onClick={() => {
                countrySelectShort("RO");
                countrySelect("Romania");
                countrySelectFlag("romania");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc romania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Romania
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "RU" ? "active" : null
              }`}
              data-option-value="RU"
              onClick={() => {
                countrySelectShort("RU");
                countrySelect("Russia");
                countrySelectFlag("russia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc russia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Russia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "RW" ? "active" : null
              }`}
              data-option-value="RW"
              onClick={() => {
                countrySelectShort("RW");
                countrySelect("Rwanda");
                countrySelectFlag("rwanda");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc rwanda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Rwanda
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "BL" ? "active" : null
              }`}
              data-option-value="BL"
              onClick={() => {
                countrySelectShort("BL");
                countrySelect("Saint Barthelemy");
                countrySelectFlag("saintbarthelemy");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintbarthelemy" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Barthelemy
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SH" ? "active" : null
              }`}
              data-option-value="SH"
              onClick={() => {
                countrySelectShort("SH");
                countrySelect("Saint Helena");
                countrySelectFlag("sainthelena");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sainthelena" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Helena
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KN" ? "active" : null
              }`}
              data-option-value="KN"
              onClick={() => {
                countrySelectShort("KN");
                countrySelect("Saint Kitts and Nevis");
                countrySelectFlag("saintkittsandnevis");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintkittsandnevis" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Kitts and Nevis
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LC" ? "active" : null
              }`}
              data-option-value="LC"
              onClick={() => {
                countrySelectShort("LC");
                countrySelect("Saint Lucia");
                countrySelectFlag("saintlucia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintlucia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Lucia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "MF" ? "active" : null
              }`}
              data-option-value="MF"
              onClick={() => {
                countrySelectShort("MF");
                countrySelect("Saint Martin (France)");
                countrySelectFlag("saintmartinfrance");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintmartinfrance" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Martin (France)
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "PM" ? "active" : null
              }`}
              data-option-value="PM"
              onClick={() => {
                countrySelectShort("PM");
                countrySelect("Saint Pierre and Miquelon");
                countrySelectFlag("saintpierreandmiquelon");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintpierreandmiquelon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Pierre and Miquelon
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VC" ? "active" : null
              }`}
              data-option-value="VC"
              onClick={() => {
                countrySelectShort("VC");
                countrySelect("Saint Vincent and the Grenadines");
                countrySelectFlag("saintvincentandthegrenadines");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintvincentandthegrenadines" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saint Vincent and the Grenadines
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "WS" ? "active" : null
              }`}
              data-option-value="WS"
              onClick={() => {
                countrySelectShort("WS");
                countrySelect("Samoa");
                countrySelectFlag("samoa");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc samoa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Samoa
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SM" ? "active" : null
              }`}
              data-option-value="SM"
              onClick={() => {
                countrySelectShort("SM");
                countrySelect("San Marino");
                countrySelectFlag("sanmarino");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sanmarino" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                San Marino
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ST" ? "active" : null
              }`}
              data-option-value="ST"
              onClick={() => {
                countrySelectShort("ST");
                countrySelect("Sao Tome and Principe");
                countrySelectFlag("saotomeandprincipe");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saotomeandprincipe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sao Tome and Principe
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SA" ? "active" : null
              }`}
              data-option-value="SA"
              onClick={() => {
                countrySelectShort("SA");
                countrySelect("Saudi Arabia");
                countrySelectFlag("saudiarabia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saudiarabia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Saudi Arabia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SN" ? "active" : null
              }`}
              data-option-value="SN"
              onClick={() => {
                countrySelectShort("SN");
                countrySelect("Senegal");
                countrySelectFlag("senegal");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc senegal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Senegal
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "RS" ? "active" : null
              }`}
              data-option-value="RS"
              onClick={() => {
                countrySelectShort("RS");
                countrySelect("Serbia");
                countrySelectFlag("serbia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc serbia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Serbia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SC" ? "active" : null
              }`}
              data-option-value="SC"
              onClick={() => {
                countrySelectShort("SC");
                countrySelect("Seychelles");
                countrySelectFlag("seychelles");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc seychelles" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Seychelles
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SL" ? "active" : null
              }`}
              data-option-value="SL"
              onClick={() => {
                countrySelectShort("SL");
                countrySelect("Sierra Leone");
                countrySelectFlag("sierraleone");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sierraleone" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sierra Leone
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SG" ? "active" : null
              }`}
              data-option-value="SG"
              onClick={() => {
                countrySelectShort("SG");
                countrySelect("Singapore");
                countrySelectFlag("singapore");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc singapore" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Singapore
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SX" ? "active" : null
              }`}
              data-option-value="SX"
              onClick={() => {
                countrySelectShort("SX");
                countrySelect("Sint Maarten (Netherlands)");
                countrySelectFlag("sintmaartennetherlands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sintmaartennetherlands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sint Maarten (Netherlands)
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SK" ? "active" : null
              }`}
              data-option-value="SK"
              onClick={() => {
                countrySelectShort("SK");
                countrySelect("Slovakia");
                countrySelectFlag("slovakia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc slovakia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Slovakia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SI" ? "active" : null
              }`}
              data-option-value="SI"
              onClick={() => {
                countrySelectShort("SI");
                countrySelect("Slovenia");
                countrySelectFlag("slovenia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc slovenia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Slovenia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SB" ? "active" : null
              }`}
              data-option-value="SB"
              onClick={() => {
                countrySelectShort("SB");
                countrySelect("Solomon Islands");
                countrySelectFlag("solomonislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc solomonislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Solomon Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SO" ? "active" : null
              }`}
              data-option-value="SO"
              onClick={() => {
                countrySelectShort("SO");
                countrySelect("Somalia");
                countrySelectFlag("somalia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc somalia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Somalia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ZA" ? "active" : null
              }`}
              data-option-value="ZA"
              onClick={() => {
                countrySelectShort("ZA");
                countrySelect("South Africa");
                countrySelectFlag("southafrica");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southafrica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                South Africa
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GS" ? "active" : null
              }`}
              data-option-value="GS"
              onClick={() => {
                countrySelectShort("GS");
                countrySelect("South Georgia and the South Sandwich Islands");
                countrySelectFlag("southgeorgiaandthesouthsandwichislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southgeorgiaandthesouthsandwichislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                South Georgia and the South Sandwich Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SS" ? "active" : null
              }`}
              data-option-value="SS"
              onClick={() => {
                countrySelectShort("SS");
                countrySelect("South Sudan");
                countrySelectFlag("southsudan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southsudan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                South Sudan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "KR" ? "active" : null
              }`}
              data-option-value="KR"
              onClick={() => {
                countrySelectShort("KR");
                countrySelect("South Korea");
                countrySelectFlag("southkorea");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southkorea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                South Korea
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ES" ? "active" : null
              }`}
              data-option-value="ES"
              onClick={() => {
                countrySelectShort("ES");
                countrySelect("Spain");
                countrySelectFlag("spain");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc spain" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Spain
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "LK" ? "active" : null
              }`}
              data-option-value="LK"
              onClick={() => {
                countrySelectShort("LK");
                countrySelect("Sri Lanka");
                countrySelectFlag("srilanka");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc srilanka" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sri Lanka
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SD" ? "active" : null
              }`}
              data-option-value="SD"
              onClick={() => {
                countrySelectShort("SD");
                countrySelect("Sudan");
                countrySelectFlag("sudan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sudan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sudan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SR" ? "active" : null
              }`}
              data-option-value="SR"
              onClick={() => {
                countrySelectShort("SR");
                countrySelect("Suriname");
                countrySelectFlag("suriname");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc suriname" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Suriname
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SJ" ? "active" : null
              }`}
              data-option-value="SJ"
              onClick={() => {
                countrySelectShort("SJ");
                countrySelect("Svalbard and Jan Mayen");
                countrySelectFlag("svalbardandjanmayen");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc svalbardandjanmayen" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Svalbard and Jan Mayen
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SZ" ? "active" : null
              }`}
              data-option-value="SZ"
              onClick={() => {
                countrySelectShort("SZ");
                countrySelect("Swaziland");
                countrySelectFlag("swaziland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc swaziland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Swaziland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SE" ? "active" : null
              }`}
              data-option-value="SE"
              onClick={() => {
                countrySelectShort("SE");
                countrySelect("Sweden");
                countrySelectFlag("sweden");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sweden" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Sweden
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "CH" ? "active" : null
              }`}
              data-option-value="CH"
              onClick={() => {
                countrySelectShort("CH");
                countrySelect("Switzerland");
                countrySelectFlag("switzerland");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc switzerland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Switzerland
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "SY" ? "active" : null
              }`}
              data-option-value="SY"
              onClick={() => {
                countrySelectShort("SY");
                countrySelect("Syria");
                countrySelectFlag("syria");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc syria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Syria
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TW" ? "active" : null
              }`}
              data-option-value="TW"
              onClick={() => {
                countrySelectShort("TW");
                countrySelect("Taiwan");
                countrySelectFlag("taiwan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc taiwan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Taiwan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TJ" ? "active" : null
              }`}
              data-option-value="TJ"
              onClick={() => {
                countrySelectShort("TJ");
                countrySelect("Tajikistan");
                countrySelectFlag("tajikistan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tajikistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tajikistan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TZ" ? "active" : null
              }`}
              data-option-value="TZ"
              onClick={() => {
                countrySelectShort("TZ");
                countrySelect("Tanzania");
                countrySelectFlag("unitedrepublicoftanzania");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedrepublicoftanzania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tanzania
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TH" ? "active" : null
              }`}
              data-option-value="TH"
              onClick={() => {
                countrySelectShort("TH");
                countrySelect("Thailand");
                countrySelectFlag("thailand");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc thailand" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Thailand
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TL" ? "active" : null
              }`}
              data-option-value="TL"
              onClick={() => {
                countrySelectShort("TL");
                countrySelect("Timor-Leste");
                countrySelectFlag("timor-leste");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc timor-leste" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Timor-Leste
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TG" ? "active" : null
              }`}
              data-option-value="TG"
              onClick={() => {
                countrySelectShort("TG");
                countrySelect("Togo");
                countrySelectFlag("togo");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc togo" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Togo
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TK" ? "active" : null
              }`}
              data-option-value="TK"
              onClick={() => {
                countrySelectShort("TK");
                countrySelect("Tokelau");
                countrySelectFlag("tokelau");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tokelau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tokelau
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TO" ? "active" : null
              }`}
              data-option-value="TO"
              onClick={() => {
                countrySelectShort("TO");
                countrySelect("Tonga");
                countrySelectFlag("tonga");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tonga" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tonga
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TT" ? "active" : null
              }`}
              data-option-value="TT"
              onClick={() => {
                countrySelectShort("TT");
                countrySelect("Trinidad and Tobago");
                countrySelectFlag("trinidadandtobago");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc trinidadandtobago" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Trinidad and Tobago
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TN" ? "active" : null
              }`}
              data-option-value="TN"
              onClick={() => {
                countrySelectShort("TN");
                countrySelect("Tunisia");
                countrySelectFlag("tunisia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tunisia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tunisia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TR" ? "active" : null
              }`}
              data-option-value="TR"
              onClick={() => {
                countrySelectShort("TR");
                countrySelect("Turkey");
                countrySelectFlag("turkey");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Turkey
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TM" ? "active" : null
              }`}
              data-option-value="TM"
              onClick={() => {
                countrySelectShort("TM");
                countrySelect("Turkmenistan");
                countrySelectFlag("turkmenistan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkmenistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Turkmenistan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TC" ? "active" : null
              }`}
              data-option-value="TC"
              onClick={() => {
                countrySelectShort("TC");
                countrySelect("Turks and Caicos Islands");
                countrySelectFlag("turksandcaicosislands");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turksandcaicosislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Turks and Caicos Islands
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "XC" ? "active" : null
              }`}
              data-option-value="XC"
              onClick={() => {
                countrySelectShort("XC");
                countrySelect("Turkish Republic of Northern Cyprus");
                countrySelectFlag("northerncyprus");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northerncyprus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Turkish Republic of Northern Cyprus
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "TV" ? "active" : null
              }`}
              data-option-value="TV"
              onClick={() => {
                countrySelectShort("TV");
                countrySelect("Tuvalu");
                countrySelectFlag("tuvalu");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tuvalu" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Tuvalu
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "UG" ? "active" : null
              }`}
              data-option-value="UG"
              onClick={() => {
                countrySelectShort("UG");
                countrySelect("Uganda");
                countrySelectFlag("uganda");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uganda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Uganda
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "UA" ? "active" : null
              }`}
              data-option-value="UA"
              onClick={() => {
                countrySelectShort("UA");
                countrySelect("Ukraine");
                countrySelectFlag("ukraine");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ukraine" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Ukraine
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "AE" ? "active" : null
              }`}
              data-option-value="AE"
              onClick={() => {
                countrySelectShort("AE");
                countrySelect("United Arab Emirates");
                countrySelectFlag("uae");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uae" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                United Arab Emirates
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "GB" ? "active" : null
              }`}
              data-option-value="GB"
              onClick={() => {
                countrySelectShort("GB");
                countrySelect("United Kingdom");
                countrySelectFlag("unitedkingdom");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedkingdom" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                United Kingdom
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "US" ? "active" : null
              }`}
              data-option-value="US"
              onClick={() => {
                countrySelectShort("US");
                countrySelect("United States");
                countrySelectFlag("unitedstates");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedstates" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                United States
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "UY" ? "active" : null
              }`}
              data-option-value="UY"
              onClick={() => {
                countrySelectShort("UY");
                countrySelect("Uruguay");
                countrySelectFlag("uruguay");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uruguay" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Uruguay
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "UZ" ? "active" : null
              }`}
              data-option-value="UZ"
              onClick={() => {
                countrySelectShort("UZ");
                countrySelect("Uzbekistan");
                countrySelectFlag("uzbekistan");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uzbekistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Uzbekistan
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VU" ? "active" : null
              }`}
              data-option-value="VU"
              onClick={() => {
                countrySelectShort("VU");
                countrySelect("Vanuatu");
                countrySelectFlag("vanuatu");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc vanuatu" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Vanuatu
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VE" ? "active" : null
              }`}
              data-option-value="VE"
              onClick={() => {
                countrySelectShort("VE");
                countrySelect("Venezuela");
                countrySelectFlag("venezuela");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc venezuela" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Venezuela
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VN" ? "active" : null
              }`}
              data-option-value="VN"
              onClick={() => {
                countrySelectShort("VN");
                countrySelect("Vietnam");
                countrySelectFlag("vietnam");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc vietnam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Vietnam
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VG" ? "active" : null
              }`}
              data-option-value="VG"
              onClick={() => {
                countrySelectShort("VG");
                countrySelect("Virgin Islands, British");
                countrySelectFlag("virginislands,british");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc virginislands,british" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Virgin Islands, British
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "VI" ? "active" : null
              }`}
              data-option-value="VI"
              onClick={() => {
                countrySelectShort("VI");
                countrySelect("Virgin Islands, U.S.");
                countrySelectFlag("virginislandsus");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc virginislandsus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Virgin Islands, U.S.
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "WF" ? "active" : null
              }`}
              data-option-value="WF"
              onClick={() => {
                countrySelectShort("WF");
                countrySelect("Wallis and Futuna");
                countrySelectFlag("wallisandfutuna");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc wallisandfutuna" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Wallis and Futuna
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "EH" ? "active" : null
              }`}
              data-option-value="EH"
              onClick={() => {
                countrySelectShort("EH");
                countrySelect("Western Sahara");
                countrySelectFlag("westernsahara");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc westernsahara" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Western Sahara
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "YE" ? "active" : null
              }`}
              data-option-value="YE"
              onClick={() => {
                countrySelectShort("YE");
                countrySelect("Yemen");
                countrySelectFlag("yemen");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc yemen" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Yemen
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ZM" ? "active" : null
              }`}
              data-option-value="ZM"
              onClick={() => {
                countrySelectShort("ZM");
                countrySelect("Zambia");
                countrySelectFlag("zambia");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc zambia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Zambia
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCountryShort === "ZW" ? "active" : null
              }`}
              data-option-value="ZW"
              onClick={() => {
                countrySelectShort("ZW");
                countrySelect("Zimbabwe");
                countrySelectFlag("zimbabwe");
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc zimbabwe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                Zimbabwe
              </p>
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { InputCountry };
