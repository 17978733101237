import { t } from "i18next";
import React from "react";
import "./History.scss";
import { CalendarRange } from "./HistoryUi/calendarRange/CalendarRange";
import { Category } from "./HistoryUi/category/Category";
import { TransitionType } from "./HistoryUi/transitionType/TransitionType";

const History = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.TransactionHistory")}</div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content"
        data-scroll-lock-scrollable=""
      >
        <div className="componentFilterWrapper-bc">
          <div className="componentFilterBody-bc">
            <div className="componentFilterElsWrapper-bc">
              <div className="filter-form-w-bc">
                <div className="componentFilterBody-content">
                  <TransitionType />
                  <Category />
                  <CalendarRange />
                </div>
                <div className="u-i-p-c-filter-footer-bc">
                  <button className="btn a-color " type="submit" title="Show">
                    <span>{t("admin.Show")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="empty-b-text-v-bc">
          {t("admin.Therearenodatafortheselectedtimeperiod")}
        </p>
      </div>
    </>
  );
};

export { History };
