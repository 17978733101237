import { userBalanceType } from "../../../common/types/AdminInfo/userBalanceType";

type IsetUserBalance = {
  type: string;
  payload: userBalanceType;
};

function setUserBalance(payload: userBalanceType): IsetUserBalance {
  return {
    type: "SET_USER_BALANCE",
    payload,
  };
}

export { setUserBalance };
