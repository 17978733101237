import { t } from "i18next";
import React, { useState } from "react";

import "./CasinoSelectFilter.scss";

interface ICasinoSelectFilter {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}
const inputPlaceholder = t("admin.Searchcasinogame");

const CasinoSelectFilter = ({
  inputValue,
  setInputValue,
}: ICasinoSelectFilter) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Casino");
  const [inputOpen, setInput] = useState(false);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputMoveStatus = () => {
    setInput((prev) => !prev);
  };

  const handleSelectStatusMove = () => {
    setSelectOpen((prev) => !prev);
  };

  const handleSelectCategory = (item: string) => {
    setSelectedCategory(item);
    setSelectOpen(false);
  };

  return (
    <div className="casino-games-popup-header custom-popap-header">
      <div className="form-control-bc select s-small filled" tabIndex={0}>
        <div
          className="form-control-label-bc form-control-select-bc inputs "
          onClick={handleSelectStatusMove}
        >
          <span className="ellipsis">{t(`navHeader.${selectedCategory}`)}</span>
          <i
            className={`form-control-icon-bc bc-i-small-arrow-${
              selectOpen ? "up" : "down"
            }`}
          />
          <i className="form-control-input-stroke-bc" />
        </div>
        {selectOpen ? (
          <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
            <label
              className={`checkbox-control-content-bc ${
                selectedCategory === "Casino" ? "active" : ""
              }`}
              data-option-value={-1}
              onClick={() => handleSelectCategory("Casino")}
            >
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                {t("navHeader.Casino")}
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCategory === "LiveCasino" ? "active" : ""
              }`}
              data-option-value={28}
              onClick={() => handleSelectCategory("LiveCasino")}
            >
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                {t("navHeader.LiveCasino")}
              </p>
            </label>
            <label
              className={`checkbox-control-content-bc ${
                selectedCategory === "Games" ? "active" : ""
              }`}
              data-option-value={36}
              onClick={() => handleSelectCategory("Games")}
            >
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                {t("navHeader.Games")}
              </p>
            </label>
          </div>
        ) : null}
      </div>

      <div
        className={`sport-search-bc custom-popap-search ${
          inputOpen ? "active" : ""
        }`}
      >
        {inputOpen ? (
          <input
            type="text"
            className="ss-input-bc"
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={onInputChange}
          />
        ) : null}
        <div className="ss-icon-holder-bc" onClick={handleInputMoveStatus}>
          <i
            className={`ss-icon-bc bc-i-${
              inputOpen ? "close-remove" : "search"
            } `}
          />
        </div>
      </div>
    </div>
  );
};

export { CasinoSelectFilter };
