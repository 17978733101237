import { IMockTransitionTypeData } from "./TransitionType";

const MockTransitionTypeData: IMockTransitionTypeData[] = [
  { name: "All", dataId: 999 },
  { name: "Correction up", dataId: 301 },
  { name: "Correction down", dataId: 302 },
  { name: "Casino CashBack Bonus", dataId: 87 },
  { name: "Casino Bet Winning", dataId: 90 },
  { name: "Loyalty Point Exchange", dataId: 80 },
  { name: "CashBack Correction", dataId: 309 },
  { name: "Casino", dataId: 131 },
  { name: "Decreasing the winning", dataId: 16 },
  { name: "Deposit", dataId: 3 },
  { name: "Deposit request", dataId: 6 },
  { name: "Withdrawal request Payment", dataId: 1 },
  { name: "Bet Cashout", dataId: 2 },
  { name: "Increasing the winning", dataId: 7 },
  { name: "Rejected withdrawal request", dataId: 8 },
  { name: "Bet", dataId: 10 },
];

export { MockTransitionTypeData };
