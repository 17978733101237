import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import "./PromotionSlots.scss";
import { Promotion, promotionsMockSlots } from "./promotionsMockSlots";

const PromotionSlot = () => {
  const [haveComponent, setHaveComponent] = useState(false);

  const navigate = useNavigate();

  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const handleCloseInfo = () => {
    navigate(`/${language}/promotions/all`);
  };

  const handleOpenInfo = (item: Promotion) => {
    navigate(`/${language}/promotions/all/${item.id}/${item.path_name}`);
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (promotionsMockSlots[id]) {
        setHaveComponent(true);
      } else {
        setHaveComponent(false);
      }
    } else {
      setHaveComponent(false);
    }
  }, [id, language]);

  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseInfo();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <section className="promotionsList-bc">
      {Object.keys(promotionsMockSlots).map((item) => {
        return (
          <article
            className="promotionsListEl-bc"
            key={promotionsMockSlots[item].id}
            onClick={() => handleOpenInfo(promotionsMockSlots[item])}
          >
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title={promotionsMockSlots[item].name}
              src={promotionsMockSlots[item].img}
            />
            <h3 className="promotions-article-title ellipsis">
              {promotionsMockSlots[item].name}
            </h3>
          </article>
        );
      })}
      {haveComponent && id ? (
        <div className="popup-holder-bc windowed  promotion-details-popup ">
          <div className="popup-middleware-bc">
            <div className="popup-inner-bc" ref={wrapperRef}>
              {promotionsMockSlots[id].cart({
                navigate: navigate,
                language: language,
              })}
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export { PromotionSlot };
