import React from "react";
import { JackpotWrapper } from "./components/JackpotWrapper/JackpotWrapper";
import { JackpotWrapperPreload } from "./components/JackpotWrapper/preloader/JackpotWrapperPreload";
import { TournamentBlockPreloader } from "./components/TournamentBlock/preloader/TournamentBlockPreloader";
import { TournamentBlock } from "./components/TournamentBlock/TournamentBlock";
import { WinnerBlockPreloader } from "./components/WinnerBlock/preloader/WinnerBlockPreloader";
import { WinnerBlock } from "./components/WinnerBlock/WinnerBlock";

interface ICenterRow {
  loading: boolean;
}

const CenterRow = ({ loading }: ICenterRow) => {
  return (
    <div className="hm-row-bc " style={{ gridTemplateColumns: "8fr 2fr 2fr" }}>
      {loading ? <JackpotWrapperPreload /> : <JackpotWrapper />}
      {loading ? <TournamentBlockPreloader /> : <TournamentBlock />}
      {loading ? <WinnerBlockPreloader /> : <WinnerBlock />}
    </div>
  );
};

export { CenterRow };
