import { FavoritCasinoStateType } from "../../../common/types/Casino/FavoritCasinoStateType";

type setFavoritsLocalType = {
  type: string;
  payload: FavoritCasinoStateType;
};

function setFavoritsLocalDelete(payload: FavoritCasinoStateType): setFavoritsLocalType {
  return {
    type: 'SET_FAVORITS_LOCAL_DELETE',
    payload,
  };
}

export { setFavoritsLocalDelete };
