/* eslint-disable react/no-unescaped-entities */
import React from "react";

import "./ResponsibleActing.scss";

const ResponsibleActing = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">
          {" "}
          Sorumlu Oyunculuk
        </span>
        <div className="printAndDownload">
          <i className="print-icons  bc-i-Print" />
          <i className="print-icons  bc-i-download" />
        </div>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p>
          Her şeyden önce, oyun eğlence için olmalıdır; bizim için böyledir. Ne
          yazık ki, şans oyunları bazı insanlar için psikolojik, sosyal ve
          finansal sorunlara yol açabilecek bir bağımlılık haline gelir. Bunu
          önlemek ve oyunlarımızın oyuncularımız için keyifli kalmasını sağlamak
          için mümkün olan her şeyi yapıyoruz.
        </p>
        <p>
          Aşırı kullanım ve kumar bağımlılığı risklerini önlemek için çeşitli
          önlemler alıyoruz:
        </p>
        <ul>
          <li>Sorumluluk sahibi oyunculuk hakında bilgi.</li>
          <li>Kendini ihraç etme mekanizmalarının kullanımı.</li>
          <li>Yardımcı hizmetlerin kullanımı.</li>
          <li>Sorumlu reklamcılık.</li>
          <li>Kredi ile Oynama Yasağı.</li>
        </ul>
        <p>
          Maltbahis, Çuracao online bahis lisansına sahiptir. Bu açıdan
          Maltbahis, oyuncularına optimum bahis servisi sağlamak için oyuncuyu
          sorumlu bir şekilde oynamaya ve herhangi bir bağımlılık belirtisi için
          uyanık olmaya teşvik eder.
        </p>
        <p>&nbsp;</p>
        <p>Sorumlu bir oyuncu olmak için lütfen bunları dikkate alın:</p>
        <ul>
          <li>
            En baştan maksimum kayıp limiti belirleyin ve ona bağlı kalın.
          </li>
          <li>
            Ne pahasına olursa olsun oynamak yerine, şansınızı denemek amacıyla
            eğlence için oynayın.
          </li>
          <li>
            Oyun oynarken sadece sınırlı şekilde finansal riskler alın, bu da
            işlerin kontrolden çıkma olasılığını azaltacaktır. Bunun sadece bir
            oyun olduğunu unutmayın.
          </li>
          <li>
            Oynamaya devam etmek için asla arkadaşlarınızdan veya finansal bir
            kurumdan borç para almayın.
          </li>
          <li>Sınırlı sürede oynayın.</li>
          <li>Sık sık ara verin.</li>
          <li>Başka aktiviteler ile değişimli olarak oynayın.</li>
          <li>
            Kızgın, depresif veya kafası karışmış hissediyorsanız oynamayın.
          </li>
          <li>
            Alkol, uyuşturucu veya psikotrop ilaçların etkisi altında oynamayın.
          </li>
          <li>
            Sadece eğlence için bulundurduğunuz parayı kullanın. Faturalarınızı
            (kira, elektrik, ısıtma) ödemek için gerekli olan genel gelirinizi
            asla kullanmayın.
          </li>
          <li>
            Oyunu gündelik veya psikolojik sorunlarınızdan kaçmanın bir yolu
            olarak görmeyin.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h2>Reşit Olmayanların Korunması</h2>
        <p>
          Çuracao hukuku, 21 yaşın altındakilerin online bahis yapmasını
          yasaklamaktadır. Maltbahis hiçbir pazarlama kampanyasında küçük
          çocukları hedeflemeyecektir.
        </p>
        <p>Ebeveynlere şiddetle tavsiye edilir:</p>
        <ul>
          <li>
            Online bahis sitelerine girişi engelleyen internet filtreleri
            yükleyin.
          </li>
          <li>Asla bir bahis sayfasını açık bırakmayın.</li>
          <li>Kullanıcı adınızı ve şifrenizi güvenli bir yerde tutun.</li>
          <li>
            Bahisle ilgili yasal durumu ve bağımlılık tehlikesini açıklayın.
          </li>
        </ul>
        <p>
          Tüm önlemlere rağmen Maltbahis bir oyuncunun yaşından şüphelenirse,
          herhangi bir noktada kimlik talep edebilir ve yaşını kanıtlayana kadar
          hesabı askıya alabilir.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h2>Sorumlu Oyun</h2>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <br />
          Online bahis, potansiyel finansal kazançları olabilen bir eğlence
          biçimidir. Oyuncu ya da aileleri için hiçbir şekilde bir gelir kaynağı
          ya da borcunu ödeme yöntemi değildir. Dolayısıyla, kullanıcının ölçülü
          oynaması ve kazandığında veya kaybettiğinde ne zaman durması
          gerektiğini bilmesi gerekmektedir. Maltbahis, hesabınızı yönetmenize
          ve sınırlarınızı belirlemenize yardımcı olacak belirli kaynaklara
          sahiptir. 'Hesabım' bölümü, aşamayacağınız bahis miktarlarınızı
          belirlemenizi sağlar. Eğer bahis tutarı bu sınırı aşarsa, web sitesi
          bahsin yapılmasını otomatik olarak durdurur.&nbsp;
        </p>
      </div>
    </div>
  );
};

export { ResponsibleActing };
