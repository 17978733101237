type IsetUserBalance = {
  type: string;
  payload: string;
};

function setUserInit(): IsetUserBalance {
  return {
    type: "SET_USER_INIT",
    payload: "",
  };
}

export { setUserInit };
