import React, { useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";

import "./DropWins.scss";

interface IDropWins {
  navigate: NavigateFunction;
  language: string;
}

const DropWins = ({ navigate, language }: IDropWins) => {
  const handleCloseInfo = () => {
    navigate(`/${language}/promotions/all`);
  };

  return (
    <>
      <i
        className="e-p-close-icon-bc bc-i-close-remove"
        onClick={handleCloseInfo}
      />
      <div className="overlay-header">Drop &amp; Wins</div>
      <div className="promotion-details-container">
        <div className="promotion-details-header">
          <img
            src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_2216ffd50f677b09c4634e5cfdfe6d85.webp"
            className="promotion-details-img"
            alt=""
            title="Drop & Wins"
          />
        </div>
        <div className="promotion-details-info" data-scroll-lock-scrollable="">
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              DROPS &amp; WINS SLOTLAR
            </div>
            <div className="spoiler-content">
              <p>
                <strong>➥ Haftalık En Yüksek Çarpan Turnuva Kuralları</strong>
                <br />
                1. Liderlik sıralaması, oyuncunun seçili oyunlarda tek spinde
                kazanılan miktarın oynanılan bahse oranına göre olacaktır.
                <br />
                2. Ödüller oyuncunun bahis aldığı miktarın kazandığı en yüksek
                çarpana oranına göre dağıtılacaktır.
                <br />
                3. Oyuncunun etkinliğe dahil olabilmesi için herhangi bir
                minimum bahis miktarı yoktur.
                <br />
                4. Liderlik sıralaması gerçek zamanlı olarak güncellenir ve
                oyuncular bu sıralamayı seçili oyunlara giriş yaptıklarında
                anlık kontrol edebilir.
                <br />
                5. Eğer iki ya da daha fazla oyuncu oyunu aynı puan sayısı ile
                tamamlarsa, ilk tamamlayan oyuncu skor tablosunda daha üst
                sıraya yerleşir.
              </p>
              <p>
                <strong>➥ Haftalık Normal Turnuva Kuralları</strong>
                <br />
                1. Liderlik sıralaması, turnuva dahilindeki oyunlarda tek spinde
                en yüksek kazanç elde edilen ellere göre sıralanmaktadır.
                <br />
                2. Ödüller sabit miktarlarda ödenecektir.
                <br />
                3. Kullanıcıların haftalık turnuvalara katılabilmesi için
                turnuvanın geçerli olduğu oyunlarda minimum 0.5 EUR bahis alması
                gerekmektedir.
                <br />
                4. Liderlik sıralaması gerçek zamanlı olarak güncellenir ve
                oyuncular bu sıralamayı seçili oyunlara giriş yaptıklarında
                anlık kontrol edebilir.
                <br />
                5. Eğer iki ya da daha fazla oyuncu oyunu aynı puan sayısı ile
                tamamlarsa, ilk tamamlayan oyuncu skor tablosunda daha üst
                sıraya yerleşir.
              </p>
              <p>
                <strong>➥ Günlük Sürpriz Çarpan Ödül Düşüşü Kuralları</strong>
                <br />
                1. Her promosyon yedi (7) güne ait Günlük Ödülleri kapsar.
                <br />
                2. Şartlara uygun her bir (1) spin,her &nbsp;promosyonun ödül
                havuzundaki tek bir (1) ödülü kazanabilir.
                <br />
                3. Ödül Havuzlarına özel kurallar ve ödül dağılımları seçili
                oyunlar içerisinde yer alır. Sürpriz çarpan ödüller oyuncular
                arasında rastgele dağıtılacaktır.
                <br />
                4. Oyuncunun etkinliğe dahil olabilmesi için herhangi bir
                minimum bahis miktarı yoktur.
                <br />
                5. Bir oyuncu her gün Ödül Havuzundaki farklı birden fazla ödülü
                kazanabilir.
                <br />
                6. Günlük En Yüksek Çarpan Sürpriz Ödülleri, Haftalık En Yüksek
                Çarpan Turnuvasıyla aynı tarihlerde gerçekleşmektedir.
              </p>
              <p>
                <strong>➥ Günlük Normal Sürpriz Ödül Kuralları</strong>
                <br />
                1. Her promosyon yedi (7) güne ait Günlük Ödülleri kapsar.
                <br />
                2. Şartlara uygun her bir (1) spin,her &nbsp;promosyonun ödül
                havuzundaki tek bir (1) ödülü kazanabilir.
                <br />
                3. Ödül Havuzlarına özel kurallar ve ödül dağılımları seçili
                oyunlar içerisinde yer alır. Sürpriz çarpan ödüller oyuncular
                arasında rastgele dağıtılacaktır.
                <br />
                4. Kullanıcıların günlük normal sürpriz ödüllere katılabilmesi
                için turnuvanın geçerli olduğu oyunlarda minimum 0.5 EUR bahis
                yapması gerekmektedir.
                <br />
                5. Ödül dağıtımları günlük turnuva süresi dolana ya da ödenecek
                ödül kalmayana dek devam edecektir.
                <br />
                6. Günlük Normal Sürpriz Ödülleri, &nbsp;Haftalık Normal
                Turnuvasıyla aynı tarihlerde gerçekleşmektedir.
              </p>
              <p>
                <strong>
                  ➥ Turnuva Haftaları 5- 8 (Haftalık ve Günlük Çarpan Turnuva
                  İçerir)
                </strong>
                <br />
                <strong>Geçerli oyunlar:</strong>&nbsp;Wolf Gold™, Mustang
                Gold™, Sweet Bonanza™, John Hunter and the Tomb of the Scarab
                Queen™, Great Rhino Megaways™, John Hunter and the Book of Tut™,
                The Dog House™, The Dog House Megaways™, Chilli Heat™, Fruit
                Party™, Big Bass Bonanza™, Madame Destiny Megaways™, Gems
                Bonanza™, Wild Wild Riches™, Wild West Gold™, Gates of Olympus™,
                Power of Thor Megaways™, Floating Dragon Hold&amp;Spin™, The
                Hand of Midas™, Buffalo King Megaways™, Juicy Fruits™, Bigger
                Bass Bonanza™, Starlight Princess™, Joker’s Jewels™, Big Bass
                Bonanza Megaways™, Book of The Fallen™, 5 Lions Megaways™,
                Buffalo King™, Cleocatra, Extra Juicy Megaways™, Wild West Gold
                Megaways, Big Bass Splash, Sugar Rush, Big Bass – Keeping it
                Reel™, Wild Wild Riches Megaways™, Wild West Duels™, The Dog
                House Multihold™, Mochimon™, Big Bass Bonanza – Hold &amp;
                Spinner™, The Red Queen™, Gods of Giza™, Rabbit Garden™, African
                Elephant™, Wild Celebrity Bus Megaways™, 3 Dancing Monkeys™,
                Jane Hunter and the Mask of Montezuma™, Kingdom of The Dead™,
                Excalibur Unleashed™, Wild Bison Charge™, Knight Hot Spotz™
              </p>
              <p>
                <strong>
                  5. Hafta: 3 Mayıs 2023 Çarşamba 21:01 CEST – 10 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  6. Hafta: 10 Mayıs 2023 Çarşamba 21:01 CEST – 17 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  7. Hafta: 17 Mayıs 2023 Çarşamba 21:01 CEST – 24 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  8. Hafta: 24 Mayıs 2023 Çarşamba 21:01 CEST – 31 Mayıs 2023
                  Çarşamba 20:59
                </strong>
              </p>
              <p>
                <strong>Haftalık Ödül Çarpan Dağılımı (Sıralama)</strong>
                <br />
                1. | 25.000x
                <br />
                2. | 10.000x
                <br />
                3. - 5. | 5.000x
                <br />
                6. - 10. | 2.000x
                <br />
                11. - 20. | 1.000x
                <br />
                21. - 50. | 500x
                <br />
                51. - 150. | 200x
                <br />
                151. – 350. | 100x
                <br />
                351. – 650. | 50x
                <br />
                651. – 2800. | 20x
                <br />
                2801. – 8000. | 10x
                <br />
                8001. – 12000. | 10 Adet Bedava Döndürme
              </p>
              <p>
                <strong>Günlük Çarpan Ödül Dağılımı (Adet)</strong>
                <br />
                1 | 2500x
                <br />
                2 | 1000x
                <br />
                5 | 500x
                <br />
                12 | 250x
                <br />
                30 | 100x
                <br />
                100 | 50x
                <br />
                350 | 20x
                <br />
                800 | 10x
                <br />
                400 | 10 Adet Bedava Döndürme
              </p>
              <p>
                <br />
                <strong>➥ Katılım Genel Kural ve Şartları</strong>
                <br />
                1. Bu promosyon Pragmatic Play networkü tarafından sağlayıcı
                hizmetini alan tüm siteler için ortak olarak düzenlenmektedir.
                &nbsp; &nbsp;
                <br />
                2. Promosyon 6 Mart 2024 tarihine kadar haftalık ve günlük
                turnuvalar şeklinde devam edecektir. Turnuva programları ve
                detayları, turnuva tarihlerinden 1 hafta önce duyurulacaktır.
                <br />
                3. Promosyona katılabilmek için belirtilen oyunlarda, oyun
                içindeki ekrandan katılım sağlamanız gerekmektedir. Promosyona
                dahil olan oyunlara Slotlar sayfasında ‘Drops &amp; Wins’
                sekmesinden ulaşılabilir. Ayrıca ilgili oyunların üzerinde
                ‘Drops &amp; Wins’ logosu da yer almaktadır.
                <br />
                4. Promosyon ödülü her ay 42.000.000₺ olup bu tutar tüm
                turnuvalar için toplam ödül havuzunu ifade etmektedir.
                <br />
                5. Ana bakiyeniz ile katılabilirsiniz, bonus para ile alınan
                bahisler turnuva kapsamı dışında tutulacaktır.
                <br />
                6. Turnuvaların başlangıç ve bitiş saatleri GMT(CEST)’ye göre
                belirlenmiş ve promosyon detaylarında İstanbul - Türkiye GMT+3
                şeklinde ifade edilmiştir.
                <br />
                7. Hak kazanılan ödüller sağlayıcı tarafından doğrulandıktan
                maksimum 72 saat sonrasına kadar hesaplara eklenmiş olacaktır.
                Kazançlar için herhangi bir çevrim şartı bulunmamaktadır.
                <br />
                8. Türkçe metinler sadece bilgilendirme amacıyla hazırlanmıştır
                ve herhangi bir anlaşmazlık durumunda promosyonun oyun içi
                turnuva kuralları bağlayıcı olacaktır.
                <br />
                9. Maltbahis ve Pragmatic Play bu promosyonu haber vermeksizin
                iptal etme, geri çekme veya kural ve şartlarını değiştirme
                hakkını saklı tutar. Ayrıca gerekli gördüğü kişilere ait
                hesaplara bonus vermeme hakkını da saklı tutar.
                <br />
                10.Maltbahis&nbsp;uygun gördüğü an üyelerinden Kimlik, İkamet
                Belgesi(son 3 aya ait) vb. belgeleri isteme hakkına sahiptir. Bu
                belgelerin kullanıcı tarafından ibraz edilmemesi durumunda
                Maltbahis oyuncunun kazançlarını bloke etme hakkını kendinde
                saklı tutar.
                <br />
                11.Kullanıcının kural dışı işlem hareketlerinde bulunduğu tespit
                edilirse, Maltbahis söz konusu kazançları iptal etme hakkını
                kendinde saklı tutar.
                <br />
                12.Herhangi bir promosyonun güncel kural ve şartlarını takip
                etme yükümlülüğü oyuncunun kendisine aittir.
                <br />
                13.Bu kurallara Maltbahis&nbsp;Genel Kural ve Şartları da
                dahildir.
              </p>
            </div>
          </div>
          <div className="spoiler">
            <input type="checkbox" />
            <div className="spoiler-title">
              <div className="hide-icon spoiler-toggle">&nbsp;</div>
              DROPS &amp; WINS CANLI CASİNO
            </div>
            <div className="spoiler-content">
              <p>
                <strong>➥ Haftalık Blackjack Turnuvaları</strong>
                <br />
                <strong>Sıra | Ödül</strong>
                <br />
                1. | €4,000
                <br />
                2. | €2,000
                <br />
                3. | €1,000
                <br />
                4.-10. | €500
                <br />
                11.-30. | €100
                <br />
                31.-140. | €50
                <br />
                141.-500. | €25
              </p>
              <p>
                <br />
                <strong>Kural ve Şartlar;</strong>
                <br />
                1.Oyuncuların Blackjack turnuvasına katılmaya hak
                kazanabilmeleri için, gerçek parayla minimum 10 EUR tutarında
                bahis alması gereklidir.
                <br />
                2.Haftalık Blackjack Turnuvası için sadece Katılan Bahis
                kriterlerini karşılayan Ana Bahisler ve Arka Bahisler (aşağıda
                tanımlandığı gibi) değerlendirilebilir.
                <br />
                3. Bir ana bahis, normal düz bahis (“Ana Bahisler”) olarak
                tanımlanacaktır.
                <br />
                4. Arka bahisler, En İyi El, Sigorta ve Blackjack (“Arka
                Bahisler”) olarak tanımlanacaktır.
                <br />
                5. Bir oyuncu, bir (1) oyun turunda Yan Bahis(ler) ile birlikte
                Katılan Bahis kriterlerini karşılayan herhangi bir Ana
                Bahis(ler) ve/veya Arka Bahis koyarsa, sadece bu Ana Bahis(ler)
                ve/veya Arka Bahisler Haftalık Blackjack Turnuvası skoru için
                değerlendirilebilir.
                <br />
                6. Haftalık Blackjack Turnuvasında yan bahisler, Mükemmel
                Çiftler (Mükemmel Çift, Renkli Çift, Karışık Çift) ve 21+3 (Aynı
                Renk Üçlü, Sıralı Renk, Üçlü, Kent, Renk) (“Yan Bahisler”)
                olarak tanımlanacaktır.
                <br />
                7. Bir oyuncunun bir Blackjack masasında birden fazla koltuk
                alması durumunda, toplam Katılan Bahis ve tüm koltuklardaki
                toplam Katılan Kazanç, Haftalık Blackjack Turnuvası puanı olarak
                değerlendirilebilir.
                <br />
                8. Bir oyuncu, katılan bir masaya birden fazla Ana Bahis ve/veya
                birden fazla Arka Bahis koyarsa, toplam Katılan Bahis ve toplam
                Katılan Kazanç, Haftalık Blackjack Turnuvası puanı için dikkate
                alınabilir.
                <br />
                9. Bir oyuncu bir (1) oyun turunda toplam Katılan bahsin bir
                katına (1x) tam olarak eşit bir kazanç (kazanç + kazanan bahsin
                bahsi) alırsa, oyun turu Haftalık Blackjack Turnuvası için
                dikkate alınmayacaktır.&nbsp;
                <br />
                10. Bir oyuncunun Ardışık Katılan Kazanç serisi varsa ve toplam
                Katılan Bahsin bir katına (1x) eşit bir galibiyet (kazanç +
                kazanan bahsin bahsi) aldıysa, Ardışık Katılan Kazanç serisi
                duraklatılır ve aynı sayıda kalır ve oyuncu tekrar Katılan
                Bahisler oynamaya başlar başlamaz devam edecektir.
                <br />
                11. Pragmatic Play’in kendi takdirine bağlı olarak belirlenen
                yanlış bir oyun turu sonucunun ilgili oyunda kaydedilmesi
                durumunda, Ardışık Katılan Kazanç serisi ve ilgili Haftalık
                Turnuva liderlik tablosundaki puanlar ayarlanmayacaktır.
              </p>
              <p>
                <br />
                Günlük Düşüş Turnuvaları
              </p>
              <p>
                <strong>Ödül Havuzu</strong>
                <br />
                <strong>Sayı | Günlük Ödül</strong>
                <br />
                1x | €1000
                <br />
                2x | €500
                <br />
                5x | €100
                <br />
                12x | €50
                <br />
                110x | €20
                <br />
                370x | €10
              </p>
              <p>
                <br />
                <strong>Kural ve Şartlar</strong>
                <br />
                1.Oyuncuların Günlük Ödül Düşüşlerine katılmaya hak
                kazanabilmeleri için, gerçek parayla minimum 2 EUR tutarında
                bahis alması gereklidir.(“Eleme Bahsi”).
                <br />
                2. Alınan her geçerli bahis, her Günlük Ödül Düşüşü sırasında
                ödül havuzundan bir (1) ödülü tetikleyebilir.
                <br />
                3.Rastgele Günlük Ödül düşüş kuralları ve ödül tabloları,
                geçerli oyunlarda/masalarda görüntülenebilecektir. Ödül tablosu
                eş zamanlı olarak güncellenecek, mevcut sıralamalar ve kalan
                ödül sayısı anlık görüntülenebilecektir.
              </p>
              <p>
                <strong>
                  5. Hafta: 3 Mayıs 2023 Çarşamba 21:01 CEST – 10 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  6. Hafta: 10 Mayıs 2023 Çarşamba 21:01 CEST – 17 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  7. Hafta: 17 Mayıs 2023 Çarşamba 21:01 CEST – 24 Mayıs 2023
                  Çarşamba 20:59
                  <br />
                  8. Hafta: 24 Mayıs 2023 Çarşamba 21:01 CEST – 31 Mayıs 2023
                  Çarşamba 20:59
                </strong>
              </p>
              <p>
                <strong>Genel Kural ve Şartlar;</strong>
                <br />
                1. Pragmatic Play Drops &amp; Wins Haftalık Blackjack Turnuvalar
                ve &nbsp;Günlük Ödül Düşüşü turnuvaları ile seçili oyunlarda, 6
                Mart 2024 tarihine kadar her hafta devam edecektir.
                <br />
                2. Promosyon Dönemi dahilindeki her Katılım Haftası; burada
                Haftalık &nbsp;Blackjack Turnuvası veya Günlük Ödül Düşüşü canlı
                casino oyunları veya Canlı Casino Oyunu kategorisindeki belirli
                bir oyuna ayrılmıştır.&nbsp;
              </p>
              <p>
                ✭ Haftalık Blackjack Turnuvası&nbsp;
                <br />✭ Günlük Düşüş Turnuvası
              </p>
              <p>
                3. Promosyonun dört haftalık turnuvasından herhangi birine
                katılmak için, bir oyuncu katılan masalardan/oyunlardan herhangi
                birini açmalı ve her Haftalık Turnuvaya ayrı ayrı
                katılmalı/kayıt yaptırmalıdır.
                <br />
                4. Tüm tutarlar oyun içinde Oyuncunun Para Birimi cinsinden
                gösterilir&nbsp;
                <br />
                5. Her ayrı Haftalık Turnuvadaki ödüller, oyuncular tarafından
                sabit nakit tutarı şeklinde ve ilgili Ödül Tablosunda
                belirtildiği şekilde kazanılabilir.
                <br />
                6. Haftalık Turnuvaların kuralları, katılan masalarda/oyunlarda
                yerleşiktir.
                <br />
                7. Maltbahis&nbsp;ve Pragmatic Play bu promosyonu haber
                vermeksizin iptal etme, geri çekme veya kural ve şartlarını
                değiştirme hakkını saklı tutar. Ayrıca gerekli gördüğü kişilere
                ait hesaplara bonus vermeme hakkını da saklı tutar.
                <br />
                8. Maltbahis&nbsp;uygun gördüğü an üyelerinden Kimlik, İkamet
                Belgesi(son 3 aya ait) vb. belgeleri isteme hakkına sahiptir. Bu
                belgelerin kullanıcı tarafından ibraz edilmemesi durumunda
                Maltbahis&nbsp;oyuncunun kazançlarını bloke etme hakkını
                kendinde saklı tutar.
                <br />
                9. Kullanıcının kural dışı işlem hareketlerinde bulunduğu tespit
                edilirse, Maltbahis&nbsp;söz konusu kazançları iptal etme
                hakkını kendinde saklı tutar.
                <br />
                10. Herhangi bir promosyonun güncel kural ve şartlarını takip
                etme yükümlülüğü oyuncunun kendisine aittir.
                <br />
                11. Bu kurallara Maltbahis&nbsp;Genel Kural ve Şartları da
                dahildir.
              </p>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export { DropWins };
