import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../components/layout";
import "./Live.scss";
import Helmet from "react-helmet";
import Foo from "../../test.d";
import { useDispatch } from "react-redux";
import { setModalLogin } from "../../store/action/modals/setmodallogin";
import { useTypeSelector } from "../../hooks/useTypeSelector";
const Live = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [panelOnep, setPanelOpen] = useState(true);
  const bearerToken = localStorage.getItem("token") || "1234";
  const user = useTypeSelector((data) => data.accountInfoReducer.user);
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const handleDepositOpen = () => {
    const basePath = location.pathname;
    const searchParams = new URLSearchParams({ open: "true" }).toString();

    navigate(`${basePath}?${searchParams}`);
  };

  const openLogin = () => {
    dispatch(setModalLogin(true));
  };

  const balance = useTypeSelector(
    (data) => data.accountInfoReducer.balance.balance
  );

  const balanceInit = useTypeSelector(
    (data) => data.accountInfoReducer.balance.init
  );
  useEffect(() => {
    if (balanceInit) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const APIFrame = Foo.APIFrame;
        const options = {
          serverUrl: "https://sport.funexx.com",
          token: bearerToken,
          externalSiteId: "veyj13",
          userId: user.api_user_id,
          locale: language,
          currency: balance.currency,
          theme: "dark",
          dataSource: "tnm",
          desktop: true,
          balance: balance.amount,
          externalCssPath: `${window.location.origin.toString()}/sportobx.css`,
          loginHandler: () => {
            openLogin;
          },
          depositHandler: () => {
            handleDepositOpen;
          },
        };

        APIFrame.init(options);

      }, 2000);
    }
  }, [balanceInit]);

  

  return (
    <Layout
      addClasses={`compact-footer page-full-size live ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus={"live"}
      betsLipPage={"sport"}
    >
      {/*<CenterContent params={params} />*/}
      <div>
        <div id="sport-container" className=" custom-container"></div>
      </div>
      <Helmet>
        <script
          type="text/javascript"
          src="https://sport.funexx.com/js/widget-integration-new.js"
        />
      </Helmet>
    </Layout>
  );
};

export { Live };
