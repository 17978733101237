import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./SelectGames.scss";

interface ISelectGames {
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>;
  seacrhGames: string;
}

const SelectGames = ({ setSeacrhGames, seacrhGames }: ISelectGames) => {
  const wrapperRef = useRef(null);
  const [inputActive, setInputActive] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeacrhGames(event.target.value);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setInputActive(false);
    }
  };

  const handleOpenInput = () => {
    setInputActive((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="casino-title-search" ref={wrapperRef}>
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis custom-font-size">{t("casino.Games")}</h3>
      </div>
      <div
        className={`sport-search-bc ${
          inputActive ? "active" : ""
        } search-custom`}
      >
        {inputActive ? (
          <input
            type="text"
            className="ss-input-bc"
            placeholder="Search provider..."
            defaultValue=""
            value={seacrhGames}
            onChange={handleInputChange}
          />
        ) : null}
        <div
          className="ss-icon-holder-bc custom-btn-positione"
          onClick={handleOpenInput}
        >
          <i
            className={`ss-icon-bc bc-i-${
              inputActive ? "close-remove" : "search"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export { SelectGames };
