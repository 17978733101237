import item1 from "./accets/Mega Şeker Festivali.webp";
import item2 from "./accets/We Transfer.webp";
import item3 from "./accets/Dropandwins.webp";
import item4 from "./accets/Wazdan.webp";
import item5 from "./accets/Yat.webp";
import item6 from "./accets/Zeus vs Hades.webp";
import item7 from "./accets/Casino Hoşgeldin Bonusu.webp";
import item8 from "./accets/Coin  Mefete Pep.webp";
import item9 from "./accets/royality.webp";
import item10 from "./accets/Sporgenel.webp";
import item11 from "./accets/Kombo+.webp";
import item12 from "./accets/Spor Megajackpot.webp";
import item13 from "./accets/%15 Çevrimsiz Spor .webp";
import item14 from "./accets/%50 Freebet.webp";
import item15 from "./accets/Anlık %10 Spor Kayıp Bonusu.webp";
import item16 from "./accets/%10 Casino Yatırım.webp";
import item17 from "./accets/Günlük Nakit Geri Ödeme.webp";
import item18 from "./accets/Maltbahis'ten double discount.webp";
import item19 from "./accets/Sınırsız Freespin.webp";

type mockCouruselMainType = {
  id: number;
  img: string;
  title: string;
};

const mockCouruselMain: mockCouruselMainType[] = [
  {
    id: 1,
    img: item1,
    title: "Mega Şeker Festivali",
  },
  {
    id: 2,
    img: item2,
    title: "We Transfer",
  },
  {
    id: 3,
    img: item3,
    title: "Dropandwins",
  },
  {
    id: 4,
    img: item4,
    title: "Wazdan",
  },
  {
    id: 5,
    img: item5,
    title: "Yat",
  },
  {
    id: 6,
    img: item6,
    title: "Zeus vs Hades",
  },
  {
    id: 7,
    img: item7,
    title: "Casino Hoşgeldin Bonusu",
  },
  {
    id: 8,
    img: item8,
    title: "Coin  Mefete Pep",
  },
  {
    id: 9,
    img: item9,
    title: "royality",
  },
  {
    id: 10,
    img: item10,
    title: "Sporgenel",
  },
  {
    id: 11,
    img: item11,
    title: "Kombo+",
  },
  {
    id: 12,
    img: item12,
    title: "Spor Megajackpot",
  },
  {
    id: 13,
    img: item13,
    title: "%15 Çevrimsiz Spor ",
  },
  {
    id: 14,
    img: item14,
    title: "%50 Freebet",
  },
  {
    id: 15,
    img: item15,
    title: "Anlık %10 Spor Kayıp Bonusu",
  },
  {
    id: 16,
    img: item16,
    title: "%10 Casino Yatırım",
  },
  {
    id: 17,
    img: item17,
    title: "Günlük Nakit Geri Ödeme",
  },
  {
    id: 18,
    img: item18,
    title: "Maltbahis'ten double discount",
  },
  {
    id: 19,
    img: item19,
    title: "Sınırsız Freespin",
  },
];

export { mockCouruselMain };
export type { mockCouruselMainType };
