import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";
import { setModalLogin } from "../../../../store/action/modals/setmodallogin";
import { setModalRegister } from "../../../../store/action/modals/setmodalregister";
import { ModalAuth } from "./components/modalAuth/ModalAuth";
import { ModalRegister } from "./components/modalRegister/ModalRegister";
import "./PopupManager.scss";

interface IPopupManager {
  role: string;
}

const PopupManager = ({ role }: IPopupManager) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const modalRegisterOpen = useTypeSelector(
    (data) => data.modalsReducer.modalRegisterOpen
  );
  const modalAuthOpen = useTypeSelector(
    (data) => data.modalsReducer.modalAuthOpen
  );


  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      dispatch(setModalRegister(false));
      dispatch(setModalLogin(false));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const handleCloseModal = () => {
    dispatch(setModalRegister(false));
    dispatch(setModalLogin(false));
  };
  return (
    <div
      className={`popup-holder-bc  ${
        modalAuthOpen || modalRegisterOpen ? "full-screen" : "windowed hidden"
      } content-manager `}
    >
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc" ref={wrapperRef}>
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleCloseModal}
          />
          {modalAuthOpen ? <ModalAuth /> : null}
          {modalRegisterOpen ? <ModalRegister /> : null}
        </div>
      </div>
    </div>
  );
};

export { PopupManager };
