import React, { useState } from "react";
import { Layout } from "../../components/layout";
import "./MaltbahisCarc.scss";

const MaltbahisCarc = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  return (
    <Layout
      addClasses={`page-full-size ${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } hide-tab-navigation-footer-mobile compact-footer ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="maltbahiscarc"
      betsLipPage={"betsLipPage"}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <iframe
        allowFullScreen={true}
        allow="autoplay; microphone *"
        title="https://luckywheel.maltbahis275.com/?langId=en&partnerId=1873892&SourceId=1"
        src="https://luckywheel.maltbahis275.com/?langId=en&partnerId=1873892&SourceId=1"
        style={{ height: "100%", width: "100%" }}
      />
    </Layout>
  );
};

export { MaltbahisCarc };
