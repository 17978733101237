import { userBalanceType } from "../../common/types/AdminInfo/userBalanceType";
import { UserInformation } from "../../common/types/AdminInfo/UserInformation";

interface userInfo {
  email: string;
  id: string;
}

export interface stateUser {
  user: UserInformation;
  role: string;
  balance: userBalanceType;
}

const initialState: stateUser = {
  user: {
    id: 15,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    passport: "",
    country: "",
    state: "",
    city: undefined,
    address: "",
    zipcode: null,
    language: "",
    documents: null,
    currency: null,
    identification_number: null,
    gender: "",
    birthday: "",
    available_currencies: ["TRY", "USD", "EUR"],
    documents_verified: false,
    email_verified_at: null,
    phone_verified: null,
    api_user_id: 12653,
    google2fa_enable: 0,
    hide_nulled_balance: false,
    crypto_print: false,
  },
  role: "unauth",
  balance: {
    balance: {
      amount: 0,
      amount_bonus: 0,
      currency: "",
    },
    cryptoBalance: null,
    fiatBalance: null,
    active_bonus: null,
    init: false,
    extra_data: {
      game_token: "",
      softswiss_freespin: false,
      crypto_payment: null,
    },
  },
};

type authAction = {
  type: string;
  payload: unknown;
};

const accountInfoReducer = (state = initialState, action: authAction) => {
  switch (action.type) {
    case "SET_AUTH":
      return { ...state, role: action.payload };
    case "SET_USER_INFO":
      return { ...state, user: action.payload };
    case "SET_USER_BALANCE":
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { ...state, balance: { ...action.payload, init: true } };
    case "SET_USER_INIT":
      return { ...state, balance: { ...state.balance, init: true } };
    default:
      return state;
  }
};

export { accountInfoReducer };
export type { userInfo };
