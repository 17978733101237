import { t } from "i18next";
import React from "react";
import { SelectTypeId } from "./components/VerifyAccountUi/selectTypeId/SelectTypeId";
import "./VerifyAccount.scss";

const VerifyAccount = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.VerifyAccount")}</div>
      <div
        className="u-i-edit-verify-account-page-bc"
        data-scroll-lock-scrollable=""
      >
        <form encType="multipart/form-data" noValidate={false}>
          <div className="u-i-p-info-text-holder-bc">
            <div className="u-i-p-info-text-bc">
              {t("admin.verifAccoutRouls")}
            </div>
          </div>
          <div className="verify-form">
            <SelectTypeId />
            <div className="u-i-p-control-item-holder-bc">
              <div className="form-control-bc has-icon file filled">
                <label className="form-control-label-bc form-control-label-file-bc inputs">
                  <input
                    type="file"
                    className="form-control-input-bc"
                    name="passportImage"
                    step={0}
                    multiple={true}
                    defaultValue=""
                  />
                  <span className="form-control-icon-holder-bc">
                    <i className="form-control-icon-bc bc-i-upload" />
                  </span>
                  <span className="form-control-filename-bc ellipsis">
                    {t("admin.ChooseFile")}
                  </span>
                  <span className="form-control-title-bc ellipsis">
                    {t("admin.Attachfile")}
                  </span>
                </label>
              </div>
            </div>
            <div className="u-i-p-c-footer-bc-verify">
              <button
                className="btn a-color big-btn "
                type="submit"
                title="Verify"
                disabled={true}
              >
                <span>{t("admin.Verify")}</span>
              </button>
            </div>
          </div>
        </form>
        <span className="u-i-e-v-a-t-title-bc">{t("admin.Uploadeddocuments")}</span>
        <div className="u-i-p-info-light-text-holder-bc">
          <p className="u-i-p-info-light-text-bc">
          {t("admin.Therearenouploadeddocuments")}.
          </p>
        </div>
      </div>
    </>
  );
};

export { VerifyAccount };
