import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout } from "../../components";
import "./CaniTakvim.scss";

const CaniTakvim = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  return (
    <Layout
      addClasses={`compact-footer page-full-size sportsbook ${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } ${panelOnep ? "smart-panel-is-visible" : ""}`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="canitakvim"
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
        <div className="live-calendar">
          <div className="">
            <div className="live-calendar-filters-wrapper">
              <div className="multi-select-bc">
                <div className="form-control-bc filled valid">
                  <div className="form-control-label-bc inputs">
                    <div className="form-control-select-bc">Football</div>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      Sport
                    </span>
                  </div>
                </div>
              </div>
              <div className="time-filters-bc">
                <ul className="time-filters-content">
                  <li className="time-filter-button-bc btn s-small a-outline a-white ">
                    02.05 Tue
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    03.05 Wed
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    04.05 Thu
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    05.05 Fri
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    06.05 Sat
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    07.05 Sun
                  </li>
                  <li className="time-filter-button-bc btn s-small a-outline  ">
                    08.05 Mon
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="live-calendar-market-scroll">
            <div className="live-calendar-market-bc">
              <p className="empty-b-text-v-bc">No games available</p>
            </div>
          </div>
        </div>
      <div className="layout-betslip-holder-bc">
        <div
          className="betslip-bc Fixed  full"
          style={{ transform: "translateY(0px)", opacity: 1 }}
        >
          <div id="betslip_container" className="">
            <div className="betslip-full-content-bc">
              <div className="bs-f-header-bc">
                <div className="second-tabs-bc">
                  <div
                    className="tab-bc selected-underline betslip active"
                    title="BetSlip"
                  >
                    <span>BetSlip</span>
                  </div>
                </div>
                <div className="settings-r-b-row-bc">
                  <div
                    className="form-control-bc select s-small has-icon filled"
                    tabIndex={0}
                  >
                    <div className="form-control-label-bc form-control-select-bc inputs ">
                      <i className="select-icon-bc bc-i-settings" />
                      <span className="ellipsis">Always ask</span>
                      <i className="form-control-icon-bc bc-i-small-arrow-down" />
                      <i className="form-control-input-stroke-bc" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bs-f-header-sub-navigation-bc " />
              <div className="bs-f-body-bc">
                <div
                  className="bs-scroll-container-bc"
                  data-scroll-lock-scrollable=""
                >
                  <p className="empty-b-text-v-bc">Your betslip is empty</p>
                  <div className="bs-actions-bc" />
                </div>
              </div>
              <div className="bs-f-footer ">
                <div className="bet-slip-switcher-wrapper-bc" />
                <div className="bs-info-massages">
                  <div className="quickBetMsgWrapper-bc betslip-warning-message ">
                    <i className="bs-notification-icon-bc bc-i-attention" />
                    <span className="quickBetMsgText-bc">
                      To place your bet, please{" "}
                      <span className="bet-slip-info-link">Sign in</span>or
                      <span className="bet-slip-info-link">Register</span>
                    </span>
                  </div>
                </div>
                <div className="bs-numbers-bc ">
                  <div className="bs-editable-number-buttons-bc">
                    <span className="bs-number-button-bc i-2 ellipsis">50</span>
                    <span className="bs-number-button-bc i-2 ellipsis">
                      100
                    </span>
                    <span className="bs-number-button-bc i-2 ellipsis">
                      500
                    </span>
                    <span
                      className="bs-number-button-bc i-2 "
                      title="Edit stakes"
                    >
                      <i className="bs-number-button-icon-bc bc-i-edit" />
                    </span>
                    <span className="bs-number-button-bc i-2 hidden">
                      <i className="bs-number-button-icon-bc bc-i-checked " />
                    </span>
                  </div>
                </div>
                <div className="bet-button-wrapper-bc content-type-0">
                  <button
                    className="btn a-color button-type-0 "
                    type="button"
                    title="Bet Now"
                    disabled={true}
                  >
                    <span>Bet Now</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className="hm-row-bc "
                style={{ gridTemplateColumns: "12fr" }}
              >
                <div className="betslip-banner">
                  <div className="sdr-item-holder-bc">
                    <Link
                      to={
                        "/tr/promotions/all/17100/anl%C4%B1k--10-spor-kay%C4%B1p-bonusu"
                      }
                      target="_self"
                      className=""
                    >
                      <img
                        src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_3ff67766543cd686d58e39b784a3b094.webp"
                        loading="lazy"
                        className="sdr-image-bc"
                        alt=""
                        title="kayıp"
                      />
                    </Link>
                  </div>
                  <div className="sdr-item-holder-bc">
                    <Link
                      to="/tr/promotions/all/17101/her-yat%C4%B1r%C4%B1ma--50-free-bet"
                      target="_self"
                      className=""
                    >
                      <img
                        src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_b82172422154a70d6b5008ae70079451.webp"
                        loading="lazy"
                        className="sdr-image-bc"
                        alt=""
                        title="Freebet"
                      />
                    </Link>
                  </div>
                  <div className="sdr-item-holder-bc">
                    <Link
                      to="/tr/promotions/all/17102/-15-%C3%A7evrimsiz-spor-yat%C4%B1r%C4%B1m-bonusu"
                      target="_self"
                      className=""
                    >
                      <img
                        src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_be35c22de506f0dabbc439a4e7d90272.webp"
                        loading="lazy"
                        className="sdr-image-bc"
                        alt=""
                        title="Spor yat"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export { CaniTakvim };
