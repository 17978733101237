import React from "react";

import "./MainHeader.scss";

import { Logo } from "./mainHeaderUi/logo/Logo";
import { SmartPanel } from "./mainHeaderUi/smartPanel/SmartPanel";
import { LanguageSelectHeader } from "./mainHeaderUi/languageSelectHeader/LanguageSelectHeader";
import { Time } from "./mainHeaderUi/time/Time";
import { UserHeader } from "./mainHeaderUi/userHeader/UserHeader";

interface iMainHeader {
  panelOnep: boolean;
  setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  betsLipOpen?: boolean;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const MainHeader = ({
  panelOnep,
  setPanelOpen,
  betsLipOpen,
  setBetsLipOpen,
}: iMainHeader) => {
  return (
    <div className="hdr-main-content-bc">
      <Logo />
      <SmartPanel
        panelOnep={panelOnep}
        setPanelOpen={setPanelOpen}
        betsLipOpen={betsLipOpen}
        setBetsLipOpen={setBetsLipOpen}
      />
      <UserHeader
      />
      <LanguageSelectHeader />
      <Time />
    </div>
  );
};

export { MainHeader };
