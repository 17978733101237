import { t } from "i18next";
import React, { useState } from "react";
import "./PromoCode.scss";

const PromoCode = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <>
      <div className="overlay-header">{t("admin.Promocode")}</div>
      <div className="promoCodeWrapper-bc ">
        <form>
          <div className="u-i-p-control-item-holder-bc">
            <div
              className={`form-control-bc ${
                inputFocused ? "focused" : ""
              } default `}
            >
              <label className="form-control-label-bc inputs">
                <input
                  value={inputValue}
                  type="number"
                  className="form-control-input-bc"
                  name="promoCode"
                  step={0}
                  defaultValue=""
                  onChange={handleChangeInput}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                {t("admin.Promocode")}
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-c-footer-bc">
            <button
              className="btn a-color big-btn button-custom"
              type="submit"
              title="Apply"
              disabled={inputValue.length > 0}
            >
              <span>{t("admin.Apply")}</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { PromoCode };
