import { t } from "i18next";
import React from "react";
import "./BonusHistory.scss";
import { FilterSelect } from "./components/filterSelect/FilterSelect";

const BonusHistory = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.Bonushistory")}</div>
      <div
        className="u-i-e-p-p-content-bc u-i-common-content"
        data-scroll-lock-scrollable=""
      >
        <FilterSelect />
        <p className="empty-b-text-v-bc">
          {t("admin.Therearenodatafortheselectedtimeperiod")}
        </p>
      </div>
    </>
  );
};

export { BonusHistory };
