import { UserInformation } from "../../../common/types/AdminInfo/UserInformation";

type IsetModalLogin = {
  type: string;
  payload: boolean;
};

function setModalLogin(payload: boolean): IsetModalLogin {
  return {
    type: 'SET_MODAL_LOGIN',
    payload,
  };
}

export { setModalLogin };
