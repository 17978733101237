import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoGameType } from "../common/types/Casino/CasinoGameType";
import { FavoritCasinoResponceType } from "../common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "../common/types/Casino/FavoritCasinoStateType";
import { CasinoInfo } from "../services";
import { setFavoritsLocalDelete } from "../store/action/favorits/setFavoritsLocalDelete";
import { setFavoritsLocalSelect } from "../store/action/favorits/setFavoritsLocalSelect";
import { setFavoritsUser } from "../store/action/favorits/setFavoritsUser";

interface IusePlayerRightWindow {
  gamesSelected: CasinoGameType[];
  openGames: string[];
  favoritsGamesUser: FavoritCasinoResponceType[] | [];
  favoritsGamesLocal: FavoritCasinoStateType[] | [];
  openGamesUrl: string;
  gameNamesUrl: string;
}

const usePlayerRightWindow = ({
  gamesSelected,
  openGames,
  favoritsGamesUser,
  favoritsGamesLocal,
  openGamesUrl,
  gameNamesUrl,
}: IusePlayerRightWindow) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFavoriteUserSelect = () => {
    async function gandleSelectFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gamesSelected[0].id,
        type: 1,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    async function gandleRemoveFavorit() {
      await CasinoInfo.setFavoritGame({
        game_id: gamesSelected[0].id,
        type: 0,
      });
      const res = await CasinoInfo.favoritGames();
      dispatch(setFavoritsUser(res.data));
    }
    favoritsGamesUser.some((obj) => obj.id === gamesSelected[0].id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };

  const handleFavoriteLocalSelect = () => {
    async function gandleSelectFavorit() {
      dispatch(
        setFavoritsLocalSelect({
          id: gamesSelected[0].id,
          slug: openGames[0],
          original_name: gamesSelected[0].original_name,
          is_demo_enabled: gamesSelected[0].is_demo_enabled,
          game_provider: {
            slug: gamesSelected[0].game_provider.slug,
          },
          banner: gamesSelected[0].banner,
        })
      );
    }

    async function gandleRemoveFavorit() {
      dispatch(
        setFavoritsLocalDelete({
          id: gamesSelected[0].id,
          slug: openGames[0],
          original_name: gamesSelected[0].original_name,
          is_demo_enabled: gamesSelected[0].is_demo_enabled,
          game_provider: {
            slug: gamesSelected[0].game_provider.slug,
          },
          banner: gamesSelected[0].banner,
        })
      );
    }
    favoritsGamesLocal.some((obj) => obj.id === gamesSelected[0].id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };
  const handleRemoveGame = () => {
    const updatedOpenGames = openGamesUrl ? openGamesUrl.split(",")[0] : "";
    const updatedGameNames = gameNamesUrl ? gameNamesUrl.split(",")[0] : "";

    const newSearchParams = new URLSearchParams();
    newSearchParams.set("openGames", updatedOpenGames);
    newSearchParams.set("gameNames", updatedGameNames);

    const newUrl = "?" + newSearchParams.toString();

    navigate(newUrl);
  };
  return {
    handleFavoriteUserSelect,
    handleFavoriteLocalSelect,
    handleRemoveGame,
  };
};

export { usePlayerRightWindow };
