import { t } from "i18next";
import React, { useState } from "react";
import "./FilterSelect.scss";
import { CategoryInput } from "./filterSelectUi/CategoryInput/CategoryInput";
import { PeriodInput } from "./filterSelectUi/PeriodInput/PeriodInput";
import { RangeInput } from "./filterSelectUi/RangeInput/RangeInput";

const FilterSelect = () => {
  const [categorySelected, setCategorySelected] = useState("Sport");
  const [periodSelected, setPeriodSelected] = useState("24");
  return (
    <div className="componentFilterWrapper-bc">
      <div className="componentFilterBody-bc">
        <div className="componentFilterElsWrapper-bc">
          <form className="filter-form-w-bc">
            <div className="componentFilterBody-content">
              <CategoryInput
                categorySelected={categorySelected}
                setCategorySelected={setCategorySelected}
              />
              <PeriodInput
                periodSelected={periodSelected}
                setPeriodSelected={setPeriodSelected}
              />
              {periodSelected === "custom" ? <RangeInput /> : null}
            </div>
            <div className="u-i-p-c-filter-footer-bc">
              <button className="btn a-color " type="submit" title="Show">
                <span>{t("admin.Bonushistory")}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { FilterSelect };
