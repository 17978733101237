import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./Period.scss";

interface IPeriod {
  period: string;
  setPeriod: React.Dispatch<React.SetStateAction<string>>;
}

const Period = ({ period, setPeriod }: IPeriod) => {
  const wrapperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectItem = (item: string) => {
    setPeriod(item);
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="u-i-p-control-item-holder-bc" ref={wrapperRef}>
      <div className="multi-select-bc ">
        <div className="form-control-bc valid filled">
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenModal}
          >
            <div className="form-control-select-bc ellipsis">
              {period === "24/1" ? "24 hourse" : ""}
              {period === "24/3" ? "72 hourse" : ""}
              {period === "24/7" ? "One week" : ""}
              {period === "24/30" ? "30 Days" : ""}
              {period === "custom" ? "Custom" : ""}
            </div>
            <i
              className={`form-control-icon-bc bc-i-small-arrow-${
                modalOpen ? "up" : "down"
              }`}
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              {t("admin.Period")}
            </span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              <label
                className={`checkbox-control-content-bc ${
                  period === "24/1" ? "active" : ""
                } `}
                data-option-value={24}
                onClick={() => handleSelectItem("24/1")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  24 {t("admin.hours")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  period === "24/3" ? "active" : ""
                } `}
                data-option-value={72}
                onClick={() => handleSelectItem("24/3")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  72 {t("admin.hours")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  period === "24/7" ? "active" : ""
                } `}
                data-option-value={168}
                onClick={() => handleSelectItem("24/7")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Birhafta")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  period === "24/30" ? "active" : ""
                } `}
                data-option-value={720}
                onClick={() => handleSelectItem("24/30")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  30 {t("admin.Days")}
                </p>
              </label>
              <label
                className={`checkbox-control-content-bc ${
                  period === "custom" ? "active" : ""
                } `}
                data-option-value=""
                onClick={() => handleSelectItem("custom")}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {t("admin.Custom")}
                </p>
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Period };
