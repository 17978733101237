import React from "react";
import "./CarouselItem.scss";

type liveCasinoItem = {
  img: string;
  name: string;
};

interface ICarouselItem {
  liveCasinoItem: liveCasinoItem;
}

const CarouselItem = ({ liveCasinoItem }: ICarouselItem) => {
  return (
    <div
      className="carousel-slide active-slide"
      style={{ width: "100%", left: "0%" }}
    >
      <div className="sdr-item-holder-bc">
        <a className="sdr-item-bc">
          <img
            src={liveCasinoItem.img}
            loading="lazy"
            className="sdr-image-bc"
            alt=""
            title={liveCasinoItem.name}
          />
        </a>
      </div>
    </div>
  );
};

export { CarouselItem };

export type { liveCasinoItem };
