import React, { useState, useRef, useEffect } from "react";
import "./CountryNumberSelect.scss";

interface ICountryNumberSelect {
  countryCode: number;
  setCountryCode: React.Dispatch<React.SetStateAction<number>>;
}

const CountryNumberSelect = ({
  countryCode,
  setCountryCode,
}: ICountryNumberSelect) => {
  const [countryFlag, setCountryFlag] = useState("turkey");
  const [modalOpen, setModalOpen] = useState(false);
  const handleSelectFlag = (item: string) => {
    setCountryFlag(item);
  };
  const handleSelectNumber = (item: number) => {
    setCountryCode(item);
  };
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };

  const handleOpenInput = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className="form-controls-field-bc country-code">
      <div
        className="form-control-bc select  filled"
        tabIndex={0}
        ref={wrapperRef}
      >
        <div
          className="form-control-label-bc form-control-select-bc inputs "
          onClick={handleOpenInput}
        >
          <i
            className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${countryFlag}`}
          />
          <span className="ellipsis">{countryCode}</span>
          <i
            className={`form-control-icon-bc bc-i-small-arrow-${
              modalOpen ? "up" : "down"
            } custom-arrow`}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">Code</span>
        </div>
        {modalOpen ? (
          <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AF"
              onClick={() => {
                handleSelectFlag("afghanistan");
                handleSelectNumber(93);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc afghanistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                93
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AX"
              onClick={() => {
                handleSelectFlag("alandislands");
                handleSelectNumber(358);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc alandislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                358
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AL"
              onClick={() => {
                handleSelectFlag("albania");
                handleSelectNumber(355);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc albania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                355
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DZ"
              onClick={() => {
                handleSelectFlag("algeria");
                handleSelectNumber(213);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc algeria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                213
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AS"
              onClick={() => {
                handleSelectFlag("americansamoa");
                handleSelectNumber(1684);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc americansamoa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1684
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AD"
              onClick={() => {
                handleSelectFlag("andorra");
                handleSelectNumber(376);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc andorra" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                376
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AO"
              onClick={() => {
                handleSelectFlag("angola");
                handleSelectNumber(244);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc angola" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                244
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AI"
              onClick={() => {
                handleSelectFlag("anguilla");
                handleSelectNumber(1264);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc anguilla" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1264
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AQ"
              onClick={() => {
                handleSelectFlag("antarctica");
                handleSelectNumber(672);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc antarctica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                672
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AG"
              onClick={() => {
                handleSelectFlag("antiguaandbarbuda");
                handleSelectNumber(1268);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc antiguaandbarbuda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1268
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AR"
              onClick={() => {
                handleSelectFlag("argentina");
                handleSelectNumber(54);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc argentina" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                54
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AM"
              onClick={() => {
                handleSelectFlag("armenia");
                handleSelectNumber(374);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc armenia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                374
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AW"
              onClick={() => {
                handleSelectFlag("aruba");
                handleSelectNumber(297);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc aruba" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                297
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AU"
              onClick={() => {
                handleSelectFlag("australia");
                handleSelectNumber(61);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc australia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                61
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AT"
              onClick={() => {
                handleSelectFlag("austria");
                handleSelectNumber(43);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc austria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                43
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AZ"
              onClick={() => {
                handleSelectFlag("azerbaijan");
                handleSelectNumber(994);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc azerbaijan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                994
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BS"
              onClick={() => {
                handleSelectFlag("bahamas");
                handleSelectNumber(1242);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bahamas" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1242
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BH"
              onClick={() => {
                handleSelectFlag("bahrain");
                handleSelectNumber(973);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bahrain" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                973
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BD"
              onClick={() => {
                handleSelectFlag("bangladesh");
                handleSelectNumber(880);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bangladesh" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                880
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BB"
              onClick={() => {
                handleSelectFlag("barbados");
                handleSelectNumber(1246);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc barbados" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1246
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BY"
              onClick={() => {
                handleSelectFlag("belarus");
                handleSelectNumber(375);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belarus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                375
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BE"
              onClick={() => {
                handleSelectFlag("belgium");
                handleSelectNumber(32);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belgium" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                32
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BZ"
              onClick={() => {
                handleSelectFlag("belize");
                handleSelectNumber(501);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc belize" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                501
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BJ"
              onClick={() => {
                handleSelectFlag("benin");
                handleSelectNumber(229);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc benin" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                229
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BM"
              onClick={() => {
                handleSelectFlag("bermuda");
                handleSelectNumber(1441);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bermuda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1441
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BT"
              onClick={() => {
                handleSelectFlag("bhutan");
                handleSelectNumber(975);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bhutan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                975
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BO"
              onClick={() => {
                handleSelectFlag("bolivia");
                handleSelectNumber(591);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bolivia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                591
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BQ"
              onClick={() => {
                handleSelectFlag("bonaire");
                handleSelectNumber(5997);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bonaire" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                5997
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BA"
              onClick={() => {
                handleSelectFlag("bosniaandherzegovina");
                handleSelectNumber(387);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bosniaandherzegovina" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                387
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BW"
              onClick={() => {
                handleSelectFlag("botswana");
                handleSelectNumber(267);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc botswana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                267
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BV"
              onClick={() => {
                handleSelectFlag("bouvetisland");
                handleSelectNumber(47);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bouvetisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                47
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BR"
              onClick={() => {
                handleSelectFlag("brazil");
                handleSelectNumber(55);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc brazil" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                55
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IO"
              onClick={() => {
                handleSelectFlag("britishindianoceanterritory");
                handleSelectNumber(246);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc britishindianoceanterritory" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                246
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BN"
              onClick={() => {
                handleSelectFlag("bruneidarussalam");
                handleSelectNumber(673);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bruneidarussalam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                673
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BG"
              onClick={() => {
                handleSelectFlag("bulgaria");
                handleSelectNumber(359);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc bulgaria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                359
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BF"
              onClick={() => {
                handleSelectFlag("burkinafaso");
                handleSelectNumber(226);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc burkinafaso" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                226
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BI"
              onClick={() => {
                handleSelectFlag("burundi");
                handleSelectNumber(257);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc burundi" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                257
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CV"
              onClick={() => {
                handleSelectFlag("caboverde");
                handleSelectNumber(238);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc caboverde" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                238
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KH"
              onClick={() => {
                handleSelectFlag("cambodia");
                handleSelectNumber(855);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cambodia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                855
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CM"
              onClick={() => {
                handleSelectFlag("cameroon");
                handleSelectNumber(237);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cameroon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                237
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CA"
              onClick={() => {
                handleSelectFlag("canada");
                handleSelectNumber(1);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc canada" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KY"
              onClick={() => {
                handleSelectFlag("caymanislands");
                handleSelectNumber(1345);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc caymanislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1345
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CF"
              onClick={() => {
                handleSelectFlag("centralafricanrepublic");
                handleSelectNumber(236);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc centralafricanrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                236
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TD"
              onClick={() => {
                handleSelectFlag("chad");
                handleSelectNumber(235);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc chad" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                235
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CL"
              onClick={() => {
                handleSelectFlag("chile");
                handleSelectNumber(56);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc chile" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                56
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CN"
              onClick={() => {
                handleSelectFlag("china");
                handleSelectNumber(86);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc china" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                86
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CX"
              onClick={() => {
                handleSelectFlag("christmasisland");
                handleSelectNumber(6189164);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc christmasisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                6189164
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CC"
              onClick={() => {
                handleSelectFlag("cocoskeelingislands");
                handleSelectNumber(6189162);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cocoskeelingislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                6189162
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CO"
              onClick={() => {
                handleSelectFlag("colombia");
                handleSelectNumber(57);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc colombia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                57
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KM"
              onClick={() => {
                handleSelectFlag("comoros");
                handleSelectNumber(269);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc comoros" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                269
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CG"
              onClick={() => {
                handleSelectFlag("congobrazzaville");
                handleSelectNumber(242);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc congobrazzaville" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                242
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CD"
              onClick={() => {
                handleSelectFlag("congokinshasa");
                handleSelectNumber(243);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc congokinshasa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                243
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CK"
              onClick={() => {
                handleSelectFlag("cookislands");
                handleSelectNumber(682);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cookislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                682
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CR"
              onClick={() => {
                handleSelectFlag("costarica");
                handleSelectNumber(506);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc costarica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                506
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CI"
              onClick={() => {
                handleSelectFlag("cotedivoire");
                handleSelectNumber(225);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cotedivoire" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                225
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HR"
              onClick={() => {
                handleSelectFlag("croatia");
                handleSelectNumber(385);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc croatia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                385
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CU"
              onClick={() => {
                handleSelectFlag("cuba");
                handleSelectNumber(53);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cuba" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                53
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CW"
              onClick={() => {
                handleSelectFlag("curacao");
                handleSelectNumber(5999);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc curacao" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                5999
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CY"
              onClick={() => {
                handleSelectFlag("cyprus");
                handleSelectNumber(357);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc cyprus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                357
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CZ"
              onClick={() => {
                handleSelectFlag("czechrepublic");
                handleSelectNumber(420);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc czechrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                420
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DK"
              onClick={() => {
                handleSelectFlag("denmark");
                handleSelectNumber(45);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc denmark" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                45
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DJ"
              onClick={() => {
                handleSelectFlag("djibouti");
                handleSelectNumber(253);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc djibouti" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                253
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DM"
              onClick={() => {
                handleSelectFlag("dominica");
                handleSelectNumber(1767);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc dominica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1767
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DO"
              onClick={() => {
                handleSelectFlag("dominicanrepublic");
                handleSelectNumber(1809);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc dominicanrepublic" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1809
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="EC"
              onClick={() => {
                handleSelectFlag("ecuador");
                handleSelectNumber(593);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ecuador" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                593
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="EG"
              onClick={() => {
                handleSelectFlag("egypt");
                handleSelectNumber(20);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc egypt" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                20
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SV"
              onClick={() => {
                handleSelectFlag("elsalvador");
                handleSelectNumber(503);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc elsalvador" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                503
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GQ"
              onClick={() => {
                handleSelectFlag("equatorialguinea");
                handleSelectNumber(240);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc equatorialguinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                240
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ER"
              onClick={() => {
                handleSelectFlag("eritrea");
                handleSelectNumber(291);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc eritrea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                291
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="EE"
              onClick={() => {
                handleSelectFlag("estonia");
                handleSelectNumber(372);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc estonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                372
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ET"
              onClick={() => {
                handleSelectFlag("ethiopia");
                handleSelectNumber(251);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ethiopia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                251
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FK"
              onClick={() => {
                handleSelectFlag("falklandislands");
                handleSelectNumber(500);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc falklandislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                500
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FO"
              onClick={() => {
                handleSelectFlag("faroeislands");
                handleSelectNumber(298);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc faroeislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                298
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FJ"
              onClick={() => {
                handleSelectFlag("fiji");
                handleSelectNumber(679);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc fiji" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                679
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FI"
              onClick={() => {
                handleSelectFlag("finland");
                handleSelectNumber(358);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc finland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                358
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FR"
              onClick={() => {
                handleSelectFlag("france");
                handleSelectNumber(33);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc france" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                33
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GF"
              onClick={() => {
                handleSelectFlag("frenchguiana");
                handleSelectNumber(594);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchguiana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                594
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PF"
              onClick={() => {
                handleSelectFlag("frenchpolynesia");
                handleSelectNumber(689);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchpolynesia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                689
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TF"
              onClick={() => {
                handleSelectFlag("frenchsouthernterritories");
                handleSelectNumber(596);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc frenchsouthernterritories" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                596
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GA"
              onClick={() => {
                handleSelectFlag("gabon");
                handleSelectNumber(241);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gabon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                241
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GM"
              onClick={() => {
                handleSelectFlag("gambia");
                handleSelectNumber(220);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gambia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                220
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GE"
              onClick={() => {
                handleSelectFlag("georgia");
                handleSelectNumber(995);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc georgia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                995
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="DE"
              onClick={() => {
                handleSelectFlag("germany");
                handleSelectNumber(49);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc germany" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                49
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GE-AB"
              onClick={() => {
                handleSelectFlag("abkhazia");
                handleSelectNumber(7840);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc abkhazia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                7840
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GH"
              onClick={() => {
                handleSelectFlag("ghana");
                handleSelectNumber(233);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ghana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                233
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GI"
              onClick={() => {
                handleSelectFlag("gibraltar");
                handleSelectNumber(350);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc gibraltar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                350
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GR"
              onClick={() => {
                handleSelectFlag("greece");
                handleSelectNumber(30);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc greece" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                30
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GL"
              onClick={() => {
                handleSelectFlag("greenland");
                handleSelectNumber(299);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc greenland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                299
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GD"
              onClick={() => {
                handleSelectFlag("grenada");
                handleSelectNumber(1473);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc grenada" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1473
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GP"
              onClick={() => {
                handleSelectFlag("guadeloupe");
                handleSelectNumber(590);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guadeloupe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                590
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GU"
              onClick={() => {
                handleSelectFlag("guam");
                handleSelectNumber(1671);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1671
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GT"
              onClick={() => {
                handleSelectFlag("guatemala");
                handleSelectNumber(502);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guatemala" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                502
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GG"
              onClick={() => {
                handleSelectFlag("guernsey");
                handleSelectNumber(441481);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guernsey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                441481
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GN"
              onClick={() => {
                handleSelectFlag("guinea");
                handleSelectNumber(224);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                224
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GW"
              onClick={() => {
                handleSelectFlag("guinea-bissau");
                handleSelectNumber(245);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guinea-bissau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                245
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GY"
              onClick={() => {
                handleSelectFlag("guyana");
                handleSelectNumber(592);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc guyana" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                592
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HT"
              onClick={() => {
                handleSelectFlag("haiti");
                handleSelectNumber(509);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc haiti" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                509
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HM"
              onClick={() => {
                handleSelectFlag("heardislandandmcdonaldislands");
                handleSelectNumber(672);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc heardislandandmcdonaldislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                672
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VA"
              onClick={() => {
                handleSelectFlag("holysee");
                handleSelectNumber(379);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc holysee" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                379
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HN"
              onClick={() => {
                handleSelectFlag("honduras");
                handleSelectNumber(504);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc honduras" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                504
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HK"
              onClick={() => {
                handleSelectFlag("hongkong");
                handleSelectNumber(852);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc hongkong" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                852
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="HU"
              onClick={() => {
                handleSelectFlag("hungary");
                handleSelectNumber(36);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc hungary" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                36
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IS"
              onClick={() => {
                handleSelectFlag("iceland");
                handleSelectNumber(354);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iceland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                354
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IN"
              onClick={() => {
                handleSelectFlag("india");
                handleSelectNumber(91);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc india" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                91
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ID"
              onClick={() => {
                handleSelectFlag("indonesia");
                handleSelectNumber(62);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc indonesia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                62
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IR"
              onClick={() => {
                handleSelectFlag("iran");
                handleSelectNumber(98);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iran" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                98
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IQ"
              onClick={() => {
                handleSelectFlag("iraq");
                handleSelectNumber(964);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc iraq" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                964
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IE"
              onClick={() => {
                handleSelectFlag("ireland");
                handleSelectNumber(353);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ireland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                353
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IM"
              onClick={() => {
                handleSelectFlag("isleofman");
                handleSelectNumber(441624);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc isleofman" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                441624
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IL"
              onClick={() => {
                handleSelectFlag("israel");
                handleSelectNumber(972);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc israel" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                972
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="IT"
              onClick={() => {
                handleSelectFlag("italy");
                handleSelectNumber(39);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc italy" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                39
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="JM"
              onClick={() => {
                handleSelectFlag("jamaica");
                handleSelectNumber(1876);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jamaica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1876
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="JP"
              onClick={() => {
                handleSelectFlag("japan");
                handleSelectNumber(81);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc japan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                81
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="JE"
              onClick={() => {
                handleSelectFlag("jersey");
                handleSelectNumber(441534);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jersey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                441534
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="JO"
              onClick={() => {
                handleSelectFlag("jordan");
                handleSelectNumber(962);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc jordan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                962
              </p>
            </label>
            <label
              className="checkbox-control-content-bc active "
              data-option-value="KZ"
              onClick={() => {
                handleSelectFlag("kazakhstan");
                handleSelectNumber(7);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kazakhstan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                7
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KE"
              onClick={() => {
                handleSelectFlag("kenya");
                handleSelectNumber(254);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kenya" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                254
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KI"
              onClick={() => {
                handleSelectFlag("kiribati");
                handleSelectNumber(686);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kiribati" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                686
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KP"
              onClick={() => {
                handleSelectFlag("korea,north");
                handleSelectNumber(850);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc korea,north" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                850
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KW"
              onClick={() => {
                handleSelectFlag("kuwait");
                handleSelectNumber(965);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kuwait" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                965
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KG"
              onClick={() => {
                handleSelectFlag("kyrgyzstan");
                handleSelectNumber(996);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc kyrgyzstan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                996
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LA"
              onClick={() => {
                handleSelectFlag("laos");
                handleSelectNumber(856);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc laos" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                856
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LV"
              onClick={() => {
                handleSelectFlag("latvia");
                handleSelectNumber(371);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc latvia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                371
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LB"
              onClick={() => {
                handleSelectFlag("lebanon");
                handleSelectNumber(961);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lebanon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                961
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LS"
              onClick={() => {
                handleSelectFlag("lesotho");
                handleSelectNumber(266);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lesotho" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                266
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LR"
              onClick={() => {
                handleSelectFlag("liberia");
                handleSelectNumber(231);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc liberia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                231
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LY"
              onClick={() => {
                handleSelectFlag("libya");
                handleSelectNumber(218);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc libya" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                218
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LI"
              onClick={() => {
                handleSelectFlag("liechtenstein");
                handleSelectNumber(423);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc liechtenstein" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                423
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LT"
              onClick={() => {
                handleSelectFlag("lithuania");
                handleSelectNumber(370);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc lithuania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                370
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LU"
              onClick={() => {
                handleSelectFlag("luxembourg");
                handleSelectNumber(352);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc luxembourg" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                352
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MO"
              onClick={() => {
                handleSelectFlag("macao");
                handleSelectNumber(853);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc macao" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                853
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MG"
              onClick={() => {
                handleSelectFlag("madagascar");
                handleSelectNumber(261);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc madagascar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                261
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MW"
              onClick={() => {
                handleSelectFlag("malawi");
                handleSelectNumber(265);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malawi" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                265
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MY"
              onClick={() => {
                handleSelectFlag("malaysia");
                handleSelectNumber(60);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malaysia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                60
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MV"
              onClick={() => {
                handleSelectFlag("maldives");
                handleSelectNumber(960);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc maldives" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                960
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ML"
              onClick={() => {
                handleSelectFlag("mali");
                handleSelectNumber(223);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mali" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                223
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MT"
              onClick={() => {
                handleSelectFlag("malta");
                handleSelectNumber(356);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc malta" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                356
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MH"
              onClick={() => {
                handleSelectFlag("marshallislands");
                handleSelectNumber(692);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc marshallislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                692
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MQ"
              onClick={() => {
                handleSelectFlag("martinique");
                handleSelectNumber(596);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc martinique" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                596
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MR"
              onClick={() => {
                handleSelectFlag("mauritania");
                handleSelectNumber(222);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mauritania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                222
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MU"
              onClick={() => {
                handleSelectFlag("mauritius");
                handleSelectNumber(230);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mauritius" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                230
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="YT"
              onClick={() => {
                handleSelectFlag("mayotte");
                handleSelectNumber(262);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mayotte" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                262
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MX"
              onClick={() => {
                handleSelectFlag("mexico");
                handleSelectNumber(52);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mexico" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                52
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="FM"
              onClick={() => {
                handleSelectFlag("micronesiafederatedstatesof");
                handleSelectNumber(691);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc micronesiafederatedstatesof" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                691
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MD"
              onClick={() => {
                handleSelectFlag("moldova");
                handleSelectNumber(373);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc moldova" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                373
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MC"
              onClick={() => {
                handleSelectFlag("monaco");
                handleSelectNumber(377);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc monaco" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                377
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MN"
              onClick={() => {
                handleSelectFlag("mongolia");
                handleSelectNumber(976);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mongolia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                976
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ME"
              onClick={() => {
                handleSelectFlag("montenegro");
                handleSelectNumber(382);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc montenegro" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                382
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MS"
              onClick={() => {
                handleSelectFlag("montserrat");
                handleSelectNumber(1664);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc montserrat" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1664
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MA"
              onClick={() => {
                handleSelectFlag("morocco");
                handleSelectNumber(212);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc morocco" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                212
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MZ"
              onClick={() => {
                handleSelectFlag("mozambique");
                handleSelectNumber(258);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc mozambique" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                258
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MM"
              onClick={() => {
                handleSelectFlag("myanmar");
                handleSelectNumber(95);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc myanmar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                95
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NA"
              onClick={() => {
                handleSelectFlag("namibia");
                handleSelectNumber(264);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc namibia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                264
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NR"
              onClick={() => {
                handleSelectFlag("nauru");
                handleSelectNumber(674);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nauru" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                674
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NP"
              onClick={() => {
                handleSelectFlag("nepal");
                handleSelectNumber(977);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nepal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                977
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NL"
              onClick={() => {
                handleSelectFlag("netherlands");
                handleSelectNumber(31);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc netherlands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                31
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NC"
              onClick={() => {
                handleSelectFlag("newcaledonia");
                handleSelectNumber(687);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc newcaledonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                687
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NZ"
              onClick={() => {
                handleSelectFlag("newzealand");
                handleSelectNumber(64);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc newzealand" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                64
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NI"
              onClick={() => {
                handleSelectFlag("nicaragua");
                handleSelectNumber(505);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nicaragua" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                505
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NE"
              onClick={() => {
                handleSelectFlag("niger");
                handleSelectNumber(227);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc niger" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                227
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NG"
              onClick={() => {
                handleSelectFlag("nigeria");
                handleSelectNumber(234);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc nigeria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                234
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NU"
              onClick={() => {
                handleSelectFlag("niue");
                handleSelectNumber(683);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc niue" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                683
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NF"
              onClick={() => {
                handleSelectFlag("norfolkisland");
                handleSelectNumber(6723);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc norfolkisland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                6723
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MP"
              onClick={() => {
                handleSelectFlag("northernmarianaislands");
                handleSelectNumber(1670);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northernmarianaislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1670
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MK"
              onClick={() => {
                handleSelectFlag("northmacedonia");
                handleSelectNumber(389);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northmacedonia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                389
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="NO"
              onClick={() => {
                handleSelectFlag("norway");
                handleSelectNumber(47);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc norway" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                47
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="OM"
              onClick={() => {
                handleSelectFlag("oman");
                handleSelectNumber(968);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc oman" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                968
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PK"
              onClick={() => {
                handleSelectFlag("pakistan");
                handleSelectNumber(92);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc pakistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                92
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PW"
              onClick={() => {
                handleSelectFlag("palau");
                handleSelectNumber(680);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc palau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                680
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PS"
              onClick={() => {
                handleSelectFlag("palestine");
                handleSelectNumber(970);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc palestine" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                970
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PA"
              onClick={() => {
                handleSelectFlag("panama");
                handleSelectNumber(507);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc panama" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                507
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PG"
              onClick={() => {
                handleSelectFlag("papuanewguinea");
                handleSelectNumber(675);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc papuanewguinea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                675
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PY"
              onClick={() => {
                handleSelectFlag("paraguay");
                handleSelectNumber(595);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc paraguay" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                595
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PE"
              onClick={() => {
                handleSelectFlag("peru");
                handleSelectNumber(51);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc peru" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                51
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PH"
              onClick={() => {
                handleSelectFlag("philippines");
                handleSelectNumber(63);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc philippines" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                63
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PN"
              onClick={() => {
                handleSelectFlag("pitcairn");
                handleSelectNumber(64);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc pitcairn" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                64
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PL"
              onClick={() => {
                handleSelectFlag("poland");
                handleSelectNumber(48);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc poland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                48
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PT"
              onClick={() => {
                handleSelectFlag("portugal");
                handleSelectNumber(351);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc portugal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                351
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PR"
              onClick={() => {
                handleSelectFlag("puertorico");
                handleSelectNumber(1787);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc puertorico" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1787
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="QA"
              onClick={() => {
                handleSelectFlag("qatar");
                handleSelectNumber(974);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc qatar" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                974
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="RE"
              onClick={() => {
                handleSelectFlag("reunion");
                handleSelectNumber(262);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc reunion" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                262
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="RO"
              onClick={() => {
                handleSelectFlag("romania");
                handleSelectNumber(40);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc romania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                40
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="RU"
              onClick={() => {
                handleSelectFlag("russia");
                handleSelectNumber(7);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc russia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                7
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="RW"
              onClick={() => {
                handleSelectFlag("rwanda");
                handleSelectNumber(250);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc rwanda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                250
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="BL"
              onClick={() => {
                handleSelectFlag("saintbarthelemy");
                handleSelectNumber(590);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintbarthelemy" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                590
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SH"
              onClick={() => {
                handleSelectFlag("sainthelena");
                handleSelectNumber(290);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sainthelena" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                290
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KN"
              onClick={() => {
                handleSelectFlag("saintkittsandnevis");
                handleSelectNumber(1869);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintkittsandnevis" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1869
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LC"
              onClick={() => {
                handleSelectFlag("saintlucia");
                handleSelectNumber(1758);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintlucia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1758
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="MF"
              onClick={() => {
                handleSelectFlag("saintmartinfrance");
                handleSelectNumber(590);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintmartinfrance" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                590
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="PM"
              onClick={() => {
                handleSelectFlag("saintpierreandmiquelon");
                handleSelectNumber(508);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintpierreandmiquelon" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                508
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VC"
              onClick={() => {
                handleSelectFlag("saintvincentandthegrenadines");
                handleSelectNumber(1784);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saintvincentandthegrenadines" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1784
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="WS"
              onClick={() => {
                handleSelectFlag("samoa");
                handleSelectNumber(685);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc samoa" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                685
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SM"
              onClick={() => {
                handleSelectFlag("sanmarino");
                handleSelectNumber(378);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sanmarino" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                378
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ST"
              onClick={() => {
                handleSelectFlag("saotomeandprincipe");
                handleSelectNumber(239);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saotomeandprincipe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                239
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SA"
              onClick={() => {
                handleSelectFlag("saudiarabia");
                handleSelectNumber(966);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc saudiarabia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                966
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SN"
              onClick={() => {
                handleSelectFlag("senegal");
                handleSelectNumber(221);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc senegal" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                221
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="RS"
              onClick={() => {
                handleSelectFlag("serbia");
                handleSelectNumber(381);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc serbia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                381
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SC"
              onClick={() => {
                handleSelectFlag("seychelles");
                handleSelectNumber(248);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc seychelles" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                248
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SL"
              onClick={() => {
                handleSelectFlag("sierraleone");
                handleSelectNumber(232);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sierraleone" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                232
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SG"
              onClick={() => {
                handleSelectFlag("singapore");
                handleSelectNumber(65);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc singapore" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                65
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SX"
              onClick={() => {
                handleSelectFlag("sintmaartennetherlands");
                handleSelectNumber(1721);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sintmaartennetherlands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1721
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SK"
              onClick={() => {
                handleSelectFlag("slovakia");
                handleSelectNumber(421);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc slovakia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                421
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SI"
              onClick={() => {
                handleSelectFlag("slovenia");
                handleSelectNumber(386);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc slovenia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                386
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SB"
              onClick={() => {
                handleSelectFlag("solomonislands");
                handleSelectNumber(677);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc solomonislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                677
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SO"
              onClick={() => {
                handleSelectFlag("somalia");
                handleSelectNumber(252);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc somalia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                252
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ZA"
              onClick={() => {
                handleSelectFlag("southafrica");
                handleSelectNumber(27);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southafrica" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                27
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GS"
              onClick={() => {
                handleSelectFlag("southgeorgiaandthesouthsandwichislands");
                handleSelectNumber(500);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southgeorgiaandthesouthsandwichislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                500
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SS"
              onClick={() => {
                handleSelectFlag("southsudan");
                handleSelectNumber(211);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southsudan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                211
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="KR"
              onClick={() => {
                handleSelectFlag("southkorea");
                handleSelectNumber(82);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc southkorea" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                82
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ES"
              onClick={() => {
                handleSelectFlag("spain");
                handleSelectNumber(34);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc spain" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                34
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="LK"
              onClick={() => {
                handleSelectFlag("srilanka");
                handleSelectNumber(94);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc srilanka" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                94
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SD"
              onClick={() => {
                handleSelectFlag("sudan");
                handleSelectNumber(249);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sudan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                249
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SR"
              onClick={() => {
                handleSelectFlag("suriname");
                handleSelectNumber(597);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc suriname" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                597
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SJ"
              onClick={() => {
                handleSelectFlag("svalbardandjanmayen");
                handleSelectNumber(47);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc svalbardandjanmayen" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                47
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SZ"
              onClick={() => {
                handleSelectFlag("swaziland");
                handleSelectNumber(268);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc swaziland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                268
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SE"
              onClick={() => {
                handleSelectFlag("sweden");
                handleSelectNumber(46);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc sweden" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                46
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="CH"
              onClick={() => {
                handleSelectFlag("switzerland");
                handleSelectNumber(41);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc switzerland" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                41
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="SY"
              onClick={() => {
                handleSelectFlag("syria");
                handleSelectNumber(963);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc syria" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                963
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TW"
              onClick={() => {
                handleSelectFlag("taiwan");
                handleSelectNumber(886);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc taiwan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                886
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TJ"
              onClick={() => {
                handleSelectFlag("tajikistan");
                handleSelectNumber(992);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tajikistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                992
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TZ"
              onClick={() => {
                handleSelectFlag("unitedrepublicoftanzania");
                handleSelectNumber(255);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedrepublicoftanzania" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                255
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TH"
              onClick={() => {
                handleSelectFlag("thailand");
                handleSelectNumber(66);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc thailand" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                66
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TL"
              onClick={() => {
                handleSelectFlag("timor-leste");
                handleSelectNumber(670);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc timor-leste" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                670
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TG"
              onClick={() => {
                handleSelectFlag("togo");
                handleSelectNumber(228);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc togo" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                228
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TK"
              onClick={() => {
                handleSelectFlag("tokelau");
                handleSelectNumber(690);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tokelau" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                690
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TO"
              onClick={() => {
                handleSelectFlag("tonga");
                handleSelectNumber(676);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tonga" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                676
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TT"
              onClick={() => {
                handleSelectFlag("trinidadandtobago");
                handleSelectNumber(1868);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc trinidadandtobago" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1868
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TN"
              onClick={() => {
                handleSelectFlag("tunisia");
                handleSelectNumber(216);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tunisia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                216
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TR"
              onClick={() => {
                handleSelectFlag("turkey");
                handleSelectNumber(90);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkey" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                90
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TM"
              onClick={() => {
                handleSelectFlag("turkmenistan");
                handleSelectNumber(993);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkmenistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                993
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TC"
              onClick={() => {
                handleSelectFlag("turksandcaicosislands");
                handleSelectNumber(1649);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turksandcaicosislands" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1649
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="XC"
              onClick={() => {
                handleSelectFlag("northerncyprus");
                handleSelectNumber(90392);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc northerncyprus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                90392
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="TV"
              onClick={() => {
                handleSelectFlag("tuvalu");
                handleSelectNumber(688);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc tuvalu" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                688
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="UG"
              onClick={() => {
                handleSelectFlag("uganda");
                handleSelectNumber(256);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uganda" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                256
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="UA"
              onClick={() => {
                handleSelectFlag("ukraine");
                handleSelectNumber(380);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc ukraine" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                380
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="AE"
              onClick={() => {
                handleSelectFlag("uae");
                handleSelectNumber(971);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uae" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                971
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="GB"
              onClick={() => {
                handleSelectFlag("unitedkingdom");
                handleSelectNumber(44);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedkingdom" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                44
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="US"
              onClick={() => {
                handleSelectFlag("unitedstates");
                handleSelectNumber(1);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedstates" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="UY"
              onClick={() => {
                handleSelectFlag("uruguay");
                handleSelectNumber(598);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uruguay" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                598
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="UZ"
              onClick={() => {
                handleSelectFlag("uzbekistan");
                handleSelectNumber(998);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc uzbekistan" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                998
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VU"
              onClick={() => {
                handleSelectFlag("vanuatu");
                handleSelectNumber(678);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc vanuatu" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                678
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VE"
              onClick={() => {
                handleSelectFlag("venezuela");
                handleSelectNumber(58);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc venezuela" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                58
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VN"
              onClick={() => {
                handleSelectFlag("vietnam");
                handleSelectNumber(84);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc vietnam" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                84
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VG"
              onClick={() => {
                handleSelectFlag("virginislands,british");
                handleSelectNumber(1284);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc virginislands,british" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1284
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="VI"
              onClick={() => {
                handleSelectFlag("virginislandsus");
                handleSelectNumber(1340);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc virginislandsus" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                1340
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="WF"
              onClick={() => {
                handleSelectFlag("wallisandfutuna");
                handleSelectNumber(681);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc wallisandfutuna" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                681
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="EH"
              onClick={() => {
                handleSelectFlag("westernsahara");
                handleSelectNumber(212);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc westernsahara" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                212
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="YE"
              onClick={() => {
                handleSelectFlag("yemen");
                handleSelectNumber(967);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc yemen" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                967
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ZM"
              onClick={() => {
                handleSelectFlag("zambia");
                handleSelectNumber(260);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc zambia" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                260
              </p>
            </label>
            <label
              className="checkbox-control-content-bc  "
              data-option-value="ZW"
              onClick={() => {
                handleSelectFlag("zimbabwe");
                handleSelectNumber(263);
              }}
            >
              <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc zimbabwe" />
              <p
                className="checkbox-control-text-bc"
                style={{ pointerEvents: "none" }}
              >
                263
              </p>
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { CountryNumberSelect };
