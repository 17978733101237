import React from "react";
import "./CasinoGamesPreloader.scss";

const CasinoGamesPreloader = () => {
  return (
    <div className="casino-category-types casino-category-types-full">
      <div className="casino-category-games ">
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
        <div className="casino-game-item skeleton-loader-game-cube" />
      </div>
    </div>
  );
};

export { CasinoGamesPreloader };
