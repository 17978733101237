import { UserInformation } from "../../../common/types/AdminInfo/UserInformation";

type IsetUserInfo = {
  type: string;
  payload: UserInformation;
};

function setUserInfo(payload: UserInformation): IsetUserInfo {
  return {
    type: 'SET_USER_INFO',
    payload,
  };
}

export { setUserInfo };
