import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./EntryTypes.scss";

const entryTypeData = ["All", "BuyIn", "FreeEntry"];

const EntryTypes = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [modalOpen, setModalOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleSelectStatus = (item: string) => {
    setSelectedStatus(item);
    setModalOpen(false);
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setModalOpen(false);
    }
  };
  const handleOpenInput = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div className="multi-select-bc ">
        <div className="form-control-bc valid filled" ref={wrapperRef}>
          <div
            className="form-control-label-bc inputs"
            onClick={handleOpenInput}
          >
            <div className="form-control-select-bc ellipsis">
              {t(`tournaments.${selectedStatus}`)}
            </div>
            <i
              className={`form-control-icon-bc bc-i-small-arrow-${
                modalOpen ? "up" : "down"
              }`}
            />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">{t('tournaments.EntryTypes')}</span>
          </div>
          {modalOpen ? (
            <div className="multi-select-label-bc">
              {entryTypeData.map((item) => {
                return (
                  <label
                    className={`checkbox-control-content-bc ${
                      selectedStatus === item
                    } `}
                    data-option-value="Infinity"
                    key={item}
                    onClick={() => handleSelectStatus(item)}
                  >
                    <p
                      className="checkbox-control-text-bc"
                      style={{ pointerEvents: "none" }}
                    >
                      {t(`tournaments.${item}`)}
                    </p>
                  </label>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { EntryTypes };
